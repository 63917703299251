import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { ProductionService } from '../../../../../service/production.service';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-production-details',
  templateUrl: './production-details.component.html',
  styleUrls: ['./production-details.component.css']
})
export class ProductionDetailsComponent implements OnInit {
 pageLoadingImg =false;
 resultobj: any = {};
 retData:any[];
 opData=[];
 retDataIncr:any[];
 totalCost:any;
 prdnFlag:any;
 purchId:any;
  up: any;
  country_dec: number=2;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
    Prd_Name: any;

 

  constructor(private productionService: ProductionService, private apiService: ApiService, private coreService: CoreService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private translate: TranslateService,private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
  }

  production_search(search: string) {   
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('prdn_id', search);
    this.productionService.prodDet(searchval).subscribe((res) => {
      this.pageLoadingImg = false;      
      if (res.data.prdn_id && res.data.prdn_id != null) {
        this.resultobj = res.data;           
        this.resultobj = this.coreService.getValidation(this.resultobj); 
        this.retData = null;      
      } else {
        this.resultobj = {};        
        this.retData = res.data.op; 
        this.opData = res.data.op;
        this.prdnFlag = res.data.op[0].flag;
        this.totalCost = Number(this.opData[0].total_cost)+Number(this.opData[0].commission)+Number(this.opData[0].misc_exp);
        this.retDataIncr = res.data.increds;       
        $('#retContent').show();  
      }


    });
  }

  
  generatePdf() {
    const lblXPos = 10;
    const headerHeight = 15;
    const spaceBetweenTables = 20; // Adjust this value based on spacing preference

    const tableData = {
        "table1": {
            heads: [
                this.translate.instant('Table.production_id'),
                this.translate.instant('Table.date'),
                this.translate.instant('Production.formula_name'),
                this.translate.instant('Van.Expected_Main_Qty'),
                this.translate.instant('Van.Total_Purch_Amount'),
                this.translate.instant('Van.Misc_Amount')
            ],
            data: [
                [
                    this.retData[0].id,
                    this.datePipe.transform(this.retData[0].prd_date, 'dd/MM/yyyy'),
                    this.retData[0].formula,
                    (this.retData[0].reqqty).toFixed(this.country_dec),
                    (this.retData[0].total_purch_amount).toFixed(this.country_dec),
                    (this.retData[0].misc_exp).toFixed(this.country_dec),
                ]
            ]
        },
        "table2": {
            heads: [
                this.translate.instant('Table.amnt'),
                this.translate.instant('Production.net_production_cost'),
                this.translate.instant('Van.Checked_by'),
                this.translate.instant('Common.Added_By'),
                this.translate.instant('Production.production_notes')
            ],
            data: [
                [
                    (this.retData[0].commission).toFixed(this.country_dec),
                    (this.retData[0].sum_total_purch_amount).toFixed(this.country_dec),
                    this.retData[0].prdn_inspection_staff,
                    this.retData[0].addedby,
                    this.retData[0].prdn_note
                ]
            ]
        },
        "table3": {
            heads: [
                this.translate.instant('HOME.NAME'),
                this.translate.instant('Van.Prdn') + '\n' + this.translate.instant('Production.cost'),
                this.translate.instant('Table.rate'),
                this.translate.instant('Production.output_quantity'),
                this.translate.instant('Purchase.mfg') + '\n' + this.translate.instant('Common.date'),
                this.translate.instant('Purchase.exp') + '\n' + this.translate.instant('Common.date'),
                this.translate.instant('HOME.GODOWN')
            ],
            data: []
        },
        "table4": {
            heads: [
                this.translate.instant('HOME.NAME'),
                this.translate.instant('Table.barcode'),
                this.translate.instant('Van.Used') + '\n' + this.translate.instant('Table.qty'),
                this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.rate'),
                this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'),
                this.translate.instant('Van.Taken') + '\n' + this.translate.instant('Common.from')
            ],
            data: []
        }
    };

    this.opData.forEach(item => {
        if (this.translate.currentLang == 'English') {
            this.Prd_Name = item.product; 
        } else {
            this.Prd_Name = item.alias; 
        }
        
        const arr = [
            this.Prd_Name,
            (item.rate * item.opqty).toFixed(this.country_dec),
            (item.rate).toFixed(this.country_dec),
            (item.opqty).toFixed(this.country_dec),
        ];

        if (item.mfd == '0000-00-00 00:00:00') {
            arr.push('na');
        } else {
            arr.push(this.datePipe.transform(item.mfd, 'dd/MM/yyyy'));
        }

        if (item.exp == '0000-00-00 00:00:00') {
            arr.push('na');
        } else {
            arr.push(this.datePipe.transform(item.exp, 'dd/MM/yyyy'));
        }

        if (item.godown) {
            arr.push(item.godown);
        } else {
            arr.push('Shop');
        }

        tableData["table3"].data.push(arr);
    });

    this.retDataIncr.forEach(item => {
        if (this.translate.currentLang == 'English') {
            this.Prd_Name = item.product; 
        } else {
            this.Prd_Name = item.alias; 
        }
        tableData["table4"].data.push([
            this.Prd_Name,
            item.barcode,
            (item.opqty).toFixed(this.country_dec),
            (item.rate).toFixed(this.country_dec),
            (item.rate * item.opqty).toFixed(this.country_dec),
            'Godown'
        ]);
    });

    let doc = this.excelService.getPdfObj();

    this.addHeaderInformation(doc, headerHeight);

    // Add table for each set of data
    let yPos = headerHeight + 20; // Initial Y position after header
    Object.keys(tableData).forEach(key => {

      // header name for table
      doc.setFontSize(14);
      if (key === "table3") {
        doc.setTextColor(0, 0, 0);
          doc.text(`${this.translate.instant('Production.output_products')}`, lblXPos, yPos - 3);
      } else if (key === "table4") {
        doc.setTextColor(0, 0, 0);
          doc.text(`${this.translate.instant('Table.ingredients_used')}`, lblXPos, yPos - 3);
      }

        doc = this.excelService.addTableToPdf(doc, yPos, lblXPos, tableData[key].heads, tableData[key].data, null);
        // Increment Y position for next table
        yPos += (tableData[key].data.length * 10) + spaceBetweenTables; 
    });

    doc.save(`Production Details.pdf`);
}

addHeaderInformation(doc, headerHeight) {
    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name + ' (' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    let addressLines = this.branch_address.split('\r\n');
    let addressText = addressLines.join(', ');
    doc.text(addressText, 71, headerHeight + 2);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, 10, headerHeight + 10);
    doc.text(':  ' + `${this.translate.instant('Van.Production_Details')}`, 55, headerHeight + 10);

    if(this.prdnFlag==0){
      doc.setFontSize(10);
      doc.setTextColor(255, 0, 0);
      doc.text(`${this.translate.instant('Van.Production_Voided')}`, 10, headerHeight + 15);
    }
}


}
