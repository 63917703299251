import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Stockedit } from '../../../../model/stockedit.model';
import { Branch } from '../../../../model/branch.model';

@Component({
  selector: 'app-branch-stock-rates', 
  templateUrl: './branch-stock-rates.component.html',
  styleUrls: ['./branch-stock-rates.component.css']
})
export class BranchStockRatesComponent implements OnInit {
  @Input() name: string;
  selecTed: any = {
    prd_id: null, prd_name: null, message: null, rec_unit_rates: null,
    upt_unit_rates: null, unit_rates: null, units_rates: null, error: null, gdstock: null, branch_id: null, prd_units: null,
    manufacture_date: null, expiry_date: null, batch_code: null, cmp_stock_id: null, purchase_rate: null
  };
  stkprd_all: string[];
  rec_unit_rates: any[];
  branches: [];
  allbranches: any[];
  branchids: number[];
  data_unit_rates: any[]
  counter: string;
  // resultobj: any {};
  resultobj: any = {};
  error = '';
  stkprdsel: any;
  pageLoadingImg: boolean;
  branch_units: any;
  skIp: any;
  taKe: any;
  cur_lang: string;
  toTal: any;
  counT: any;
  selBranch=[];
  branch_name: any;
  up: any;
  fullbranches: any;
  branchselids:any;
  prd_tax:any;
  country_dec: string;
  prod_alias: any;
  barcode: any;
  g_settings: any;
  generalSearch_type: any;
  // brdstkprd_all: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })

    this.getAllBranches();
    this.up = this.coreService.getUserPrivilage();
    this.getfullBranches();
    this.country_dec=this.coreService.setDecimalLength();
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
     
    }else{
      this.prod_alias =null;
      
    }
  }
  

  getfullBranches(){
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      this.fullbranches = branch['data'];
    });
  }

  //form search
  searchStkPrd(search: string) {
    let searchval = new FormData();
    if(this.prod_alias) {
      searchval.append('alias_search', '1');
     }
    searchval.append("keyword", search);
    this.apiService.getQtybyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      $("#br_err").text("");
    });
    this.barcode=null;
  }

  searchStkPrdBarcode(search: string){
    if(!search){
      $("#br_err").text("Enter Barcode");
      return false;
    }else{
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.apiService.getQtybyBarcodeNew(searchval).subscribe((res:any) => {
      // this.brdstkprd_all = res['data'];
      if (typeof res['data'].prd_id !== 'undefined') {
        this.stkprdsel=[];
        $("#br_err").text("");
        this.selectStockPrd(res['data'])
      }else{
        $("#br_err").text("Barcode not found");
        $('#editstockform').hide();
      }
    });
  }

  //form search select
  selectStockPrd(res: Stockedit) {

    if(res.prd_id != undefined){
      this.getBranches(res.prd_id);
    }
    this.stkprd_all = [];
    //  this.brdstkprd_all=null;
    var units_notsel = [];
    var sel_unit_rates = [];
    this.branch_units = [];
    if (res) {
      this.selecTed = res;
      sel_unit_rates = this.selecTed.units_rates;         
      this.branchids = this.selecTed.branch_id;
      this.prd_tax = this.selecTed.prd_tax;
      $('#editstockform').show();
      // compare
      var result1 = this.selecTed.prd_units;    
      // var result2 = this.selecTed.units_rates;  

      //remove unwanted data
      var id = 0;
      // var sel_unit_rates_distinct= $.grep(sel_unit_rates, function(e){     
      //  return e.sur_unit_rate != id; 
      //   });

      var props = ['sur_unit_id', 'unit_name'];

      units_notsel = result1.filter(function (o1) {
        // filter out (!) items in result2
        return !sel_unit_rates.some(function (o2) {
          return o1.sur_unit_id === o2.sur_unit_id;          // assumes unique id
        });
      }).map(function (o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        return props.reduce(function (newo, name) {
          newo[name] = o[name];
          return newo;
        }, {});
      });

      // compare
      // merge 
       $.each(units_notsel, function (index, value) {
        sel_unit_rates.push({ "sur_unit_id": units_notsel[index].sur_unit_id, 'sur_unit_rate': 0, 'unit_name': units_notsel[index].unit_name, 'sur_id': 0,'unit_type':units_notsel[index].unit_type});
      });
      //merge
      this.data_unit_rates = sel_unit_rates;  
      this.data_unit_rates.sort(function(a, b){
        var a1= a.unit_type, b1= b.unit_type;
        if(a1== b1) return 0;
        return a1< b1? 1: -1;
    });       
    sel_unit_rates = [];
    }
  }

  updateStock(form: { value: Stockedit; }) {
    // console.log(form.value);
    this.pageLoadingImg = true;
    var upt_unit_rates = [];
    var units_rates = [];
    var errorobj = false;
    $('input[name="unitratechk"]:checked').each(function () {


      upt_unit_rates.push({ "unit_id": $(this).val() });
    });


    $('input.edit_unit_rate').each(function () {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();

      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          if (Number(unit_rt) <= 0) {
            errorobj = true;
          }
          units_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
        }
      });

    });


    //form.value.stkprdsel=undefined;
    form.value.unit_rates = units_rates;
    if (errorobj) {
      $('#error_in').html('Please enter  value greater than zero');
      this.pageLoadingImg = false;
    }
    else {
      $('#error_in').html('');
      // this.pageLoadingImg = false;
    }
    if (!errorobj) {
      this.pageLoadingImg = true;
      this.apiService.updateMultiBrachStockRate(form.value).subscribe((res: Stockedit) => {
        this.pageLoadingImg = false;
        if (res.error != null) {
          
          this.resultobj = res.error;
          this.resultobj = this.coreService.getValidation(this.resultobj);
// console.log(this.resultobj);
        }
        else {
          this.resultobj = {};
          this.coreService.createfunct(form, this.resultobj, 'Updated');
          this.stkprd_all = [];
          $('#error_in').html('');
          $('#editstockform').hide();
          this.stkprdsel = "Select Product";

        }

      });
    }
  }

  getBranches(prd_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getBranchesWithUnits(searchval).subscribe((branch: Branch) => {
      this.branches = branch['data'];
    });
  }

  onChange(event) {
    this.counter = "Number Only";
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }


  isNumberKey1(e) {
    //if the letter is not digit then display error and don't type anything
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      //display error message
      $("#errmsg").html("Digits Only").show().fadeOut("slow");
      return false;
    }
  }


  updateQty(formdata) {
    // this.pageLoadingImg = true;    
    this.apiService.updateQty(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        // console.log("error");
      }
      else {
        this.coreService.createfunct(formdata, "nil", "Updated");
      }

    });
  }

  selectStockPrdBranch(branchid, prd_id) {
    // console.log(branchid);
    let inpVal = new FormData();
    inpVal.append("branchid", branchid);
    inpVal.append("prd_id", prd_id);
    this.apiService.selBranchviseQty(inpVal).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        // console.log("error");
      }
      else {
        this.branch_units = res['data'];
      }

    });

  }

  useBranchData(data) {
    const found = this.selecTed.units_rates.some(el => el.unit_name === data.unit_name);
    var existingArray = this.selecTed.units_rates.filter((x => x.unit_name === data.unit_name));
    this.selecTed.units_rates.find(x => x.unit_name === existingArray[0].unit_name).sur_unit_rate = data.unit_rate;
  }

  useBranchDataAll() {
    // console.log(this.branch_units);
    // console.log(this.selecTed.units_rates);
    for (var i = 0; i < this.branch_units.length; i++) {
      if (this.selecTed.units_rates.find(x => x.unit_name === this.branch_units[i].unit_name)) { this.selecTed.units_rates.find(x => x.unit_name === this.branch_units[i].unit_name).sur_unit_rate = this.branch_units[i].unit_rate; }

    }
  }


  getAllBranches() {
    this.apiService.getStockBranches().subscribe((datas: Branch[]) => {
      this.allbranches = datas['data'];
    });

  }

  useBranchAllData(data,skip,flag,total) {
    let inpVal = new FormData();
    inpVal.append("branchid", data.branch_id);
    inpVal.append("skip", skip);
    inpVal.append("flag", flag);
    inpVal.append("total", total);

    this.pageLoadingImg = true;
    $('.list_branch').hide();    
    this.apiService.selBranchviseUnits(inpVal).subscribe((res: any) => {

      if (res.error != null) {
        // console.log("error");
      }
      else {      
        this.toTal = res['data']['total'];
        this.counT = res['data']['insert']; 
        // console.log(res);
       this.pageLoadingImg = false; 
        $('.list_data').hide();
        $('.list_cont').show(); 
     if(res['data']['flag']=='E')    
      {
        $('.list_data').show();
        $('.list_cont').hide();
        $('.sel-branch').hide();
        $('.list_branch').show();    
      this.coreService.createfunct(data, "nil", "Updated");
      }
        }
      

    });
  }


  useBranchAllDatSel(data)
  {
   this.selBranch = data;
   $('.sel-branch').show();
 
  }
  showCalculateMdl(id: number) {
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#mrp_id").val(id);
    $("#calculateMdl").show();
  }
  closeCalculateMdl(){
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#sp_tax").val('');
    $("#calculateMdl").hide();
  }
  calculateTax(keyword){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $("#sp").val(p);
      $("#sp_tax").val(t);
    }else{
      $("#sp").val('');
      $("#sp_tax").val('');
    }
  }
  showCalculatedTax(){
    let mrp_id = $("#mrp_id").val();
    let sp = $("#sp").val();
    $(".stk_mrp_"+mrp_id).val(sp);
    $("#calculateMdl").hide();
  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {
  
      // console.log(res['data']);
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });
  
  }
  
}
