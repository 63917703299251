import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-quick-product-create',
  templateUrl: './quick-product-create.component.html',
  styleUrls: ['./quick-product-create.component.css']
})
export class QuickProductCreateComponent implements OnInit {

  private modalRef: NgbModalRef;
  closeResult: string;
  pageFrom:any;
  curpage: number;
  pgNo: any=1;
  pgnum:any=1;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg: boolean;
  prods: any;
  totalProducts: any;
  prd_pgno: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,private translate:TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.pageLoadingImg = true;
    let searchval = new FormData();
    
  
  
    searchval.append("list", '1');

    this.prd_pgno = localStorage.prd_pgno;
    
    if(this.prd_pgno && this.prd_pgno !=undefined ){
      this.pgnum=this.prd_pgno
     } 

    this.apiService.prodSumPage(searchval,this.pgnum).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.totalProducts = res['data']['total_products'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.pgNo=this.curpage;
      localStorage.setItem("prd_pgno",'1');
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


     
     
  }
  


  
  pageNext( pageno: any) {
    this.pgNo=pageno;
  this.pageLoadingImg = true;
     let searchval = new FormData();
     searchval.append("pageno", pageno);
     searchval.append("list", '1');
     this.apiService.prodSumPage(searchval, pageno).subscribe((res: any) => {
       this.pageLoadingImg = false;
       this.prods = res['data']['data']['data'];
       this.pageFrom = res['data']['data']['from'];
       this.totalProducts = res['data']['total_products'];
       this.curpage = res['data']['data']['current_page'];
      
       this.lastpage = res['data']['data']['last_page'];
       this.pgEnd(this.curpage, this.lastpage);
       this.pgStart(this.curpage);
 
     });
   }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  searchInp(keyword) {
    let searchval = new FormData();
    if (keyword)
      if(this.translate.currentLang == "Arabic"){
        searchval.append("withAlias", "1");
      }
      searchval.append("keyword", keyword);
    this.pageLoadingImg = true;
    this.apiService.prodSumPage(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prods = res['data']['data']['data'];
      this.totalProducts = res['data']['total_products'];

      
      

      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }

   /*
    Start
    Author: harsha
    Created: 12.09.2024
    Description:search with barcode and ean
  */
  selectProdDetailBarcode(barcode: string) {
   
    if(barcode){
    const searchval = new FormData();
    searchval.append("barcode", barcode);
    this.pageLoadingImg = true;
    this.apiService.getProdByBarcode(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.prods=[];
      if(res['data']){
        this.prods.push(res['data']);
      }
    
     

    });
  }else{
    this.searchInp('')
  }

  }



  editproductfn(){
    console.log('this.pgNo editproductfn');
    console.log(this.pgNo);
    
    localStorage.setItem("prd_pgno",this.pgNo);
  }

}
