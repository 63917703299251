import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';


@Component({
  selector: 'app-report-dash',
  templateUrl: './report-dash.component.html',
  styleUrls: ['./report-dash.component.css']
})
export class ReportDashComponent implements OnInit {
  userType:any;
  up: any;
  usr_username: any;
  show_van = false;
  show_godown = false;
  show_production = false;
  branch_transfer_receipt = false;
  cmpny: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();

  this.userType =  this.coreService.getUserData('user_type');
  this.usr_username =  this.coreService.getUserData('usr_username');
  this.getEnbaleDisableModuleSettingBykey();
  this.cmpny = this.coreService.getUserData('cmpny');

  }
  getEnbaleDisableModuleSettingBykey() {
    this.apiService.getEnbaleDisableModuleSettingBykey().subscribe((res) => {
      if (res['data']) {
        this.show_van = (res['data']['enable_van']) ? true: false;
        this.show_godown = (res['data']['enable_godown']) ? true: false;
        this.show_production = (res['data']['enable_production']) ? true: false;
        this.branch_transfer_receipt = (res['data']['enable_branch_transfer_receipt']) ? true: false;
      } 
    });
  }

}
