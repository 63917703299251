import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import {Supplier} from '../../../../model/ledger/supplier.model';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html', 
  styleUrls: ['./supplier.component.css']
})
export class SupplierComponent implements OnInit {
  @ViewChild('closeModal1', { static: false }) closeModal1: ElementRef;
  fileName = 'supplier_list.xlsx';
  Expsumms: any;
  exportLoader: boolean;
  supp_name: any;
  supp_code: any;
  group_all: any;
  supp_alias: any;
  supp_zip: any;
  supp_address1: any;
  supp_address2: any;
  supp_city: any;
  supp_state: any;
  supp_country: any;
  supp_phone: any;
  supp_mob: any;
  supp_email: any;
  supp_fax: any;
  supp_contact: any;
  supp_due: any;
  supp_tin: any;
  supp_notes: any;
  pageLoadingImg:boolean;
  supplier:any;
  curpage:any;
  lastpage:any;
  slnum:any;
  pgstart:any;
  pgend:any;
  search:any;op_bal:any;elseBlocknostart:any;elseBlocknoend:any;




  resultobj: any =  {};
  selData: any = { supp_id: null, error: null, supp_name: null, supp_code: null, message: null, data: null };
  supplierAll: string[];
  supp: string;
  from: any;
  up: any;
  branch_all: any;
  userType: any;
  printError: any;
  is_customer: false;
  errObjArr = {
    vattErr: null, 
  };
  vaterrTxt: string;
  currencies = [];
  country_dec: string;
  delete_ledger_id: any;
  checkIsGL: boolean;
  other_branch_dt: number=0;
  e_invoice_filter=[
    { id: 0, name: 'All' },
    { id: 1, name: 'Enabled' },
    { id: 2, name: 'Not Enabled' },
  ]
  edit_supp_id = 0;
  e_inv_filter_id:any;
  branch_e_invoice_enabled = 0;
  eInvoiceSupport: boolean=false;
  show={
    '0':'suspended',
    '1':'active'
  }
  sup_status = [
    { id: 0, name: 'Suspend' },
    { id: 1, name: 'Active' },
  ];
  status: any;
  supp_status: any;
  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.coreService.getToken();
    this.search =  "";
     this.e_inv_filter_id=0;
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });

    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');
    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
     
      }
    });
    // 
    this.apiService.getClientSettingBykey({ key: "general_ledger_enable" }).subscribe((res) => {
      if (res["data"]) {
        this.checkIsGL = res["data"]["cs_value"] ? true : false;
      }
    });
    this.searchBranch('');
    this.listSupplier();
  }


  validatesupplier(list) {
   
      this.printError = [];
  if(this.userType =='ADMIN'){

      if (!list.brch_id) {
        this.printError.push({ 'branch_id': 'Required' });
        $('.ledger_branch_id').css("border", "1px solid red");
      } else {
        $('.ledger_branch_id').css("border", "1px solid #dedede");
      }
    }
    if(this.supp_tin){
      if (this.supp_tin.length !=15) {
        this.errObjArr.vattErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
        this.vaterrTxt='15 Digits Required';
      } else {
        this.errObjArr.vattErr = "f";
  
      }
    }
   


      // if (!list.supp_code) {
      //   this.printError.push({ 'supp_code': 'Required' });
      //   $('.supp_code').css("border", "1px solid red");
      // } else {
      //   $('.supp_code').css("border", "1px solid #dedede");
      // }

      if (!list.supp_name) {
        this.printError.push({ 'supp_name': 'Required' });
        $('.supp_name').css("border", "1px solid red");
      } else {
        $('.supp_name').css("border", "1px solid #dedede");
      }
      if (list.op_bal && list.op_bal_type == undefined) {
        this.printError.push({ 'op_bal_type': 'Required' });
        this.printError.op_bal_type =  'Select Type';
        $('.op_bal_type').css("border", "1px solid red");
        console.log('true');
      
      } else {
        $('.op_bal_type').css("border", "1px solid #dedede");
        this.printError.op_bal_type =  '';

      }
      console.log(list);
     

  }


  createSupplier(formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    this.validatesupplier(formgroup.value);
    if (this.printError.length <= 0) {
    this.apiService.createSupplier(formgroup.value).subscribe((res: Supplier) => {
      this.pageLoadingImg=false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
      }

    });
    this.vaterrTxt =null;
  }else{
    this.pageLoadingImg=false;

  }
  }


  updateSupplier(formgroup: { value: any; }) {
    
    this.printError = [];
    if(this.selData.supp_tin){
      if (this.selData.supp_tin.length !=15) {
        this.errObjArr.vattErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
        this.vaterrTxt='15 Digits Required';
      } else {
        this.errObjArr.vattErr = "f";
  
      }
    }
   if(this.printError.length <= 0){
    this.pageLoadingImg=true;
    this.apiService.updateSupplier(formgroup.value).subscribe((res: Supplier) => {
      this.pageLoadingImg=false;


      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.resultobj = {};
        this.coreService.updatefunct(formgroup, this.resultobj, res.message);
        $('#edit-supp').hide();
        this.supp = 'Select Supplier';
        $('.close').click(); 
        this.supplierAll = [];
        this.listSupplier();
      }

    });
    this.vaterrTxt = null;
   }
  }

  // form search supplier
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
      // console.log(this.supplierAll);
    });
  }

  listSupplier() {
    let searchval = new FormData();
    searchval.append("status", '1');
    this.apiService.searchSupplierList(searchval,1).subscribe((res) => {
  
      this.supplier = res['data']['data'];
    this.from = res['data'].from;
      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
        this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }


  pageNext(formdata: { value: any;},pageno: any) {

    let searchval = new FormData();
    formdata.value.search=this.search;
    formdata.value.status='1';
    // searchval.append("status", '1');
    // searchval.append("search", search);
    // searchval.append("e_inv_filter_id", this.e_inv_filter_id);
    this.apiService.searchSupplierList(formdata.value,pageno).subscribe((res) => {
  
      this.supplier = res['data']['data'];
    this.from = res['data'].from;
      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
        this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }



pgEnd(curr, end) {
  if (curr == end)
    this.pgend = true;
  else
    this.pgend = false;

}

pgStart(curr) {
  if (curr == 1)
    this.pgstart = true;
  else
    this.pgstart = false;
}
  selectSupp( Supplier) {
    if (Supplier) {

      let searchval = new FormData();

    searchval.append("supp_id", Supplier.supp_id);
    this.apiService.isSupplierDeletable(searchval).subscribe((res) => {
      this.delete_ledger_id = res['ledger_id'];
      this.other_branch_dt = res['other_branch'];
      this.status=res['ledger_id']
      // console.log(this.delete_ledger_id);
    });
      const data = Supplier;
      // this.selData = data;
      this.selData = {
        supp_name : data.supp_name,
        supp_alias : data.supp_alias,
        supp_code : data.supp_code,
        supp_branch_id : Number(data.supp_branch_id),
        supp_zip : data.supp_zip,
        supp_address1 : data.supp_address1,
        supp_address2 : data.supp_address2,
        supp_city : data.supp_city,
        supp_state : data.supp_state,
        supp_country : data.supp_country,
        supp_phone : data.supp_phone,
        supp_mob : data.supp_mob,
        supp_email : data.supp_email,
        supp_fax : data.supp_fax,
        supp_contact : data.supp_contact,
        supp_due : data.supp_due,
        supp_tin : data.supp_tin,
        supp_notes : data.supp_notes,
        supp_ledger : data.supp_ledger_id,
        supp_display_in_all_branch : data.supp_display_in_all_branch,
        opening_balance : Math.abs(data.opening_balance),
        op_bal_type : (data.opening_balance < 0) ? true : false,
        supp_id : data.supp_id,
        default_currency_id : data.default_currency_id,

      };
      
      
      
      // this.selData.op_bal_type = (data.opening_balance < 0) ? true : false;
      // // this.selData.opening_balance = Supplier.opening_balance;
      // this.selData.opening_balance = Math.abs(data.opening_balance);

      $('#edit-supp').show();
    }
    this.supplierAll = [];

  }

  deleteSupplier(ledger_id, formgroup: { value: any; }) {
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("ledger_id", ledger_id);
    this.apiService.DeleteSupplier(searchval).subscribe((res) => {

      this.pageLoadingImg = false;

        
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.closeModal1.nativeElement.click();
        // $('#listbtn').focus();.click();

        this.coreService.showMessage(res.message);
        this.resultobj = {};
    
        this.coreService.showMessage(res.message);
        this.listSupplier();
      


      }
    });
  }

  removeName() {
    this.supplierAll = [];
    $('.prev-list').hide();
  }

  searchInp(e) {
    // this.e_inv_filter_id=0;
    let searchval = new FormData();      
    searchval.append("search", e); 
    searchval.append("e_inv_filter_id", this.e_inv_filter_id);   
     this.apiService.searchSupplierList(searchval,1).subscribe((res) => {
      this.supplier = res['data']['data'];   

      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
        this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;            
     });
    
    }

  tabClick(tab: Event) {
    this.resultobj = {};
    this.supplierAll = [];
    $('#edit-supp').hide();
    this.supp = 'Select Supplier';
    this.listSupplier();
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export(formdata: { value: any;}) {
    this.exportLoader = true;
    formdata.value.export='export';
    formdata.value.search=this.search;
   
    
    // debugger;
    this.apiService.searchSupplierList(formdata.value,1).subscribe((res) => {
      formdata.value.export='';
      this.pageLoadingImg = false;
      this.Expsumms = res['data'].data;
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });
  }

  addSupplier(){
    this.edit_supp_id = 0;
  }

  supplierUpdated(){
    this.e_inv_filter_id=0;
    this.edit_supp_id = 0;
    this.listSupplier();
  }

  updateEditId(id){
    this.edit_supp_id = id;
    this.resultobj = [];
  }

  
  filterSupplier(formdata: { value: any;},pageno: any){
    formdata.value.search=this.search;
    this.apiService.searchSupplierList(formdata.value,pageno).subscribe((res) => {
      this.supplier = res['data']['data'];
      this.from = res['data'].from;
        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
          this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        this.slnum = 0;


    });
  }




}
