import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';


@Component({
  selector: 'app-van-profit-loss',
  templateUrl: './van-profit-loss.component.html',
  styleUrls: ['./van-profit-loss.component.css']
})
export class VanProfitLossComponent implements OnInit {
  filterTypes: { id: string; name: any; }[];
  fileName= 'van_profit_loss.xlsx';
  vans: any;
  pageLoadingImg: boolean;
  report: any;
  country_dec: string;
  rateTyes = [
    { id: 'vanstock_last_tran_rate', name: 'Last Transfer Rate' },
    { id: 'vanstock_avg_tran_rate', name: 'Avg Transfer Rate' }
  ];
  van_id: any;
  period_type: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;
  exportLoader: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.searchVans('');
    this.country_dec=this.coreService.setDecimalLength();
    this.pageLoadingImg = true;
    this.van_id=1;
    this.period_type='t';
    let searchval =new FormData();
    searchval.append('period_type','t');
    searchval.append('van_id',this.van_id);
    this.apiService.vanProfitLossReport(searchval).subscribe((res) => {
      this.report=res.data;
      this.pageLoadingImg = false;
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month','Common.last_transfer_rate','Common.avg_transfer_rate']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.rateTyes = [
        { id: 'vanstock_last_tran_rate', name:res['Common.last_transfer_rate']},
        { id: 'vanstock_avg_tran_rate', name:res['Common.avg_transfer_rate'] }
      ];
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.vans = res['data'];
    });

  }

  vanProfitLoss(formdata: { value: any; }){
  

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.pageLoadingImg = true;

  this.apiService.vanProfitLossReport(formdata.value).subscribe((res) => {
    this.report=res.data;
    this.pageLoadingImg = false;

  });
  }

  exportEXCL(){
    this.exportLoader = true;
    this.exportexcel();
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

}
