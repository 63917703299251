import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-van-transfer-invoice',
  templateUrl: './van-transfer-invoice.component.html',
  styleUrls: ['./van-transfer-invoice.component.css']
})

export class VanTransferInvoiceComponent implements OnInit {
  fileName= 'van_transfer_invoice.xlsx';
  calc_mode: any;
  rep_type: any;
  purchaseBlock: any;
  mrpBlock: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  van_tran_filter: any;
  trans_val1: any;
  trans_val2: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;
  van_id: any;

  reportType:any;
  searchProducts: any;
  catagories: any;
  company: any;
  vans: any;
  vanPrdData: any;
  vanTransData: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  totalRes: any;
  slnum: number;
  data: any;
  pageLoadingImg:boolean;
  reportBy:string;
  repDate:any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;

  exp_vanTransData: any;
  exp_totalRes: any;

  branch_address:any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];


  rept_type = [
   
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];
  godown_all: any;
  subcatagories: any;
  enablePrft: boolean;
  country_dec: number=2;
  errorPassword: any;
  cur_lang: string;
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  PrdNAme: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.exportLoader = false;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    // searchval.append('rep_type', '1');
    this.totalRes = {};
    this.apiService.readVanTransfersInvoiceReport(searchval, 1).subscribe((res: any) => {
    this.pageLoadingImg = false;

     
      this.vanTransData = res.data;
      this.reportType = res.reportType;
      // this.rep_type =1;
    
      this.totalRes = res.alldetais;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;

      this.errorPassword = '';
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.transfer','Purchase.Returned','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.rept_type = [
   
        { id: 2, name: res['Common.transfer'] },
        { id: 3, name:  res['Purchase.Returned'] },
      ];
    });
  }

  ngAfterContentInit(){

    this.searchCat('');
    this.searchVans('');
    this.searchComp('');
    this.searchGdwn('');
    

  }



  ngOnDestroy(){
    $('.close').click();
 
  }



  // Loading Api to include for Filters


  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }


  // Loaders END

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      
    });
  }




  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').hide();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.readVanTransfersInvoiceReport(formdata.value, pageno).subscribe((res: any) => {
       
        this.pageLoadingImg = false;
    $('.resultdata').show();
      
        this.vanTransData = res.data;
        this.totalRes = res.alldetais;
        this.curpage = res.current_page;
        this.lastpage = res.last_page;
        this.reportType = res.reportType;

        if(res.enblprft == true){
          this.enablePrft = true;
        }
        else{
        this.enablePrft = false;
        }
        this.errorPassword = '';
        
        this.reportBy = res.reportBy;
        this.repDate = res.Date;


        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
       
        this.slnum = 0;
        if(!res.msg){
           $('.close').trigger('click');
        }else{
           document.getElementById("filter").click();
           this.errorPassword = res.msg;
        }


      });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.filter =1;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanTransfersInvoiceReport(formdata.value, 1).subscribe((res: any) => {
     
      this.exportLoader = false;    
      this.exp_vanTransData = res.data;
      this.exp_totalRes = res.alldetais;
    
      this.reportType = res.reportType;
      this.reportBy = res.reportBy;
      this.repDate = res.Date;
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });

  }

  print(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.filter =1;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanTransfersInvoiceReport(formdata.value, 1).subscribe((res: any) => {
     
      this.exportLoader = false;    
      this.exp_vanTransData = res.data;
      this.exp_totalRes = res.alldetais;
    
      this.reportType = res.reportType;
      this.reportBy = res.reportBy;
      this.repDate = res.Date;

      // if(this.exp_vanTransData){
      //   $('#btn1').click();
      // }
     
      setTimeout(() => {
        $('#btn1').click();
        this.exportLoader = false;    
      }, 2000);
    });

  }




  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'SHOP' });
    });
  
  }


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }



  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
    formdata.value.filter =1;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.readVanTransfersInvoiceReport(formdata.value, 1).subscribe((res: any) => {
       
      this.exp_vanTransData = res.data;
      this.exp_totalRes = res.alldetais;
    
      this.reportType = res.reportType;
      this.reportBy = res.reportBy;
      this.repDate = res.Date;
      

      const heads = [
        this.translate.instant('Table.itm_name'),
        this.translate.instant('Common.date'),
        this.translate.instant('Common.rate'),
        this.translate.instant('Table.qty'),
        this.translate.instant('Common.Derived_qty'),
        this.translate.instant('Table.amnt')
      ];
   
      if(this.reportType == 'Returns Report'){
        heads.splice(1,0, this.translate.instant('Van.Return'))
      }else{
        heads.splice(1,0, this.translate.instant('Common.transfer') + '\n' + this.translate.instant('Common.id'))
      }

      if(this.enablePrft){
        heads.splice(4,0, this.translate.instant('Table.item_cost'))
        heads.splice(5,0, this.translate.instant('Table.item_profit'))
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.exp_vanTransData.forEach(item=>{
          item.category.forEach(item1=>{
            const arr=[
              item1.name,
              '',
              '',
              (item1.rate).toFixed(this.country_dec),
              this.currencyPipe.transform(item1.qty, '', '', '1.1-1'),
              this.currencyPipe.transform(item1.drvd_qty, '', '', '1.1-1'),
              (item1.amount).toFixed(this.country_dec)
            ]

            if(this.enablePrft){
              arr.splice(4,0, (item1.total_item_cost).toFixed(this.country_dec))
              arr.splice(5,0, (item1.total_item_profit).toFixed(this.country_dec))
            }
  
            data.push(arr)
          })

          item.products.forEach(item2=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item2.prod.alias}else{this.PrdNAme =  item2.prod.name}
            const arr=[
              this.PrdNAme,
              '',
              '',
              '',
              '',
              '',
              ''
            ]

            if(this.enablePrft){
              arr.splice(4,0, ' ')
              arr.splice(5,0, ' ')
            }
            
            data.push(arr)

            item2.items.forEach(item3=>{
              const arr=[
                item3.prd_barcode,
                item3.vantransub_vantran_id,
                this.datePipe.transform(item3.vantransub_date, 'dd/MM/yyyy'),
                (item3.vantransub_purch_rate).toFixed(this.country_dec),
                this.currencyPipe.transform(item3.vantransub_qty, '', '', '1.1-1'),
                this.currencyPipe.transform(item3.derived_qty, '', '', '1.1-1'),
                (item3.purchase_amount).toFixed(this.country_dec)
              ]
  
              if(this.enablePrft){
                arr.splice(4,0, (item3.item_cost).toFixed(this.country_dec))
                arr.splice(5,0, (item3.item_profit).toFixed(this.country_dec))
              }
              
              data.push(arr)
            })

          })
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Van.van_transfer_invoice')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalRes.total_products,  valueXPos, headerHeight + 20);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalRes.total_categories,  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_trans_qty')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.totalRes.total_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total')}` + ' ' + `${this.translate.instant('Common.Derived_qty')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.totalRes.total_drvd, '', '', '1.1-1'),  valueXPos, headerHeight + 35);

        if(!this.rep_type){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Production.total_transfer_amount')}`, lblXPos, headerHeight + 40);
        } else if(this.rep_type == 3){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Production.total_return_amount')}`, lblXPos, headerHeight + 40);
        } else if(this.rep_type == 2){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Production.total_transfer_amount')}`, lblXPos, headerHeight + 40);
        }
        doc.setFontSize(10);
        doc.text(':  ' + (this.totalRes.total_purchase_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 40);

        if(this.enablePrft){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.totl_item_profit')}`, lblXPos, headerHeight + 45);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.totalRes.total_profit, '', '', '1.1-1'),  valueXPos, headerHeight + 45);
        }

        if(this.reportBy){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.VAN')}`, lblXPos, headerHeight + 50);
          doc.setFontSize(10);
          doc.text(':  ' + this.reportBy,  valueXPos, headerHeight + 50);
        }

        if(this.reportBy){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 50);
          doc.setFontSize(10);
          doc.text(':  ' + this.reportType,  valueXPos, headerHeight + 50);
        }
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 55, lblXPos, heads, data, null);
    
       
        doc.save(`Van Transfer Invoice.pdf`);
      

    });
  
    
    this.exportLoader = false;

    
  }


}
