import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import * as $ from "jquery";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { APP_BASE_HREF } from "@angular/common";
import { AppComponent } from "./app.component";

import { ExcelService } from "./service/excel.service";
import { CurrencyPipe } from "@angular/common";

// import {ExcelService} from './excel.service';
// shared
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";

import { UserComponent } from "./view/companymaster/user/user.component";
import { ProductComponent } from "./view/product/product.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { HomeComponent } from "./view/home/home.component";
import { UnitComponent } from "./view/product/unit/unit.component";
import { FeatureComponent } from "./view/product/feature/feature.component";
import { CatagoryComponent } from "./view/product/catagory/catagory.component";
import { SubcatagoryComponent } from "./view/product/subcatagory/subcatagory.component";
import { ManufactureComponent } from "./view/product/manufacture/manufacture.component";
import { ErpMaterialModule } from "./material-module";
import { AlertComponent } from "./view/alert/alert.component";
import { EditproductComponent } from "./view/product/editproduct/editproduct.component";
import { MasterComponent } from "./view/master/master.component";
import { ProductdashComponent } from "./view/productdash/productdash.component";

// translation
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// module
import { StockModule } from "./module/stock/stock.module";
import { GodownModule } from "./module/godown/godown.module";
import { ReportModule } from "./module/report/report.module";
import { LedgerModule } from "./module/ledger/ledger.module";
import { PurchaseModule } from "./module/purchase/purchase.module";
import { VanModule } from "./module/van/van.module";
import { ProductionModule } from "./module/production/production.module";
import { SettingsModule } from "./module/settings/settings.module";
import { TransactionModule } from "./module/transaction/transaction.module";
import { ExcelImportModule } from "./module/excel-import/excel-import.module";
import { DamagedProductModule } from "./module/damaged-product/damaged-product.module";
import { OfferModule } from "./module/offer/offer.module";

// shared
import { SharedModule } from "./shared/shared.module";
import { ComfunctModule } from "./common/comfunct/comfunct.module";

import { LedgerComponent } from "./view/ledger/ledger.component";
import { LedgermanageComponent } from "./view/ledger/ledgermanage/ledgermanage.component";
import { LedgergroupComponent } from "./view/ledger/ledgergroup/ledgergroup.component";
import { CompanymasterComponent } from "./view/companymaster/companymaster.component";
import { BranchComponent } from "./view/companymaster/branch/branch.component";
import { LogoutComponent } from "./view/companymaster/logout/logout.component";
import { LoginComponent } from "./view/companymaster/login/login.component";
import { StockComponent } from "./view/stock/stock.component";
import { EditstockComponent } from "./view/stock/editstock/editstock.component";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
// import { ProductionProductComponent } from "./app/module/report/report-dash/rep-production/production-product/production-product.component";
import { LedgerDetailsComponent } from "./view/ledger/ledger-details/ledger-details.component";
import { ReportDashReportCompanyComponent } from "./report/report-dash-report-company/report-dash-report-company.component";
import { DashboardModule } from "./module/dashboard/dashboard.module";
import { UpdateMutiProductComponent } from "./view/product/update-muti-product/update-muti-product.component";
import { HrdashComponent } from "./view/hrdash/hrdash.component";
import { DepartmentComponent } from "./view/hr/department/department.component";
import { DesignationComponent } from "./view/hr/designation/designation.component";
import { EmployeeComponent } from "./view/hr/employee/employee.component";
import { IqamaComponent } from "./view/hr/iqama/iqama.component";
import { NgxPrintModule } from "ngx-print";
import { PassportComponent } from "./view/hr/passport/passport.component";
import { BaladiyahComponent } from "./view/hr/baladiyah/baladiyah.component";
import { LicenceComponent } from "./view/hr/licence/licence.component";
import { EmpReportComponent } from "./view/hr/emp-report/emp-report.component";
import { ViewInvoiceComponent } from "./view/view-invoice/view-invoice.component";
import { InvoiceDesignComponent } from "./invoice-design/invoice-design.component";
import { InvoiceTwoComponent } from "./invoice-two/invoice-two.component";
import { SalesThermalBillComponent } from "../app/module/sales-thermal-bill/sales-thermal-bill.component";
import { PriceChekerComponent } from './view/price-cheker/price-cheker.component';
import { DashboardTwoComponent } from './dashboard-two/dashboard-two.component';
import { InvoiceWithoutHeaderComponent } from './invoice-without-header/invoice-without-header.component';
import { UnitListComponent } from './view/product/unit-list/unit-list.component';
import { WeightScaleItemsComponent } from './view/product/weight-scale-items/weight-scale-items.component';
import { InvoiceThreeComponent } from './invoice-three/invoice-three.component';
import { InvoiceFourComponent } from './invoice-four/invoice-four.component';
import { QuickProductCreateComponent } from './view/product/quick-product-create/quick-product-create.component';
import { QuickProductModule } from './view/product/quick-product/quick-product.module';
import { ProductLocationComponent } from './view/product/product-location/product-location.component';
import { ExpiryNotificationComponent } from './module/batch/expiry-notification/expiry-notification.component';
import { DeductionsComponent } from './view/hr/deductions/deductions.component';
import { AdditionsComponent } from './view/hr/additions/additions.component';
import { TemplateRtlComponent } from './template-rtl/template-rtl.component';
import { TemplateTwoRtlComponent } from './template-two-rtl/template-two-rtl.component';
import { AttendanceComponent } from './view/hr/attendance/attendance.component';
import { CompanyHolidaysComponent } from './view/hr/company-holidays/company-holidays.component';
import { PayrollSettingsComponent } from './view/hr/payroll-settings/payroll-settings.component';
import { PayrollComponent } from './view/hr/payroll/payroll.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NewInvoiceComponent } from './new-invoice/new-invoice.component';
import { ServiceTypeComponent } from './module/service/service-type/service-type.component';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { FreightTypeComponent } from './module/freight/freight-type/freight-type.component';
import { ExciseCategoryComponent } from './view/product/excise-category/excise-category.component';
import { ExcisecatPopupComponent } from './view/product/excisecat-popup/excisecat-popup.component';
import { OfferFlyerPosterComponent } from './view/product/offer-flyer-poster/offer-flyer-poster.component';
import { FavoriteComponent } from './module/favorite/favorite.component';
import { CustomCurrencyPipe } from './custom-currency.pipe';






// import { RepOpeningstockComponent } from './app/module/report/report-dash/rep-stock/rep-openingstock/rep-openingstock.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    HeaderComponent,
    FooterComponent,
    ProductComponent,
    HomeComponent,
    UnitComponent,
    FeatureComponent,
    CatagoryComponent,
    SubcatagoryComponent,
    ManufactureComponent,
    AlertComponent,
    EditproductComponent,
    MasterComponent,
    ProductdashComponent,
    LedgerComponent,
    LedgermanageComponent,
    LedgergroupComponent,
    CompanymasterComponent,
    BranchComponent,
    LogoutComponent,
    LoginComponent,
    StockComponent,
    EditstockComponent,
    // ProductionProductComponent,
    LedgerDetailsComponent,
    ReportDashReportCompanyComponent,
    UpdateMutiProductComponent,
    HrdashComponent,
    DepartmentComponent,
    DesignationComponent,
    EmployeeComponent,
    IqamaComponent,
    PassportComponent,
    BaladiyahComponent,
    LicenceComponent,
    EmpReportComponent,
    ViewInvoiceComponent,
    InvoiceDesignComponent,
    InvoiceTwoComponent,
    SalesThermalBillComponent,
    PriceChekerComponent,
    DashboardTwoComponent,
    InvoiceWithoutHeaderComponent,
    UnitListComponent,
    WeightScaleItemsComponent,
    InvoiceThreeComponent,
    InvoiceFourComponent,
    QuickProductCreateComponent,
    ProductLocationComponent,
    DeductionsComponent,
    AdditionsComponent,    
    ExpiryNotificationComponent,    
    TemplateRtlComponent,
    TemplateTwoRtlComponent,
    AttendanceComponent,
    CompanyHolidaysComponent,
    PayrollSettingsComponent,
    PayrollComponent,    
    CalendarComponent, 
    NewInvoiceComponent,    
    ServiceTypeComponent, NewDashboardComponent, FreightTypeComponent, ExciseCategoryComponent, ExcisecatPopupComponent, OfferFlyerPosterComponent, FavoriteComponent,    
  

  

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    MatTabsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    ErpMaterialModule,
    NgSelectModule,
    StockModule,
    GodownModule,
    ReportModule,
    LedgerModule,
    PurchaseModule,
    VanModule,
    NgxPrintModule,
    SettingsModule,
    ComfunctModule,
    ProductionModule,
    TransactionModule,
    ExcelImportModule,
    DamagedProductModule,
    OfferModule,
    DashboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DateInputsModule,
    QuickProductModule,

  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    ExcelService,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
