import { Component, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-sale-rep-by-serial-no',
  templateUrl: './sale-rep-by-serial-no.component.html',
  styleUrls: ['./sale-rep-by-serial-no.component.css']
})
export class SaleRepBySerialNoComponent implements OnInit {

  fileName = 'sales_report_by_serial_number.xlsx';
  sales_agents: any;
  searchval: any;
  pageLoadingImg: boolean;
  salesSum: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  repDate: any;
  period_type: any = 't';
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val1: any;
  inv_val2: any;
  rate_filter: any;
  rate_val1: any;
  rate_val2: any;
  purch_qty_filter: any;
  purch_qty: any;
  purch_qty2: any;
  barcode_filter: any;
  bar_val1: any;
  serial_no:any;
  bar_val2: any;
  prd_id: any;
  cat_id: any;
  added_by: any;
  products: any; catagories: any;
  users: any;
  logoImgUrl: any = '';
  tempImgUrl: any;
  payType = ['Cash', 'Credit'];
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  det_with_prft_type:string;

  total_amount: any;
  total_discount_amount: any;
  total_qty_sold: any;
  branch_all: any;
  filter_branch_id: any;
  userType: any;
  up: any;
  vans: any;
  exportLoader: boolean;
  ExprepDate: any;
  ExpsalesSum: any;
  Exptotal_amount: any;
  Exptotal_discount_amount: any;
  Exptotal_qty_sold: any;
  
  godowns: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  report_type: any;
  rep_type: string;
  Expreport_type: any;
  taxCategories: any;
  total_item_cost: any;
  total_item_profit: any;
  enablePrft: boolean;
  errorPassword: any;
  ExpenablePrft: boolean;
  Exptotal_item_cost: any;
  Exptotal_item_profit: any;
  show = false;
  country_dec: number=2;
  salesPriviewLoader: boolean;
  purchaseItems: any[];
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  resultobj: any;
  showdata: boolean;
  customer: any
  priceGroupList: any;
  price_grp : any = null;
  cust_id:any=null;
  storag_eqp_list = [
    { id: 'no', name: 'No' },
    { id: 'yes', name: 'Yes' },
  ];
  customer_category: any;
  expsalesSum: any;
  exptotal_qty_sold: any;
  exptotal_amount: any;
  exptotal_discount_amount: any;
  cur_lang: string;
  prd: any;
  alias: any;
  



  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
  
   
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.getGodownList();
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();

    // this.getAllBranch();
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append('period_type','t');
    searchval.append("filter_branch_id", this.filter_branch_id);
    this.apiService.salesrepbyserialno(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      
      this.salesSum = res['data']['prdt_list']['data'];
      this.total_qty_sold=res['data'].total_sold_qty
      this.total_amount=res['data'].total_sale_amnt
      this.total_discount_amount=res['data'].total_discount_amnt
      console.log('serial number');
      console.log(this.serial_no);
      console.log("salesSum");
      console.log(this.salesSum);
      this.pageFrom = res['data']['prdt_list']['from'];
      this.curpage = res['data']['prdt_list']['current_page'];
      this.lastpage = res['data']['prdt_list']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
      
      
  
    })
    this.country_dec=this.coreService.setDecimalLength();
    this.getAllTaxCategories();
    this.getAllCustomerCategory();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

     this.userType = this.coreService.getUserData('user_type');
    
  
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.No','Common.Yes']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.storag_eqp_list = [
        { id: 'no', name:  res['Common.No'] },
        { id: 'yes', name: res['Common.Yes'] },
      ];
    });

  


  }
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('price_group', this.price_grp ? this.price_grp : '');
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });
  
  }

  changePriceGroup(){
    this.customer = '';
    this.cust_id = null;
}

getAllTaxCategories(){
  this.apiService.getAllTaxCategory().subscribe((resp) => {

    this.taxCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
  });
}

getAllBranch() {
  this.apiService.readAllBranch().subscribe((res) => {
    this.branch_all = res['data'];
    // this.filter_branch_id = res['selected'];

    // if (this.userType == 'ADMIN') {
    //   this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    // }
  });
}

searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res) => {
    this.branch_all = res['data'];
    // if (this.userType == 'ADMIN') {
    //   this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    // }
  });
}


ngOnDestroy() {
  $('.close').click();

}

getGodownList() {
  let searchval = new FormData();

  this.apiService.getGodownList(searchval).subscribe((res) => {
    this.godowns = res['data'];
  });
}
pageNext(formdata: { value: any; }, pageno: any) {
  this.pageLoadingImg = true;
  if (formdata.value.cust_id === null) {
    formdata.value.cust_id = '';
  }
  this.apiService.salesrepbyserialno(formdata.value, pageno).subscribe((res) => {
    this.pageLoadingImg = false;
    this.repDate = res.data.Date;
    
    this.salesSum = res['data']['prdt_list']['data'];
    this.total_qty_sold=res['data'].total_sold_qty
    this.total_amount=res['data'].total_sale_amnt
    this.total_discount_amount=res['data'].total_discount_amnt
    console.log('serial number');
    console.log(this.serial_no);
    console.log("salesSum");
    console.log(this.salesSum);
    this.pageFrom = res['data']['prdt_list']['from'];
    this.curpage = res['data']['prdt_list']['current_page'];
    this.lastpage = res['data']['prdt_list']['last_page'];
    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);
    
    
    

  })



}

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }



  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });

    });

  }

  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];

    });

  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }
  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    this.apiService.salesrepbyserialno(Expformdata.value, 1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.ExprepDate = res.data.Date;
      this.repDate = res.data.Date;
      
      this.expsalesSum = res['data']['prdt_list']['data'];
      this.exptotal_qty_sold=res['data'].total_sold_qty
      this.exptotal_amount=res['data'].total_sale_amnt
      this.exptotal_discount_amount=res['data'].total_discount_amnt
      // console.log('serial number');
      // console.log(this.serial_no);
      // console.log("salesSum");
      // console.log(this.salesSum);
      // this.pageFrom = res['data']['prdt_list']['from'];
      // this.curpage = res['data']['prdt_list']['current_page'];
      // this.lastpage = res['data']['prdt_list']['last_page'];
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
      
      
      setTimeout(() => {
        this.exportexcel();
      }, 3000);


      Expformdata.value.export = '';
      
  
    });


  }




  sale_search(search) {
    this.salesPriviewLoader = true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }

  getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }


// pdf download
generatePdf(Expformdata: { value: any; }) {

  this.exportLoader = true;

  // debugger;
  Expformdata.value.export = 'export';

  if (Expformdata.value.period_type === 'c') {
    Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
    Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
  }
  this.apiService.salesrepbyserialno(Expformdata.value, 1).subscribe((res) => {
    this.pageLoadingImg = false;
    this.ExprepDate = res.data.Date;
    this.repDate = res.data.Date;
    
    this.expsalesSum = res['data']['prdt_list']['data'];
    this.exptotal_qty_sold=res['data'].total_sold_qty
    this.exptotal_amount=res['data'].total_sale_amnt
    this.exptotal_discount_amount=res['data'].total_discount_amnt

    console.log(this.expsalesSum);
    
    
    const heads = [
      this.translate.instant('Common.date'),
      this.translate.instant('transaction.Branch_Inv_No'),
      this.translate.instant('Sales.Name_Of_The_Customer'),
      this.translate.instant('Table.Item_Price'),
      this.translate.instant('Purchase.vat'),
      this.translate.instant('Table.item_total'),
      this.translate.instant('Table.item_name'),
      this.translate.instant('Table.qty'),
      this.translate.instant('Table.serial_no')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.expsalesSum.forEach(item=>{
        if (this.translate.currentLang === 'Arabic') {
          this.prd= item.prd_alias;
          this.alias= item.ledger_alias_AR;
      } else if (this.translate.currentLang === 'English') {
        this.alias= item.sales_cust_name;
        this.prd= item.prd_name;
      }
        data.push([
          item.salesub_date,
          item.branch_inv_no,
          this.alias,
          (item.sale_amount).toFixed(this.country_dec),
          (item.sale_tax).toFixed(this.country_dec),
          (item.sale_with_tax).toFixed(this.country_dec),
          this.prd,
          item.quantity,
          item.ss_serial_numbers
        ])
      })
  
      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');
  
      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);
  
      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.sales_rep_by_serialno')}`,  valueXPos, headerHeight + 15);
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Sold_Quantity')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.total_qty_sold,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Sale_Amount')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_discount_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 30);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
  
     
      doc.save(`Sales Report By Serial Number.pdf`);


    Expformdata.value.export = '';

    this.exportLoader = false;

  });
  
}


}
