import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Product } from '../../model/product.model';
import { Unit } from '../../model/unit.model';
import { Feature } from '../../model/feature.model';
import { Catagory } from 'src/app/model/catagory.model';
import { Subcatagory } from '../../model/subcatagory.model';
import { Manufacture } from '../../model/manufacture.model';
import { CoreService } from '../../service/core.service';
import { Branch } from '../../model/branch.model';
import { formatDate } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
// modal

import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChild('btnReset', { static: false }) btnReset: ElementRef<HTMLElement>;
  prd_sub_cat_id: any;
  cmpny: any;
  thenBlockBase: any;
  cat_name: any;
  cat_remarks: any;
  cat_pos: any;
  cat_id: any;
  subcat_name: any;
  manftr_comp_name: any;
  manftr_comp_code: any;
  manftr_comp_address: any;
  manftr_comp_phone: any;
  manftr_comp_mobile: any;
  manftr_comp_fax: any;
  manftr_comp_email: any;
  manftr_comp_contact_person: any;
  manftr_comp_website: any;
  manftr_comp_notes: any;
  id: any;
  baseunit: any;
  unit_name: any;
  unit_base_qty: any;
  unit_code: any;
  unit_display: any;
  unit_remarks: any;
  prd_alias: any;
  prd_code: any;
  catid: any;
  favorite_id = 0;
  prd_base_unit_id: any;
  prd_base_unit_ean: any;
  addBarcodes:any;
  prd_exp_dur: any;
  prd_tax_code: any;
  prd_tax: any;
  prd_desc: any;
  prd_minstock_alert: any;
  prd_min_stock: any;
  prd_maxstock_alert: any;
  prd_max_stock: any;
  prd_tax_cat_id: any;
  derunitids: any;
  featids: any;
  setderived = true;
  setfeat = true;
  setstock = true; purchase_rate: any; feat_value: any; baseunitids: any; unit_ean: any;


  displayedColumns: string[] = ['name', 'value'];
  dataSource = [];
  //  unit table 
  prd_unit_ids = [];
  unit_rates = [];
  prd_unit_ids_stock = [];
  p_types_id: number;
  prd_stock_stat = 1;
  bunitforder: string[];
  unit_all: string[];
  subcat_all: string[];


  selectedData: Product = {
    prd_id: null, prd_name: null, error: null, message: null, prd_sub_cat_id: null, prd_cat_id: null,
    prd_base_unit_id: null, prd_minstock_alert: null, prd_maxstock_alert: null, prd_unit_ids: null,
    prd_default_unit_id: null, features: null, unit: null, basic: null, prd_loyalty_rate: null,
    unit_rates: null, data: null, prd_supplier: null
  };
  selectedUnit: Unit = {
    unit_id: null, error: null, unit_name: null,
    unit_base_id: null, message: null, unit_type: null,
    unit_base_qty: null, derived_units_count: null, sur_unit_id: null
  };
  selectedFeature: Feature = { feat_id: null, error: null, feat_name: null, feat_description: null, message: null };

  values: string[];
  derunits: string[];
  features: string[];
  catagories: any[];
  subcatagories: string[];
  unit = { unit_base_id: null };
  datas: string[];
  mfs: string[];
  prods_all: string[];
  buid: number;
  baseunits: [];
  branches: [];
  feats: [];
  changeBox: boolean;
  selDisable: boolean;
  addStock = 0;
  prodsummbasic = [];
  prodid: number;
  cmp_stock_id: number;
  resDup: boolean;
  mrp: number;
  unitsforstock = [];
  branchselids = [];
  stockaddprate: number;
  private modalRef: NgbModalRef;
  closeResult: string;
  result = '';
  erR: any;
  search: string[];
  prd_name: any;
  prd_short_name: any;

  prd_stock_statusUnchecked = false;
  prd_stock_statusChecked = true;
  prd_stock_status = true;
  // p_types = [];
  p_types_id_ckeck = true;
  pageLoadingImg: boolean; userType: any;
  taxCategories = [];
  favorites =[];
  prd_img: any;
  prdImgUrl: any;
  prd_supplier: any;
  base_url: any;
  imageError: string;
  excelfileList: FileList;
  excelErr = '';
  isSubmitInProg = false;
  impRes: any;
  fileName = 'product_import_sample_document.xlsx';
  p_types = [
    { id: 1, name: 'Goods', selected: 1 },
    { id: 0, name: 'Service', selected: 0 }
  ];
  prd_locations: any;
  der_base_unit:any;
  der_unit_name: any;
  der_unit_base_qty: any;
  der_unit_code: any;
  der_unit_display: any;
  der_unit_remarks: any;
  // prd_excs_tax_checked:boolean;
  result2 = "";
  der_unit_type: any = 0;
  addEanBars = [{
    brc_id: 0,
    brc_ean_barcode: '',
    brc_unit_id : 0
  }];
  tmpaddEanBars = [{
    brc_id: 0,
    brc_ean_barcode: '',
    brc_unit_id : 0
  }];
  inProgressMg = '';
  isCompleted = <any>{};
  productimportLimit = 2000;
  genSysUnit=false;
  added_rows_count = 0;
  existing_rows_count = 0;
  existing_rows = [];
  checkIsExciseEnble: boolean;
  excstaxCategories: any;
  prd_excs_tax_cat_id: any;
  prd_excs_tax_type: any;
  is_include: any;
  same_mult_unit: any;
  setstockloading: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private translate1: TranslateService, private modalService: NgbModal, private router: Router) { }


  ngOnInit() {
    this.translate1.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.apiService.getClientSettingBykey({ key: "excise_duty_enable" }).subscribe((res) => {
      if (res["data"]) {
        this.checkIsExciseEnble = res["data"]["cs_value"] ? true : false;
        
        let searchval=new FormData;
        this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
          this.excstaxCategories = res.data;
        });

      }
    });
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });

    this.apiService.getAllFavorites(1,1000).subscribe((resp) => {

      console.log("ggggggggggg");
      console.log(resp.data);
      this.favorites = resp.data;
    });

    this.userType = this.coreService.getUserData('user_type');
    $('#dfeat_valid').hide();
    if (this.p_types_id_ckeck == true) {
      this.p_types_id_ckeck = false;
    } else {
      this.p_types_id_ckeck = true;
    }
    $('#stock_status').show();
    this.prd_stock_statusUnchecked = false;
    this.prd_stock_statusChecked = true;
    this.prd_stock_status = true;
    // this.p_types = [
    //   { id: 1, name: 'Goods', selected: 1 },
    //   { id: 0, name: 'Service', selected: 0 }
    // ];

    this.p_types_id = 1;
    this.prd_stock_stat = 1;
    var id = 0;
    this.apiService.readBase(id).subscribe((units: Unit[]) => {
      this.values = units['data'];
    });
    this.apiService.readFeat().subscribe((features: Feature[]) => {
      this.feats = features['data'];
    });
    this.readcatSub();
    // this.readmanfs();
    this.readBaseUnits();
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      this.branches = branch['data'];
    });

    // listing all current locations
    this.apiService.getAllPrdLoactions(1).subscribe((res) => {
      this.prd_locations = res.data
    });
    $('#addbatchbtn').hide();
    $('#stkbatchsel').hide();

    this.coreService.getToken();
    $("#base-select").prop('disabled', false);
    $("#bunit_ean").prop('disabled', false);
    
    this.cmpny = this.coreService.getUserData('cmpny');
    this.base_url = this.apiService.PHP_API_SERVER;
    this.getPrdSetting();
  }
  getPrdSetting() {
    this.apiService.getPrdSetting().subscribe((res) => {
      if (res['data']) {
        this.p_types_id = res['data'].default_item_creation;
      } else { 
        this.p_types_id = 1;
      }
      this.selectPtype(this.p_types_id)
    });
  }

  langChange(){
    this.translate1.get(['Product.Goods', 'Product.Services']).subscribe((res: string) => {    
     
      
      this.p_types = [
        { id: 1, name: res['Product.Goods'], selected: 1 },
        { id: 0, name: res['Product.Services'], selected: 0 }
      ];
    });

  }
  createOrUpdateProduct(form: { value: Product; reset: () => void; }) {
    if (this.selectedData && this.selectedData.prd_id) {
    }
    else {
      //  low stock varning
      if (form.value.prd_minstock_alert == true) {
        form.value.prd_minstock_alert = 1;
      }
      else {
        form.value.prd_minstock_alert = 0;
      }
      if (form.value.prd_maxstock_alert == true) {
        form.value.prd_maxstock_alert = 1;
      }
      else {
        form.value.prd_maxstock_alert = 0;
      }
      //  newly added
      form.value.prd_base_unit_id = this.buid;
      if (form.value.prd_unit_ids) {
        form.value.prd_unit_ids = this.prd_unit_ids;
      } else {
        form.value.prd_unit_ids = this.prd_unit_ids;

      }

      // remove duplicates
      var names = form.value.prd_unit_ids;
      var uniqueNames = [];
      $.each(names, function (i, el) {
        if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
      });

      form.value.prd_unit_ids = uniqueNames;
      form.value.prd_default_unit_id = form.value.prd_base_unit_id;

      // unit rates
      form.value.unit_rates = this.unit_rates;
      // feature 
      if (this.dataSource) {
        form.value.features = this.dataSource;
      } else {
        form.value.features = [];
      }
      form.value.unit = null;
      form.value.basic = null;
      form.value.prd_loyalty_rate = 12;


      this.apiService.createProduct(form.value).subscribe((product: Product) => {
        if (product.error != null) {
          this.result = product.error;
        }
        else {
          this.coreService.openSnackBar(product.message, 'Close');
          this.result = '';
          this.dataSource = [];
          this.prd_unit_ids = [];
          this.unit_rates = [];
          this.features = [];
          $("#fvlue").val(" ");
          form.reset();
          $('#basic').show();
          $('#feature').hide();
          $('#inputbase').show();
          $('#inputder').hide();

        }

      });
    }

  }

  createOrUpdateProductStock(form: { value: Product; reset: () => void; }) {
    if (this.selectedData && this.selectedData.prd_id) {
    }
    else {
      //  low stock varning
      if (form.value.prd_minstock_alert == true) {
        form.value.prd_minstock_alert = 1;
      }
      else {
        form.value.prd_minstock_alert = 0;
      }
      if (form.value.prd_maxstock_alert == true) {
        form.value.prd_maxstock_alert = 1;
      }
      else {
        form.value.prd_maxstock_alert = 0;
      }
      //  newly added
      form.value.prd_base_unit_id = this.buid;
      if (form.value.prd_unit_ids) {
        form.value.prd_unit_ids = this.prd_unit_ids;
      } else {
        form.value.prd_unit_ids = this.prd_unit_ids;

      }

      // remove duplicates
      var names = form.value.prd_unit_ids;
      var uniqueNames = [];
      $.each(names, function (i, el) {
        if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
      });

      form.value.prd_unit_ids = uniqueNames;
      form.value.prd_default_unit_id = form.value.prd_base_unit_id;

      // feature 
      if (this.dataSource) {
        form.value.features = this.dataSource;
      } else {
        form.value.features = [];
      }
      form.value.unit = null;
      form.value.basic = null;
      form.value.prd_loyalty_rate = 12;

      // add unit rate 
      var upt_unit_rates = [];
      var units_rates = [];
      var units_rates_udt = [];
      var errorobj = false;
      $('input[name="unitratechk"]:checked').each(function () {


        upt_unit_rates.push({ "unit_id": $(this).val() });
      });

      $('input.edit_unit_rate').each(function () {
        var attr_id = this.getAttribute("id");
        var unit_rt = $(this).val();

        $.each(upt_unit_rates, function (index, value) {
          if (value.unit_id == attr_id) {
            if ((Number(unit_rt) < 0) || (!unit_rt)) {
              errorobj = true;
            }
            units_rates_udt.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
          }
        });

      });


      form.value.unit_rates = units_rates_udt;
      if (errorobj) {
        $('#error_in').html('Required positive value for unit rates');
      }
      else {
        $('#error_in').html('');
      }



      if (!errorobj) {
        this.apiService.createProduct(form.value).subscribe((product: Product) => {
          if (product.error != null) {
            this.result = product.error;
          }
          else {
            this.coreService.openSnackBar(product.message, 'Close');
            this.result = '';
            this.dataSource = [];
            this.prd_unit_ids = [];
            this.unit_rates = [];
            this.features = [];
            $("#fvlue").val(" ");
            form.reset();
            $('#basic').show();
            $('#feature').hide();
            $('#inputbase').show();
            $('#inputder').hide();
            $('#stock').hide();
            $("#base-select").prop('disabled', false);
            $("#bunit_ean").prop('disabled', false);
            this.selDisable = false;

          }

        });
      }
      //end if for update
    }

  }

  updateExcsTaxPerc(){
    
    this.prd_excs_tax_type=1;
  }
  addBase() {
    $(".unitclass").toggle();
  }

  addFeat() {
    $(".featclass").toggle();
  }

  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.prd_tax = selTax[0].taxcat_tax_per;
  }

  selectSubCats(catid: number) {

    this.prd_sub_cat_id = null;
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      // if (this.changeBox)
      //   this.changeBox = false;
      // else
      //   this.changeBox = true;
    });

    const selRow = this.catagories.filter((categ) => categ.cat_id === catid);
    this.prd_tax_cat_id = selRow[0].cat_tax_cat_id;
    this.updateTaxPerc(selRow[0].cat_tax_cat_id);
  }


  // Feature Table

  addFeatureValue(featids: Feature, addfeatf) {
    this.apiService.createFeatList(addfeatf.value).subscribe((prod: Product) => {
      this.pageLoadingImg = false;
      if (prod.error != null) {
        this.result = prod.error;
      } else {
        this.result = '';
        // check duplicates
        const found = this.dataSource.some(el => el.feat_id === featids.feat_id);
        if (found == false) {
          var featv = $("#fvlue").val();
          this.dataSource.push({ "feat_id": featids.feat_id, "feat_name": featids.feat_name, "feat_val": featv.toString() });
          $("#fvlue").val('');
          this.result = '';
          addfeatf.reset();
          $('#dfeat_valid').hide();
        } else {
          $('#dfeat_valid').show();
        }
      }
    });
  }

  deleteFeat(id: number) {
    var type = id,
      i: number;
    for (i = this.dataSource.length - 1; i >= 0; --i) {
      if (this.dataSource[i].feat_id == type) {
        this.dataSource.splice(i, 1);
      }
    }

  }

  //  end Feature table


  // add unit   
  //add  base unit in display table
  addUnitValue(baseunitids: Unit) {
    if (baseunitids) {
      $(".bunit_valid").text('');
      var bean = $("#bunit_ean").val();
      if (bean) {
        bean = bean.toString();
        $("#bunit_ean").val('');
      }
      else {
        bean = "";
      }
      this.prd_unit_ids.push({ "unit_type": 1, "unit_id": baseunitids.unit_id, "unit_name": baseunitids.unit_name, "ean_barcode": bean, "unit_rate": 0 });
      //  Remove duplicates     
      this.prd_unit_ids = this.coreService.removeDumplicateValueunit(this.prd_unit_ids);
      $("#buvlue").val('');
      $("#burate").val('');
      $(".bunitrate_valid").text('');
      $('#addbase-btn').hide();
      $('#base-select').hide();
      $('#bunit_ean').hide();
      $('#inputder').show();
    }
    else {
      $(".bunit_valid").text('');
    }
  }

  //add  base and der unit in display table
  addUnitValues(derunitids: Unit, baseunitids: Unit, addunitlist) {
    $('#dunit_valid').hide();
    this.apiService.createUnitList(addunitlist.value,this.tmpaddEanBars ).subscribe((prod: Product) => {
      this.pageLoadingImg = false;
      if (prod.error != null) {
        this.result = prod.error;
      } else {
        this.result = '';
        
        // add base unit in table
        if (this.buid && derunitids.unit_id) {
          $(".bunit_valid").text('');
          var bean = $("#bunit_ean").val();
          if (bean) {
            bean = bean.toString();
            $("#bunit_ean").val('');
          }
          else {
            bean = "";
          }
          this.prd_unit_ids.push({ "unit_type": 1, "unit_id": baseunitids.unit_id,
           "unit_name": baseunitids.unit_name, "ean_barcode": bean, "unit_rate": 0, addBarcodes: JSON.parse(JSON.stringify(this.tmpaddEanBars)) });
           this.tmpaddEanBars = [{
            brc_id: 0,
            brc_ean_barcode: '',
            brc_unit_id : 0
          }];
          //  Remove duplicates     
          this.prd_unit_ids = this.coreService.removeDumplicateValueunit(this.prd_unit_ids);
          $("#buvlue").val('');
          $("#burate").val('');
          $(".bunitrate_valid").text('');
          $("#base-select").prop('disabled', true);
          $("#bunit_ean").prop('disabled', true);
          this.selDisable = true;
          $('#inputder').show();

        }
        else {
          $(".bunit_valid").text('');
        }
        //  end add base unit in table

        //  add der unit
        if (derunitids && derunitids.unit_id) {
          var featv = $("#duvlue").val();
          var fdeurate = $("#durate").val();
          var dean = $("#dunit_ean").val();
          if (dean) {
            dean = dean.toString();
            $("#dunit_ean").val('');
          }
          else {
            dean = "";
          }

          // check duplicates     
          var res = this.prd_unit_ids.some(el => el.unit_id === derunitids.unit_id);
          var resEan = this.prd_unit_ids.some(el => el.ean_barcode === dean);
          if (dean == "")
            resEan = false;
          if (res == false && resEan == false) {
            this.prd_unit_ids.push({ "unit_type": 0, "unit_id": derunitids.unit_id, "unit_name": derunitids.unit_name, 
            "ean_barcode": dean, "unit_rate": fdeurate.toString(), addBarcodes: JSON.parse(JSON.stringify(this.tmpaddEanBars))  });
            this.tmpaddEanBars = [{
              brc_id: 0,
              brc_ean_barcode: '',
              brc_unit_id : 0
            }];
            //  Remove duplicates     
            this.prd_unit_ids = this.coreService.removeDumplicateValueunit(this.prd_unit_ids);
            $("#duvlue").val('');
            $("#durate").val('');
            $('#dunit_valid').hide();

          } else {
            $('#dunit_valid').show();
          }
        }
        //  end der units

      }
    });
    //  end addunitlist form

  }

  deleteUnit(data: Unit) {
    if (data.unit_type == 1) {
      var res = confirm("Removing Base Unit will remove Derived units from the table");
      if (res) {
        this.prd_unit_ids = [];
        $('#dunit_valid').hide();
        $('#addbase-btn').show();
        $("#base-select").prop('disabled', false);
        $("#bunit_ean").prop('disabled', false);
        $("#buvlue").val('');
        $("#burate").val('');
        this.readBaseUnits();
        $('#inputder').hide();
        this.selDisable = false;
      }
    } else {
      var type = data.unit_id,
        i: number;
      for (i = this.prd_unit_ids.length - 1; i >= 0; --i) {
        if (this.prd_unit_ids[i].unit_id == type) {
          this.prd_unit_ids.splice(i, 1);
        }
      }
    }

  }


  // end unit display table

  pageoneSubmit(form: { value: Product; }) {
    form.value.basic = 1;
    this.apiService.createProduct(form.value).subscribe((product: Product) => {

      if (product && product.error != null) {
        this.result = product.error;
      }
      else {
        $('#basic').hide();
        $('#stock').hide();
        $('#feature').hide();
        $('#unit').show();


      }

    });

  }

  pageunitSubmit(form: { value: Product; }) {
    $('#dunit_valid').hide();
    form.value.basic = null;
    if (this.prd_unit_ids.length > 0) {
      form.value.prd_base_unit_id = this.buid;
    } else {
      form.value.prd_base_unit_id = null;
    }
    if (form.value.prd_base_unit_id == undefined) {
      $(".bunit_valid").text('Required');
    } else {
      form.value.unit = 1;
      this.apiService.createProduct(form.value).subscribe((product: Product) => {
        if (product && product.error != null) {
          this.result = product.error;

        }
        else {
          $('#basic').hide();
          $('#unit').hide();
          $('#feature').show();
          $('#stock').hide();

        }

      });

    }
    // end if   base unit valid
  }

  addStockProduct() {
    $('#basic').hide();
    $('#unit').hide();
    $('#feature').hide();
    $('#stock').show();

  }

  backUnit() {
    $('#basic').hide();
    $('#unit').show();
    $('#feature').hide();
    $('#stock').hide();
  }

  backBasic() {
    $('#basic').show();
    $('#unit').hide();
    $('#feature').hide();
    $('#stock').hide();
  }

  backFeature() {
    $('#basic').hide();
    $('#unit').hide();
    $('#feature').show();
    $('#stock').hide();
  }

  // search
  searchProduct(name: string) {
    let searchval = new FormData();
    searchval.append("prod_name", name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prods_all = res['data'];
    });
  }

  //Remove Name Suggestions



  readBaseUnits() {
    this.apiService.readBaseUnits().subscribe((baseunt: Unit[]) => {
      this.baseunits = baseunt['data'];
    });
  }

  // new codes  

  selectPtype(prdtypes: number) {

    if (prdtypes == 1) {
      $('#stock_status').show();
    } else {
      $('#stock_status').hide();
    }
  }

  addNew(form) {
    form.reset();
    this.result = '';
    this.ngOnInit();
    $('#basic').show();
    $('#stock').hide();
    $('#feature').hide();
    $('#unit').hide();
    this.setderived = true;
    this.setfeat = true;
    this.setstock = true;
    $('#prrate').val('');

  }

  productBaseSubmit(formgroup) {
    this.pageLoadingImg = true;
   // console.log(this.p_types_id);
    // formdata.value.prd_stock_status=false;
    let searchval = new FormData(formgroup);

    if (this.p_types_id == 0)
      searchval.set('prd_stock_status', 'false');
    else
    searchval.set('prd_stock_status',""+this.prd_stock_status+"");

    if(this.checkIsExciseEnble){
      searchval.append('prd_excs_tax_type', this.prd_excs_tax_type);
      searchval.append('prd_excs_tax_cat_id', this.prd_excs_tax_cat_id);
    }

    searchval.append('prd_cat_id', this.catid);
    searchval.append('prd_sub_cat_id', this.prd_sub_cat_id);
    searchval.append('prd_base_unit_id', this.prd_base_unit_id);
    searchval.append('prd_tax_cat_id', this.prd_tax_cat_id);
    searchval.append('prd_supplier', this.prd_supplier);
    searchval.append('prd_type', this.p_types_id.toString());
    searchval.append('addBarcodes', JSON.stringify(this.addEanBars));
    searchval.append('favorite_id', this.favorite_id.toString());

    this.apiService.createProductBase(searchval).subscribe((product: Product) => {
      this.pageLoadingImg = false;


      if (product && product.error != null) {
        this.result = product.error;
      }
      else {
        $('#basic').hide();
        $('#stock').hide();
        $('#feature').hide();
        $('#unit').show();
        this.prodsummbasic = product['data'];
        this.prodid = product['data'][0].prd_id;
        this.cmp_stock_id = product['data'][0].cmp_stock_id;
        this.unitsforstock.push({ "unit_type": 1, "unit_id": product['data'][0].unit.unit_id, "unit_name": product['data'][0].unit.unit_name, "unit_base_qty": 1 });
      }

    });


    //  $('#unit').show(); 
  }
  
  // selct der unit and add base unit in table
  selectDerUnits(baseunitids: number) {
    if (baseunitids) {
      this.der_base_unit = baseunitids;
      // sel der unit    
      this.apiService.readDerbyId(baseunitids).subscribe((res: Unit[]) => {
        this.derunits = res['data'];

      });
    }
  }

  //form add unit
  productUnitSubmit(formgroup: { value: any; }) {
    this.unitsforstock = [];
    formgroup.value.prd_id = this.prodid;
    formgroup.value.prd_units = this.prd_unit_ids;
    this.prd_unit_ids_stock.push(this.prd_unit_ids);
    this.apiService.createProductUnit(formgroup.value).subscribe((res: Product) => {
      if (res.error != null) {

      }
      else {
        this.coreService.openSnackBar('Updated', 'Close');
        this.unitsforstock = res['data'];
        this.prd_unit_ids = [];
        this.derunitids = 'Derived Units';
        $('#dunit_valid').hide();
        this.setderived = false;
        res.error = '';

      }

    });

  }

  //form add Features
  productFeatSubmit(formgroup: { value: any; }) {
    formgroup.value.prd_id = this.prodid;
    formgroup.value.prd_features = this.dataSource;
    this.apiService.createProductFeat(formgroup.value).subscribe((res: Product) => {
      if (res.error != null) {
      }
      else {
        this.coreService.openSnackBar('Updated', 'Close');
        this.dataSource = [];
        this.featids = 'Select Features';
        this.setfeat = false;



      }

    });


  }


  //form add Stock
  productStkSubmit(formgroup: { value: any; }) {
    var upt_unit_rates = [];
    var unit_rates = [];
    var errorobj = false;
    var errordp = this.errValidDp(this.resDup = null);
    // add unit ids  
    $('input[name="unitratechk"]:checked').each(function () {

      upt_unit_rates.push({ "unit_id": $(this).val() });
    });
    //  add unit rates for final sotck submit arr:  unit_rates
    $('input.edit_unit_rate').each(function () {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();
      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          //  unit validation  
          // console.log(unit_rt);        
          if (!unit_rt) {
            errorobj = true;
          } else if (unit_rt > 0) {
            errorobj = false;
          } else if (unit_rt == 0) {
            errorobj = false;
          } else {
            errorobj = true;
          }

          unit_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
        }
      });

    });
    this.mrp = (unit_rates[0].unit_rate);

    formgroup.value.prd_id = this.prodid;
    formgroup.value.cmp_stock_id = this.cmp_stock_id;
    formgroup.value.unit_rates = unit_rates;
    // validation
    var errorobjunitrate = this.errValidSr(errorobj);
    var prval = $('#prrate').val();
    var errorobjprate = this.errValidPrt(prval);
    if (!errorobjprate && !errorobjunitrate) {
      this.setstockloading=true;
      this.apiService.createProductStock(formgroup.value).subscribe((res: Product) => {
        this.setstockloading=false;
        if (res.error != null) {

        }
        else {
          this.addStock = 1;
          this.coreService.createfunct(formgroup, res.error, 'Updated');
          // $('#set-stockModal').hide();
          // $('.modal-backdrop').hide();
          this.unitsforstock = [];
          // this.stockaddprate = res['data']['purchase_rate'];
          //  $('#prrate').val(this.stockaddprate);
          this.setstock = false;
          this.branchselids = [];
          // let element:HTMLElement = document.getElementById('set-stockModal') as HTMLElement;
          // element.click();
          $('#clsStckModal').trigger('click');
          // formgroup.value.reset();       
        }
      });
    }


  }

  // validation
  errValidSr(errorobj: boolean) {
    if (errorobj == true) {
      $('#err_sr').show();
      return true;
    }
    else {
      $('#err_sr').hide();
      errorobj = false;
      return false;
    }
  }
  errValidDp(errorobj: boolean) {
    if (errorobj == true) {
      $('#err_dp').show();
      return true;
    }
    else {
      $('#err_dp').hide();
      return false;

    }
  }

  errValidQty(errFied: number) {
    if (Number(errFied) <= 0 || isNaN(errFied)) {
      $('#err_opqty').show();
      return true;
    } else {
      $('#err_opqty').hide();
      return false;
    }

  }

  errValidPrt(errFied: any) {
    if (!errFied) {
      $('#err_prate').show();
      return true;
    } else {
      $('#err_prate').hide();
      return false;
    }

  }

  // catagory
  //form search  catagory
  searchCat(search: string, flag) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    if (flag == 'y')
      $('.prev-list-cat').show();
  }


  addCatagory(formdata: { value: Catagory; }) {
    this.apiService.createCat(formdata.value).subscribe((catagory: Catagory) => {
      if (catagory.error != null) {
        this.result = catagory.error;
      }
      else {
        this.coreService.createfunct(formdata, this.result, catagory.message);
        this.modalRef.close();
        this.readcatSub();
      }

    });
  }
  translate(){
    if(this.prd_name){
      let searchval = new FormData();
      searchval.append("prd_name", this.prd_name);
      this.apiService.translateProductName(searchval).subscribe((res) => {
        
        this.prd_alias = res['data'];
      });
    }
  }

  openModal(content: any, size) {

    $('#idname').hide();
    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  showCalculateMdl(id: number) {
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#mrp_id").val(id);
    $("#calculateMdl").show();
  }
  closeCalculateMdl(){
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#sp_tax").val('');
    $("#calculateMdl").hide();
  }
  calculateTax(keyword){
    if(!isNaN(keyword)){

      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $("#sp").val(p);
      $("#sp_tax").val(t);
    }else{
      $("#sp").val('');
      $("#sp_tax").val('');
    }
  }
  showCalculatedTax(){
    let mrp_id = $("#mrp_id").val();
    let sp = $("#sp").val();
    $(".stk_mrp_"+mrp_id).val(sp);
    $("#calculateMdl").hide();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // sub catagory
  createSubCat(formdata: { value: Subcatagory; }) {
    this.apiService.createSubCat(formdata.value).subscribe((res: Subcatagory) => {
      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.result = '';
        this.coreService.createfunct(formdata, this.result, res.message);
        this.modalRef.close();
        this.readcatSub();
      }

    });
  }

  //form search
  searchSubcat(search: string, flag) {
    let searchval = new FormData();
    searchval.append("subcat_name", search);
    this.apiService.getSubcatByName(searchval).subscribe((res) => {
      this.subcat_all = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-subcat').show();
  }



  createManf(formdata: { value: Manufacture; }) {
    this.apiService.createManf(formdata.value).subscribe((res: Manufacture) => {
      if (res.error != null) {
        this.result = res.error;
      }
      else {
        this.result = '';
        this.coreService.createfunct(formdata, this.result, res.message);
        this.readmanfs();
        this.modalRef.close();
      }

    });
  }


  //form search 
  searchManf(search: string, flag) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-manf').show();

  }



  createUnit(form: { value: Unit; }) {
    if (form.value.unit_type == 1) {
      form.value.unit_base_id = 0;
      form.value.unit_base_qty = 1;
    }
    this.apiService.createUnit(form.value).subscribe((unit: Unit) => {
      if (unit.error != null) {
        this.result = unit.error;
      }
      else {
        this.result = '';
        this.coreService.createfunct(form, this.result, unit.message);
        this.readBaseUnits();
        this.modalRef.close();
      }

    });

  }

  // unit types
  unit_types = [
    { id: 1, name: 'Base Unit' },
    { id: 0, name: 'Derived Unit' }
  ];


  selectType(id: number) {
    if (id == 0) {
      $(".buclass").show();
      this.apiService.readBase(id).subscribe((units: Unit[]) => {
        this.bunitforder = units['data'];
      });
      $("#uqtysh").prop("disabled", false);

    } else {
      $(".buclass").hide();
      $("#uqtysh").prop("disabled", true);
    }
  }

  //form search unit
  searchUnit(search: string, flag) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-unit').show();
  }

  removeUnitsug() {
    $('.prev-list-unit').hide();
  }




  readcatSub() {
    this.apiService.readCat().subscribe((catagories: Catagory[]) => {
      this.catagories = catagories['cat'];
      this.subcatagories = catagories['subcat'];
    });
  }

  readmanfs() {
    this.apiService.readManfs().subscribe((datas: Manufacture[]) => {
      this.mfs = datas['data'];
    });
  }

  // search product
  selectProd(name: string) {
    this.search = [];
    let searchval = new FormData();
    searchval.append("prod_name", name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.search = res['data'];
    });
    if (this.search)
      $('.prev-list').show();

  }

  removeName() {
    this.prods_all = [];
    this.unit_all = [];
    $('.prev-list').hide();
    if (this.prd_name != "")
      // this.prd_short_name = this.prd_name.substring(0, 4);
    this.prd_short_name = this.prd_name;
  }
  setDefault(val,index){
    this.prd_unit_ids.forEach(function(item){ delete item.is_default });
    this.prd_unit_ids[index].is_default = val;
  }

  removeCat() {
    $('.prev-list-cat').hide();
  }

  removeSubCat() {
    $('.prev-list-subcat').hide();
  }

  removeManf() {
    $('.prev-list-manf').hide();
  }


  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.prd_img = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.prdImgUrl = event.target.result;
    }

  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }



      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          if (img_height > max_height || img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              ' X ' +
              max_width +
              'px';
            return false;
          } else {
            this.prdImgUrl = e.target.result;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);




    }
  }

  clearForm() {
    this.btnReset.nativeElement.click();
    this.impRes = null;
  }

  fileChange(event) {
      this.excelfileList = event.target.files;
      
  }
  import(){
    this.excelErr = "";
    if(this.excelfileList && this.excelfileList.length > 0) {
      this.impRes = null;
      let file: File = this.excelfileList[0];
      let formData:FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('show_reason', '1');
      this.isSubmitInProg = true;
      this.apiService.importProductExcel(formData).subscribe((res) => {
        // console.log(this.staff);
        this.btnReset.nativeElement.click();
        this.isSubmitInProg = false;
        this.impRes = res.data;
        console.log(this.impRes);
        // if (res.error != null) {
        //   this.excelErr = res.error;
        // } else {
        //   this.closeModal.nativeElement.click();
        //   this.clearForm();
        // }

      });
      // let headers = new Headers();
      // /** In Angular 5, including the header Content-Type can invalidate your request */
      // headers.append('Content-Type', 'multipart/form-data');
      // headers.append('Accept', 'application/json');
      // let options = new RequestOptions({ headers: headers });
      // this.http.post(`${this.apiEndPoint}`, formData, options)
      //     .map(res => res.json())
      //     .catch(error => Observable.throw(error))
      //     .subscribe(
      //         data => console.log('success'),
      //         error => console.log(error)
      //     )
    } else{
      this.excelErr = "Please Choose An Excel File";
    }
  }
  importNew(){
    this.inProgressMg = "";
    this.excelErr = "";
    if(this.excelfileList && this.excelfileList.length > 0) {
      this.impRes = null;
      let file: File = this.excelfileList[0];
      let formData:FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('show_reason', '1');
      formData.append('start', '');
      formData.append('limit', '');
      formData.append('genSysUnit', '');
      formData.append('is_include', this.is_include);
      this.isSubmitInProg = true;
      this.inProgressMg = "Don't close this window wait till complete,Processing.....";
      this.added_rows_count = 0;
      this.existing_rows_count = 0;
      this.existing_rows = [];
      this.apiService.getExcelRowCount(formData).subscribe((res) => {
        console.log('count');
        console.log(res);
        if (res.error) {
          this.isSubmitInProg = false;
          this.inProgressMg = "";
          if(res.errmsg && res.errmsg.length > 0){
            this.excelErr = res.errmsg[0];
          } else{
            this.excelErr = 'Somthing Went Wrong..!!!';
          }
        } else{
          if(res.data.count > 0){
            this.inProgressMg += "<br>Total Row : " + res.data.count + "<br>Importing Started from Row 1 .......";
            
            var parts = Math.ceil(res.data.count /this.productimportLimit);
            console.log(parts);
            // this.noPage = this.createRange(this.ttlPage);
            // isCompleted 
          
            this.importExcel(formData, 0, parts);
            // this.importExcel(formData);
          } else{
            this.isSubmitInProg = false;
            this.inProgressMg = "No Data Found";
          }
        }
      });

    } else{
      this.excelErr = "Please Choose An Excel File";
    }
  }

  importExcel(formData, i, parts){
    console.log('parts started ' + i);
    formData.set('start', (i* this.productimportLimit));
    formData.set('limit', this.productimportLimit);
    formData.set('genSysUnit', this.genSysUnit);
    let callFunction = '';
  
    callFunction = 'importProductExcelNew';
    if (this.same_mult_unit) {
      callFunction = 'importProductExcelNewSameUnit';
    }
  
    this.apiService[callFunction](formData).subscribe((res) => {
        // return res;
        console.log(res);
        this.added_rows_count += res.data.added_rows_count;
        this.existing_rows_count += res.data.existing_rows_count;
        this.existing_rows.push(...res.data.existing_rows);

        if((i+1) < parts){
          this.importExcel(formData, i+1, parts);
          this.inProgressMg += "<br>Importing " + ((i* this.productimportLimit) +1) + ' To ' + ((i* this.productimportLimit) +  this.productimportLimit)+ " Completed, Importing Started from " + (((i+1)* this.productimportLimit) +1)  + " .......";
        } else {
          this.isSubmitInProg = false;
          this.inProgressMg += "<br>Importing Completed";
          this.impRes = { 
            added_rows_count:this.added_rows_count,
            existing_rows_count: this.existing_rows_count,
            existing_rows : this.existing_rows
          };
          
          console.log(this.impRes);
        }
    });
  }
  downloadSheet() {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  downloadFailedSheet() {
    /* table id is passed over here */
    let element = document.getElementById('export-failed');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'product_import_failed_items.xlsx');
  }

  addLocation() {
    $('#srchbatchbtn').show();
    $('#addbatchbtn').hide();
    $('#stkbatchsel').hide();
    $('#batch1').show();

  }

  searchLoc() {
    $('#srchbatchbtn').hide();
    $('#addbatchbtn').show();
    $('#stkbatchsel').show();
    $('#batch1').hide();
  }
  createDUnit(form: { value: any; }) {
    this.apiService.createUnit(form.value).subscribe((unit: Unit) => {
      if (unit.error != null) {
        this.result2 = unit.error;
      }
      else {
        this.result2 = '';
        this.coreService.showMessage(unit.message);
        this.der_unit_name = '';
        this.der_unit_base_qty= '';
        this.der_unit_code= '';
        this.der_unit_display= '';
        this.der_unit_remarks= '';
        this.apiService.readDerbyId(this.der_base_unit).subscribe((res: Unit[]) => {
          this.derunits = res['data'];
        });
        document.getElementById('closeAddMdl').click();
      }

    });
  }
  searchDUnit(search: string, flag) {
    let searchval = new FormData();
    this.der_unit_code= search;
    this.der_unit_display= search;
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-unit').show();
  }

  addMore() {

    this.addEanBars.push({
      brc_id: 0,
      brc_ean_barcode: '',
      brc_unit_id : 0
    });
  }
  addMoretmp() {

    this.tmpaddEanBars.push({
      brc_id: 0,
      brc_ean_barcode: '',
      brc_unit_id : 0
    });
  }

  remove(index){
    this.addEanBars.splice(index, 1);
  }
  removetmp(index){
    this.tmpaddEanBars.splice(index, 1);
  }
  // clearAddbarcodeTmp(){

  // }

  searchFav(search: string, flag) {
    let searchval = new FormData();
    searchval.append("fav_name", search);
    this.apiService.getFavoritebyName(searchval).subscribe((res) => {
      this.favorites = res['data'];
    });
  
  }
}





