import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-database-backup',
  templateUrl: './database-backup.component.html',
  styleUrls: ['./database-backup.component.css']
})
export class DatabaseBackupComponent implements OnInit {

constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService, public router: Router) { }
  isbkpEnabled = false;
  info = [];
  cmpny = '';
  filePath = '';
  publicPath = '';
  status ={
    0: 'Deleted', 
    1: 'Finished', 
    2: 'Inprogress', 
    3: 'Failed'	
  }
  ngOnInit() {

    this.cmpny = this.coreService.getUserData('cmpny');
    this.publicPath = (this.apiService.PHP_API_SERVER.indexOf('127.0.0.1') !== -1) ? '' : '/public';
    console.log('aju ' + this.publicPath);
    this.filePath = this.apiService.PHP_API_SERVER + this.publicPath + '/dbbackup/' + this.cmpny + '/';

    this.apiService.getClientSettingBykey({ key: 'db_bkp' }).subscribe((res) => {
      if (res['data']) {
        this.isbkpEnabled = (res['data']['cs_value']) ? true : false;
      }

      if(!this.isbkpEnabled){
        this.router.navigate(['/master'])
      } else{
        this.getAllBkp();
      }
    });

  }

  createDatabaseBackup(){
    this.apiService.createDatabaseBackup().subscribe((res) => {
      this.getAllBkp();
      this.coreService.showMessage(res.message);
    });
  }

  getAllBkp(){
    this.apiService.getBackups().subscribe((res) => {
      this.info = res.data;
      if(this.info.findIndex((data)=>data.status ==2) != -1){
        setTimeout(()=>{ this.getAllBkp() }, 10000);
      }
    });
  }

  deleteDBBackUp(id,indx){
    this.info[indx].loadingImg = true;
    this.apiService.deleteDBBackUp(id).subscribe((res) => {
      this.getAllBkp();
      this.coreService.showMessage(res.message);
    })

  }

}
