import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-fuel-profit-loss',
  templateUrl: './fuel-profit-loss.component.html',
  styleUrls: ['./fuel-profit-loss.component.css']
})
export class FuelProfitLossComponent implements OnInit {
  branch_all: any;
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  userType: any;
  pageLoadingImg: boolean;
  feulProfitLoss: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
     
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
     
    }
    this.apiService.fuelProfitAndLoss(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.feulProfitLoss = res['data'];
      // this.repDate=res['data']['Date'];
     
      
      
  
    })
  
  
  
  }

}
