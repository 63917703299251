import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCurrencyPipe } from '../custom-currency.pipe';



@NgModule({
  declarations: [
    CustomCurrencyPipe  
  ],
  exports: [
    CustomCurrencyPipe  // Export it so it can be used in other modules
  ],
  imports: [
    CommonModule,  
  ],
  
})
export class SharedModule { }
