import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ext-transfer-inv-based',
  templateUrl: './ext-transfer-inv-based.component.html',
  styleUrls: ['./ext-transfer-inv-based.component.css']
})
export class ExtTransferInvBasedComponent implements OnInit {
  fileName= 'external_transfer_invoice_based.xlsx';
  salesSum: any;
  pageLoadingImg: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_amount: any; 
  total_discount_amount: any;
  total_qty_sold: any;
  pgend: boolean;
  pgstart: boolean;
  repDate: any;
  branch_all: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  report_type_filter = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' },
    { id: 'detailed_with_mrp', name: 'Detailed With Sales Rate' }
  ];


  inv_filter: any;
  searchProducts: any[];
  company: any;
  categories: any;
  godowns: any;
  vans: any;
  subcatagories: any;
  values: any;
  exportLoader: boolean;
  ExpsalesSum: any;
  ExprepDate: any;
  Exptotal_amount: any;
  Exptotal_mrp: any;
  Exptotal_discount_amount: any;
  Exptotal_qty_sold: any;
  Expreport_type: any;
  report_type: any;
  rep_type: string;
  country_dec: number=2;
  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  cur_lang: string;
  filter_branch_id: any;
  userType: any;
  PrdNAme: any;
  PrdAlias: any;
  ledAlias: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.listAllBranches();
    this.listGodowns();
    this.pageLoadingImg = true;
    this.rep_type = 'summary';
    this.report_type = 'summary';
    let searchval = new FormData();
    this.apiService.extTranInvBased(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.salesSum = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

    this.userType = this.coreService.getUserData('user_type');

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed','Common.detailed_with_mrp']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.report_type_filter = [
        { id: 'summary', name: res['Common.summary'] },
        { id: 'detailed', name: res['Common.detailed'] },
        { id: 'detailed_with_mrp', name: res['Common.detailed_with_mrp'] }
      ];
    
    });
  }

  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      console.log(res);
      this.godowns = res['data'];

    });

  }
  listAllBranches() {
    let searchval = new FormData();

    this.apiService.getAllBranchesforTransfer().subscribe((res) => {
      this.branch_all = res['data'];
    });

  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.extTranInvBased(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.salesSum = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
      this.report_type = res.data.rep_type;


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  //form search  for category
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];
    });
  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }
  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];


    });
  }
  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }
  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    this.apiService.extTranInvBased(Expformdata.value, 1).subscribe((res: any) => {
      this.ExprepDate = res.data.Date;
      this.ExpsalesSum = res['data']['data'];
      this.Expreport_type = res.data.rep_type;

      this.Exptotal_amount = res['data']['total_amount'];
      this.Exptotal_mrp = res['data']['total_mrp'];
      this.Exptotal_qty_sold = res['data']['total_qty_sold'];




      setTimeout(() => {
        this.exportexcel();
      }, 3000);


      Expformdata.value.export = '';
    });



  }

  // pdf download
  generatePdf(Expformdata: { value: any; }) {

    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    this.apiService.extTranInvBased(Expformdata.value, 1).subscribe((res: any) => {
      this.ExprepDate = res.data.Date;
      this.ExpsalesSum = res['data']['data'];
      this.Expreport_type = res.data.rep_type;

      this.Exptotal_amount = res['data']['total_amount'];
      this.Exptotal_mrp = res['data']['total_mrp'];
      this.Exptotal_qty_sold = res['data']['total_qty_sold'];

      const heads = [];

      if(this.report_type == 'detailed'){
        heads.splice(0,0, this.translate.instant('Common.date'))
        heads.splice(1,0, this.translate.instant('Vat.Transfer_Ref_No'))
        heads.splice(2,0, this.translate.instant('transaction.Transferd_To'))
        heads.splice(3,0, this.translate.instant('Table.item_total'))
        heads.splice(4,0, this.translate.instant('Table.item_name'))
        heads.splice(5,0, this.translate.instant('Table.qty'))
      } else if(this.report_type == 'summary'){
        heads.splice(0,0, this.translate.instant('Common.prd_name'))
        heads.splice(1,0, ' ')
        heads.splice(2,0, this.translate.instant('Branch.trasfer_id'))
        heads.splice(3,0, this.translate.instant('transaction.Transferd_To'))
        heads.splice(4,0, this.translate.instant('Common.date'))
        heads.splice(5,0, this.translate.instant('Table.qty'))
        heads.splice(6,0, this.translate.instant('Table.rate'))
        heads.splice(7,0, this.translate.instant('Table.amt'))
      } else if(this.report_type == 'detailed_with_mrp'){
        heads.splice(0,0, this.translate.instant('Common.date'))
        heads.splice(1,0, this.translate.instant('Vat.Transfer_Ref_No'))
        heads.splice(2,0, this.translate.instant('transaction.Transferd_To'))
        heads.splice(3,0, this.translate.instant('Table.item_name'))
        heads.splice(4,0, this.translate.instant('Table.qty'))
        heads.splice(5,0, this.translate.instant('Common.rate'))
        heads.splice(6,0, this.translate.instant('Table.item_total'))
        heads.splice(7,0, this.translate.instant('Table.mrp'))
        heads.splice(8,0, this.translate.instant('Table.total_mrp'))
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        if(this.report_type == 'detailed'){
         
          this.ExpsalesSum.forEach(item=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}
            if(this.translate.currentLang == "Arabic"){this.ledAlias =  item.ledger_alias}else{this.ledAlias =  item.ledger_name}
            data.push([
              this.datePipe.transform(item.extstktrsub_date, 'dd/MM/yyyy'),
              item.inv_no,
              this.ledAlias,
              (item.sale_amount).toFixed(this.country_dec),
              this.PrdNAme,
              item.extstktrsub_qty
            ])
          })

        } else if(this.report_type == 'summary'){

          this.ExpsalesSum.forEach(item=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}
            data.push([
              this.PrdNAme,
              '',
              '',
              '',
              '',
              '',
              ''
            ])

            item.items.forEach(item1=>{
              if(this.translate.currentLang == "Arabic"){this.ledAlias =  item1.ledger_alias}else{this.ledAlias =  item1.ledger_name}

              data.push([
                '',
                item1.prd_barcode,
                item1.inv_no,
                this.ledAlias,
                this.datePipe.transform(item1.extstktrsub_date, 'dd/MM/yyyy'),
                this.currencyPipe.transform(item1.extstktrsub_qty, '', '', '1.1-1'),
                (item1.extstktrsub_rate).toFixed(this.country_dec),
                (item1.sale_amount).toFixed(this.country_dec)
              ])
            })

        })
        } else if(this.report_type == 'detailed_with_mrp'){
          this.ExpsalesSum.forEach(item=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}
            if(this.translate.currentLang == "Arabic"){this.ledAlias =  item.ledger_alias}else{this.ledAlias =  item.ledger_name}
            data.push([
              this.datePipe.transform(item.extstktrsub_date, 'dd/MM/yyyy'),
              item.inv_no,
              this.ledAlias,
              this.PrdNAme,
              this.currencyPipe.transform(item.extstktrsub_qty, '', '', '1.1-1'),
              (item.extstktrsub_rate).toFixed(this.country_dec),
              (item.sale_amount).toFixed(this.country_dec),
              (item.sur_unit_rate).toFixed(this.country_dec),
              (item.mrp_amount).toFixed(this.country_dec)
            ])
          })
        }
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('transaction.ext_transfer_inv_based')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_trans_qty')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.total_qty_sold,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + (this.total_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
    
       
        doc.save(`External Transfer Invoice Based.pdf`);

        this.exportLoader = false;

      Expformdata.value.export = '';
    });
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

}
