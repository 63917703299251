import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-cash-book',
  templateUrl: './rep-cash-book.component.html',
  styleUrls: ['./rep-cash-book.component.css']
})


export class RepCashBookComponent implements OnInit {

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  
  accType = [
    { id: '3', name: 'Cash' },
    { id: '4', name: 'Bank' }
  ];

  // report_type = [
  //   { id: 'summary', name: 'Summary' },
  //   { id: 'detailed', name: 'Detailed' },
  //   { id: 'grouped_detail', name: 'Grouped Ledger' }

  // ];


  ledger_sel_id: any;
  reportData: any;
  selecTed: any[];
  pageLoadingImg: boolean;
  searchleadger: any;
  period_type: string;
  acc_type: string;
  rep_type: string;
  show_op_bal :any;
  op_bal :any;
  vch_type: any;
  country_dec: number=2;
  InvoiceDueReceipt: any;
  netSale: any;
  reciept: any;
  purchReturn: any;
  debitNote: any;
  purchase: any;
  invoiceDuePayment: any;
  payment: any;
  salesReturn: any;
  creditNote: any;
  totalIn: any;
  totalOut: any;
  totalCredit: any;
  totalDebit: any;
  totalDiff: any;
  openingBalance: any;
  creditPurchase: any;
  creditSales: any;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  repDate: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.period_type = 't';
    this.acc_type = '3'
    this.rep_type = 'summary';
    this.getDefaultLedgers();
    
    let searchval = new FormData();
    searchval.append("ledger_grp", '3');
    searchval.append("ledger_name", '');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
      
    });

    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed','ACCOUNTS.grpd_ledger','Common.cash','HOME.Bank']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      // this.report_type = [
      //   { id: 'summary', name: res['Common.summary'] },
      //   { id: 'detailed', name: res['Common.detailed'] },
      //   { id: 'grouped_detail', name: res['ACCOUNTS.grpd_ledger'] }
    
      // ];
      this.accType = [
        { id: '3', name: res['Common.cash'] },
        { id: '4', name: res['HOME.Bank'] }
      ];
    });

  }

  //form search  ledger
  searchLedger(search: string, grp = '3') {
    // $("#report-wrapper").hide();

    let searchval = new FormData();
    searchval.append("ledger_grp", grp);
    searchval.append("ledger_name", search);
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
      this.selecTed = [];
    });

  }
  // searchVoucherType(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('vchtype_name', search);
  //   this.apiService.searchVoucherType(searchval).subscribe((res) => {
  //     this.vch_type = res.data;
  //   });
  // }

  changeAccType() {
    this.searchleadger = [];
    this.ledger_sel_id = {};

    let searchval = new FormData();
    searchval.append("ledger_grp", this.acc_type);
    searchval.append("ledger_name", '');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
    });


  }

  selectLedger(ledg_id, form) {
    if (this.ledger_sel_id > 0 || this.ledger_sel_id == 'All') {
      if (form && form.value.period_type === 'c') {
        form.value.date1 = (form.value.date1) ? this.apiService.formatDate(form.value.date1) : form.value.date1;
        form.value.date2 = (form.value.date2) ? this.apiService.formatDate(form.value.date2) : form.value.date2;

      }
      this.selecTed = [];

      // this.is_notes = true;
      // this.is_branch_ref_no = true;
      // this.is_ref_no = true;
      // this.colSpan = 7;

      this.pageLoadingImg = true;
      form.value.ledger_sel_id = this.ledger_sel_id;
      form.value.acc_type = this.acc_type;


      this.apiService.getCashBookRep(form.value).subscribe((res) => {

        console.log("data");
        console.log(res['data']['total']['in']);
        this.pageLoadingImg = false;
        this.selecTed = res['data'];
        this.repDate = res['data']['Date'];
        
        this.creditPurchase =res['data']['creditPurchase'][0];
        this.creditSales =res['data']['creditSales'][0];

        this.netSale =res['data']['netSale'][0];
        this.reciept =res['data']['reciept'][0];
        this.InvoiceDueReceipt =res['data']['InvoiceDueReceipt'][0];
        this.purchReturn =res['data']['purchReturn'][0];
        this.debitNote =res['data']['debitNote'][0];
        this.purchase =res['data']['purchase'][0];
        this.invoiceDuePayment =res['data']['invoiceDuePayment'][0];
        this.payment =res['data']['payment'][0];
        this.salesReturn =res['data']['salesReturn'][0];
        this.creditNote =res['data']['creditNote'][0];
        this.totalIn =res['data']['total']['in'];
        this.totalOut =res['data']['total']['out'];
        this.totalCredit =res['data']['total']['creditData'];
        this.totalDebit =res['data']['total']['debitData'];
        this.totalDiff =res['data']['total']['totalDiff'];
        this.openingBalance =res['data']['op_bal']['op_balance'];




        this.op_bal = res['data'].op_bal;


      });

    }



  }

  getDefaultLedgers() {

    let searchval = new FormData();
    searchval.append("tax_sub_cat", '9');
    this.apiService.getDefaultLedgers(searchval).subscribe((res) => {
      this.searchleadger = res['data'];
      if(this.translate.currentLang == 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_alias': 'الكل' });
      }
      if(this.translate.currentLang != 'Arabic'){
        this.searchleadger.push({ "ledger_id": 'All', 'ledger_name': 'All' });
      }
      this.ledger_sel_id = Number(res['ledger_id']);
      if (this.ledger_sel_id) {
        var form = {
          value:
            { period_type: '' }
        };
        form.value.period_type = 't'
        this.selectLedger(this.ledger_sel_id, form);
      }
    });

  }

  // pdf download
  generatePdf() {
  
    const heads1 = [
      this.translate.instant('HOME.cash_in_drawer'),
      (this.totalDiff).toFixed(this.country_dec),
      this.translate.instant('Common.opening_bal'),
      (this.openingBalance).toFixed(this.country_dec),
      this.translate.instant('HOME.net_cash_in_drawer'),
      (this.totalDiff + this.openingBalance).toFixed(this.country_dec)
    ]

    const heads = [
      this.translate.instant('HOME.total_sale'),
      this.creditSales.salesTotal + this.netSale.difference,
      ' ',
      this.translate.instant('HOME.total_purchase'),
      this.creditPurchase.purchaseTotal + this.purchase.difference
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];
      const data1= [];

      data.push([
        this.translate.instant('HOME.credit_sale'),
        (this.creditSales.salesTotal).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.credit_purchase'),
        (this.creditPurchase.purchaseTotal).toFixed(this.country_dec)
      ])

      data.push([
        this.translate.instant('HOME.cash_sale'),
        (this.netSale.difference).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.cash_purchase'),
        (this.purchase.difference).toFixed(this.country_dec)
      ])

      data.push([
        this.translate.instant('HOME.invoice_due_receipt'),
        (this.InvoiceDueReceipt.difference).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.invoice_due_payment'),
        (this.invoiceDuePayment.difference).toFixed(this.country_dec)
      ])

      data.push([
        this.translate.instant('HOME.RECEIPT'),
        (this.reciept.difference).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.PAYMENT'),
        (this.payment.difference).toFixed(this.country_dec)
      ])

      data.push([
        this.translate.instant('HOME.cash_purchase_return'),
        (this.purchReturn.difference).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.Cash_Sales_Return'),
        (this.salesReturn.difference).toFixed(this.country_dec)
      ])

      data.push([
        this.translate.instant('HOME.Debit_note_Receipt'),
        (this.debitNote.difference).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.credit_note_payment'),
        (this.creditNote.difference).toFixed(this.country_dec)
      ])

      data.push([
        this.translate.instant('HOME.total_cash_received'),
        (this.totalCredit).toFixed(this.country_dec),
        ' ',
        this.translate.instant('HOME.total_cash_payment'),
        (this.totalDebit).toFixed(this.country_dec)
      ])
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.daily_operation')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('ACCOUNTS.Ledger_Name')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.op_bal.ledger_name,  valueXPos, headerHeight + 20);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 25, lblXPos, heads, data, null);
      doc = this.excelService.addTableToPdf(doc, headerHeight + 85, lblXPos, heads1, data1, null);
     
      doc.save(`Daily Operation.pdf`);


    
  }

}
