import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import * as jspdf from 'jspdf';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx'; 
import pdfMake from 'pdfmake/build/pdfmake';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
//import pdfFonts from 'pdfmake/build/vfs_fonts';


import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
 pdfMake.vfs = pdfFonts.pdfMake.vfs; 
 pdfMake.fonts = { 'Roboto': { normal: 'Roboto-Regular.ttf', bold: 'Roboto-Medium.ttf', italics: 'Roboto-Italic.ttf', bolditalics: 'Roboto-Italic.ttf' },
 'THSarabunNew': { normal: 'THSarabunNew.ttf' }, 'code128': { normal: 'code128.ttf' } }


pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-sales-cash-credit',
  templateUrl: './sales-cash-credit.component.html',
  styleUrls: ['./sales-cash-credit.component.css']
})
export class SalesCashCreditComponent implements OnInit {

  @ViewChild('exportall',{static: true}) reportContent: ElementRef;
  fileName= 'sales.xlsx';
 
  pageLoadingImg: boolean;
  value1:any;
  value2:any;
  inv_val1:any;
  inv_val2:any;
  sales_val1:any;
  sales_val2:any;
  disc_val1:any;
  agents:any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: 0, name: 'Cash' },
    { id: 1, name: 'Credit' },
    // { id: 2, name: 'Bank' }

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];

  filter_custtyp = [
    { id: '1', name: 'Registered' },
    { id: '0', name: 'Non Registered' },

  ];
  

  payType = ['Cash','Credit','Bank'];

  godowns:any;
  salesSum = [];
  totalCat: any;
  totalProducts: any;
  sup_id:any;
  cust_id:any;
  cust_type:any;
  pay_type:any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  sales_disc_filter:any;
  sales_filter:any;
  inv_filter:any;
  period_type:any;
  gd_id:any;
  agent_id:any;
  company:any;
  supplier:any;
  users:any;
  customer:any;
  added_by:any;
  sales_flags:any;
  summary:any;
  repDate:any;
  totalData:number;
  date1:any;
  date2:any;
  time1:any;
  time2:any;
  vat_reg_no:any;
  ModelData: boolean;
  show: any;
  userType: any;
  filter_branch_id: any;
  branch_all: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  Expshow: any;
  ExpsalesSum: any;
  Expsummary: any;
  vans: any;
  exportLoader: boolean;
  pdf: any;
  active_pay_type = 0;
  ttl_vat = 0;
  ttl_disc = 0;
  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];
  cmp_type: any;
  Alldet: any;
  ExpAlldet: any;
  cmpExist: boolean;
  country_dec: number=2;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }


 

  ngOnInit() {
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.period_type = 't';
    this.pay_type = 0;
    this.active_pay_type = this.pay_type;

    this.exportLoader = false;
    this.changeCmpType(1);
    this.cmp_type = '1';
    this.cmpExist = true;

    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = [];
    this.totalData = 0;
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("period_type", this.period_type);
    searchval.append("cmp_type",this.cmp_type);

    this.apiService.salesAuditor(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      this.show = res['data']['data'];

      this.salesSum = res['data']['data'];
     
      this.summary = res['data']['info'].branch;
      this.repDate = res['data']['info'].Date;

       // console.log(this.summary);
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      // this.pdf = res['data']['pdf'];
      this.totalData = res['data']['total'];
      this.ttl_vat = res['data']['tax_total'];
      this.ttl_disc = res['data']['sales_discount'];
      //  console.log(this.pdf);
      this.Alldet = res['data'].info.Summary;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
this.searchUser('');
this.ModelData = false;

this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
  
   this.getGodownList();
  
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.filter_paytype = [
        { id: 0, name:  res['Common.cash'] },
        { id: 1, name: res['HOME.credit'] },
        // { id: 2, name: 'Bank' }
    
      ];
   
    });

  }
  changeCmpType(cmptype){
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];

    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      this.checkBranch();

      // if (this.userType == 'ADMIN') {
      //   this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }
  checkBranch(){
    if(this.filter_branch_id.length > 0)
    {
      this.cmpExist = true;
    }else{
      this.cmpExist = false;
    }
  }

  generatePdf() {
                 
             
  console.log(this.summary.hasOwnProperty('van'));
    var filename = 'Sales Summary';
  

// Declaring your layout
var myTableLayouts = {
  exampleLayout: {
    fontSize: 11,
     bold: false,
  }
  };


    var docDefinition = {
      content: [
       {
          text: this.branch_display_name,
          bold: true,
          fontSize: 13,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: filename,
          bold: true,
          fontSize: 16,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [
              {
                text:(this.repDate.date1&&this.repDate.date2)? 'Duration : '+this.repDate.date1+' - '+this.repDate.date2:'',
                fontSize: 11,
                margin: [0, 0, 0, 12]
              },
            {
              text: 'Total Invoices : '+this.summary.tot_result,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },
            {
              text: 'Total Days : '+this.summary.tot_days,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },
            {
              text: 'Total Invoice Amount : '+this.summary.tot_inv_amt,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },
            {
              text: 'Total VAT Amount: '+this.summary.tot_vat_amt,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },
            {
              text: 'Total Amount Excl. VAT : '+this.summary.tot_excl_vat,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },
            
            {
              text: 'Total Discount Amount : '+this.summary.tot_disc_amt,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },
            
            {
              text: 'Total Promotional Discount  : '+this.summary.tot_disc_promo,
              fontSize: 11,
              margin: [0, 0, 0, 12]
            },

            {
              text: 'Total Item  Discount : '+this.summary.tot_item_disc,
              fontSize: 11,
              margin: [0, 0, 0, 20]
            },
            {
              text: 'Godown : '+ (this.summary.hasOwnProperty('godown') ? this.summary.godown.gd_name : 'All' ),
              fontSize: 11,
              margin: [0, 0, 0, 20]
            },
            
            {
              text: 'Van : '+ (this.summary.hasOwnProperty('van') ? this.summary.van.van_name : 'All'),
              fontSize: 11,
              margin: [0, 0, 0, 20]
            },
  
            ],
            [
              // Document definition for Profile pic
            ]
          ],
          columnStyles: { comment: { columnWidth: 'auto' } },
          styles: {
            font: 'THSarabuNew',
            name: {
              fontSize: 11,
            },
            tableExample: {
              fontSize: 6,
            },
          },
        },
      
        {
          style: 'tableExample',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
           
            fontSize: 11,
            headerRows: 1,
            styles: {
              cellPadding: 0.5,
              fontSize: 7
          },
          //startY: 30, /* if start position is fixed from top */
          tableLineColor: [0, 0, 0], //choose RGB
          tableLineWidth: 0.5, //table border width
           //widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto','auto','auto','auto'],
            body: this.pdf,
            bodyStyles: {
              margin: 30,
              fontSize: 5,
              lineWidth: 0.1,
              lineColor: [0, 0, 0]
          }, 
          theme: 'grid',
          columnStyles: {
            text: {
                cellWidth: 'auto',
                fontSize: 6,
            }
        }
          }
        }
      ]
    };

     pdfMake.createPdf(docDefinition).download(filename+".pdf");



  }
  ngAfterContentInit(){
    
    this.searchVans('');
    this.searchComp('');
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }


  ngOnDestroy(){
    $('.close').click();
 
  }

  pageNext(formdata: { value: any; },newform: { value: any; }, pageno: any) {
    this.salesSum = [];
    this.active_pay_type = this.pay_type;
    this.pageLoadingImg = true;
    formdata.value.datewise =  newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.salesAuditor(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
        this.show = res['data']['data'];
      
      this.salesSum = res['data']['data'];
      // this.pdf = res['data']['pdf'];
      this.summary = res['data']['info'].branch;

      this.repDate = res['data']['info'].Date;
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      this.ttl_vat = res['data']['tax_total'];
      this.ttl_disc = res['data']['sales_discount'];
      // console.log(this.totalData);
      this.Alldet = res['data'].info.Summary;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }


  
  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }


  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  searchUser(search: string)
  {
   let searchval = new FormData();      
      searchval.append("usr_name", search);    
      this.apiService.getUserByName(searchval).subscribe((res) => {
        this.users = res['data'];     
      });
  }

  searchAgent(search: string)
  {
   let searchval = new FormData();      
      searchval.append("usr_name", search);    
      this.apiService.getUserByName(searchval).subscribe((res) => {
        this.agents = res['data'];   
        // console.log(this.users);               
      });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'Shop' });
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

    });

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  // getGodownbybranch(bid)
  // {
  //   console.log(bid);
  //      let searchval = new FormData();      
  //   searchval.append("bid", bid);
        
  //        this.apiService.listGodownbyBranch(searchval).subscribe((res) => {
  //         this.godowns = res['data'];  
  //     this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

  //        });
  // }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  export() {
    // const doc = new jspdf('p', 'pt', 'A4');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const content = this.reportContent.nativeElement;

    // doc.fromHTML(content.innerHTML,5, 5, {
    //   'width': 1000,
    //   'elementHandlers': specialElementHandlers
    // });
    // doc.setFontSize(5);
    

    // doc.save('asdfghj' + '.pdf');
  }




  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       var FileName = ((this.active_pay_type == 0) ? 'Cash ' : 'Credit ') + this.fileName;
       XLSX.writeFile(wb, FileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.datewise = true;
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
      this.apiService.salesAuditor(formdata.value,1).subscribe((res: Sales) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
          // this.Expshow = res['data'];
        
        this.ExpsalesSum = res['data']['data'];
       
        this.Expsummary = res['data']['info'].branch;
        this.repDate = res['data']['info'].Date;

        this.totalData = res['data']['total'];
      this.ttl_vat = res['data']['tax_total'];
      this.ttl_disc = res['data']['sales_discount'];
      this.ExpAlldet = res['data'].info.Summary;
      
        // console.log(this.totalData);
  
      
        // }
      
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
   

    });



    }
    
    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });
      });
  
    }

  // pdf download
  generatePdf1(formdata: { value: any; }) {

    this.exportLoader = true;
     
    // debugger;
    formdata.value.datewise = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      // formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      // formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.salesAuditor(formdata.value,1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
        // this.Expshow = res['data'];
      
      this.ExpsalesSum = res['data']['data'];
     
      this.Expsummary = res['data']['info'].branch;
      this.repDate = res['data']['info'].Date;

      this.totalData = res['data']['total'];
    this.ttl_vat = res['data']['tax_total'];
    this.ttl_disc = res['data']['sales_discount'];
    this.ExpAlldet = res['data'].info.Summary;
    
      // console.log(this.totalData);
  
  const heads = [
    this.translate.instant('Table.date'),
    this.translate.instant('HOME.Invoice_no'),
    this.translate.instant('Vat.sales') + ' ' + this.translate.instant('Table.amnt'),
    this.translate.instant('Purchase.vat'),
    this.translate.instant('Sales.amount_with_vat'),
    this.translate.instant('Table.description')
  ];

  if(this.active_pay_type){
    heads.splice(5,0, this.translate.instant('Common.customer_name'))
    heads.splice(6,0, this.translate.instant('HOME.CUSTOMER') + ' ' + this.translate.instant('Purchase.VAT_No'))
  }

    const lblXPos = 10;
    const headerHeight = 15;
    const valueXPos = 55;
    const data = [];

    this.ExpsalesSum.forEach(item=>{
      const arr=[
        this.datePipe.transform(item.sales_date, 'dd/MM/yyyy'),
        item.sales_inv_no,
        (item.sales_total + item.sales_discount - item.sales_tax).toFixed(this.country_dec),
        (item.sales_tax).toFixed(this.country_dec),
        (item.sales_total + item.sales_discount).toFixed(this.country_dec),
        item.sales_notes
      ]

      if(this.active_pay_type){
        if(this.translate.currentLang == "English"){
          arr.splice(5,0, item.name)
        }else{  arr.splice(5,0, item.alias)}
       

        arr.splice(6,0, item.vat_no)
      }

      data.push(arr)
    })

    let doc = this.excelService.getPdfObj();

    var addressLines = this.branch_address.split('\r\n');
    var addressText = addressLines.join(', ');

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    doc.text(addressText, 71, headerHeight + 2);

    if(this.repDate){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);

    if(this.active_pay_type == 0){
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.cash') + this.translate.instant('Vat.sales')} `,  valueXPos, headerHeight + 15);
    } else if(this.active_pay_type == 1){
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('HOME.credit') + this.translate.instant('Vat.sales')} `,  valueXPos, headerHeight + 15);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Common.CR_No')}`, lblXPos, headerHeight + 20);
    doc.setFontSize(10);
    doc.text(':  ' + this.summary.branch_reg_no,  valueXPos, headerHeight + 20);

    if(this.salesSum.length != 0){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Sales_Amt')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + (this.totalData + this.ttl_disc - this.ttl_vat).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + (this.ttl_vat).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.total') + ' ' + this.translate.instant('Sales.amount_with_vat')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + (this.totalData + this.ttl_disc).toFixed(this.country_dec),  valueXPos, headerHeight + 35);
    }

    if(this.userType == 'ADMIN' || this.userType == 'AUDITOR'){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Auditor.Company_type')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.Alldet.cmp_type,  valueXPos, headerHeight + 40);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Branch.Activity')}`, lblXPos, headerHeight + 45);
    doc.setFontSize(10);
    doc.text(':  ' + ' ',  valueXPos, headerHeight + 45);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Branch.Mobile No')}`, lblXPos, headerHeight + 50);
    doc.setFontSize(10);
    doc.text(':  ' + this.summary.branch_mob,  valueXPos, headerHeight + 50);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Branch.Charge Person')}`, lblXPos, headerHeight + 55);
    doc.setFontSize(10);
    doc.text(':  ' + ' ',  valueXPos, headerHeight + 55);
    
    doc = this.excelService.addTableToPdf(doc, headerHeight + 60, lblXPos, heads, data, null);

   
    doc.save(`sales.pdf`);

    this.exportLoader = false;

  });

    
    
  }


}
