import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-fuel-daily-transactions',
  templateUrl: './fuel-daily-transactions.component.html',
  styleUrls: ['./fuel-daily-transactions.component.css']
})
export class FuelDailyTransactionsComponent implements OnInit {
  branch_all: any;
  fileName= 'fuel_daily_transactions.xlsx';
  pageLoadingImg: boolean;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  feulDailySumm: any;
  country_dec: number=2;
  userType: any;
  exportLoader: boolean;
  repDate: any;
  period_type: string;
  users: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService) { }

  ngOnInit() {
    this.getAllBranch()
    this.userType = this.coreService.getUserData('user_type');
    this.country_dec=this.coreService.setDecimalLength();
    this.period_type = 't';
    let searchval=new FormData();
    searchval.append('period_type','t');
    this.pageLoadingImg = true;
    this.apiService.fuelDailyTransactions(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false;    
      this.feulDailySumm = res['data']['data'];
      this.repDate=res['data']['Date'];
      // this.pageFrom = res['data']['prdt_list']['from'];
      // this.curpage = res['data']['prdt_list']['current_page'];
      // this.lastpage = res['data']['prdt_list']['last_page'];
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
      
  
    });
    this.searchUser('');
  }

  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      // this.filter_branch_id = res['selected'];
  
      // if (this.userType == 'ADMIN') {
      //   this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
     
    }
    this.apiService.fuelDailyTransactions(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      // this.repDate = res.data.Date;
      
      this.feulDailySumm = res['data']['data'];
      this.repDate=res['data']['Date'];
      // this.pageFrom = res['data']['prdt_list']['from'];
      // this.curpage = res['data']['prdt_list']['current_page'];
      // this.lastpage = res['data']['prdt_list']['last_page'];
      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
      
      
      
  
    })
  
  
  
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    setTimeout(() => {
      this.exportLoader = false;
  }, 1000);
  }
  
  exportEXCL() {
  
    this.exportLoader = true;
    this.exportexcel(); 
  }

}
