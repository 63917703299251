import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';


//routes
import { GodownRoutingModule} from './godown-routing.module';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';


//translation
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxPrintModule} from 'ngx-print';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
 }

 import {APP_BASE_HREF} from '@angular/common'; 
 import { AppComponent } from '../../app.component';


//component
import { GodownComponent } from './godown/godown.component';
import { GodownManageComponent } from './godown/godown-manage/godown-manage.component';
import { GodownTransferComponent } from './godown-transfer/godown-transfer.component';
import { TestComponent } from './test/test.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [GodownComponent, GodownManageComponent, GodownTransferComponent, TestComponent],
  imports: [ 
    CommonModule, 
    GodownRoutingModule,
    ErpMaterialModule, 
    NgSelectModule,  
    FormsModule,
    ReactiveFormsModule, 
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    BrowserModule,
    SharedModule,
    ComfunctModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }) 
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }] ,
  bootstrap: [AppComponent]
})
export class GodownModule {}
