import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';
// routing
import { ProdctionRoutingModule} from './production-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxPrintModule} from 'ngx-print';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { NumonlyDirective } from '../../../app/directive/numonly.directive';
// validations



//date picker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// component
import { ProductionDashComponent } from './production-dash/production-dash.component';
import { ProductionFormulaComponent } from './production-dash/production-formula/production-formula.component';
import { ProductionComponent } from './production-dash/production/production.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ProductionVoidComponent } from './production-dash/production-void/production-void.component';
import { SharedModule } from 'src/app/shared/shared.module';




@NgModule({
  declarations: [ProductionDashComponent, ProductionFormulaComponent, ProductionComponent, ProductionVoidComponent],
  imports: [
    CommonModule, 
    ProdctionRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgbModule,
    ComfunctModule,
    SharedModule,
    DateInputsModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }, NumonlyDirective,DatePipe], 
  bootstrap: [AppComponent]
})
export class ProductionModule { }
