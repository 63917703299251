import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ApiService } from "../../../service/api.service";
import { Product } from "../../../model/product.model";
import { Unit } from "../../../model/unit.model";
import { Feature } from "../../../model/feature.model";
import { Catagory } from "src/app/model/catagory.model";
import { Subcatagory } from "../../../model/subcatagory.model";
import { Manufacture } from "../../../model/manufacture.model";
import { CoreService } from "../../../service/core.service";
import { Branch } from "../../../model/branch.model";
import { formatDate } from "@angular/common";
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
// modal
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-quick-product",
  templateUrl: "./quick-product.component.html",
  styleUrls: ["./quick-product.component.css"],
})
export class QuickProductComponent implements OnInit {
  @Input()quickCreate;
  @ViewChild("closeModal", { static: false }) closeModal: ElementRef;
  @ViewChild("btnReset", { static: false }) btnReset: ElementRef<HTMLElement>;
  prd_sub_cat_id: any;
  thenBlockBase: any;
  cat_name: any;
  cat_remarks: any;
  cat_pos: any;
  cat_id: any;
  subcat_name: any;
  manftr_comp_name: any;
  manftr_comp_code: any;
  manftr_comp_address: any;
  manftr_comp_phone: any;
  manftr_comp_mobile: any;
  manftr_comp_fax: any;
  manftr_comp_email: any;
  manftr_comp_contact_person: any;
  manftr_comp_website: any;
  manftr_comp_notes: any;
  id: any;
  baseunit: any;
  unit_name: any;
  unit_base_qty: any;
  unit_code: any;
  unit_display: any;
  unit_remarks: any;
  prd_alias: any;
  prd_code: any;
  catid: any;
  prd_base_unit_id: any;
  prd_base_unit_ean: any;
  prd_exp_dur: any;
  prd_tax_code: any;
  prd_tax: any;
  prd_desc: any;
  prd_minstock_alert: any;
  prd_min_stock: any;
  prd_maxstock_alert: any;
  prd_max_stock: any;
  prd_tax_cat_id: any;
  derunitids: any;
  featids: any;
  setderived = true;
  setfeat = true;
  setstock = true;
  purchase_rate: any;
  feat_value: any;
  baseunitids: any;
  unit_ean: any;

  displayedColumns: string[] = ["name", "value"];
  dataSource = [];
  //  unit table
  prd_unit_ids = [];
  new_prd_unit_ids:any
  unit_rates = [];
  prd_unit_ids_stock = [];
  p_types_id: number;
  prd_stock_stat = 1;
  bunitforder: string[];
  unit_all: string[];
  subcat_all: string[];

  selectedData: Product = {
    prd_id: null,
    prd_name: null,
    error: null,
    message: null,
    prd_sub_cat_id: null,
    prd_cat_id: null,
    prd_base_unit_id: null,
    prd_minstock_alert: null,
    prd_maxstock_alert: null,
    prd_unit_ids: null,
    prd_default_unit_id: null,
    features: null,
    unit: null,
    basic: null,
    prd_loyalty_rate: null,
    unit_rates: null,
    data: null,
    prd_supplier: null,
  };
  selectedUnit: Unit = {
    unit_id: null,
    error: null,
    unit_name: null,
    unit_base_id: null,
    message: null,
    unit_type: null,
    unit_base_qty: null,
    derived_units_count: null,
    sur_unit_id: null,
  };
  selectedFeature: Feature = {
    feat_id: null,
    error: null,
    feat_name: null,
    feat_description: null,
    message: null,
  };

  values: string[];
  derunits: string[];
  features: string[];
  catagories: any[];
  subcatagories: string[];
  unit = { unit_base_id: null };
  datas: string[];
  mfs: string[];
  prods_all: string[];
  buid: number;
  baseunits: [];
  branches: [];
  feats: [];
  changeBox: boolean;
  selDisable: boolean;
  addStock = 0;
  prodsummbasic = [];
  prodid: number;
  cmp_stock_id: number;
  resDup: boolean;
  mrp: number;
  unitsforstock = [];
  branchselids = [];
  stockaddprate: number;
  private modalRef: NgbModalRef;
  closeResult: string;
  result = "";
  erR: any;
  search: string[];
  prd_name: any;
  prd_short_name: any;

  prd_stock_statusUnchecked = false;
  prd_stock_statusChecked = true;
  prd_stock_status = true;
  p_types = [];
  p_types_id_ckeck = true;
  pageLoadingImg: boolean;
  userType: any;
  taxCategories = [];
  prd_img: any;
  prdImgUrl: any;
  prd_supplier: any;
  base_url: any;
  imageError: string;
  excelfileList: FileList;
  excelErr = "";
  isSubmitInProg = false;
  impRes: any;
  loading: boolean;
  prd_locations: any;
  der_base_unit:any;
  der_unit_name: any;
  der_unit_base_qty: any;
  der_unit_code: any;
  der_unit_display: any;
  der_unit_remarks: any;
  result2 = "";
  der_unit_type: any = 0;
  constructor(
    private apiService: ApiService,
    private coreService: CoreService,
    private modalService: NgbModal,
    private translate1: TranslateService
  ) { }

  ngOnInit() {
    this.translate1.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
    this.userType = this.coreService.getUserData("user_type");
    $("#dfeat_valid_qp").hide();
    if (this.p_types_id_ckeck == true) {
      this.p_types_id_ckeck = false;
    } else {
      this.p_types_id_ckeck = true;
    }
    $("#stock_status_qp").show();
    this.prd_stock_statusUnchecked = false;
    this.prd_stock_statusChecked = true;
    this.prd_stock_status = true;
    this.p_types = [
      { id: 1, name: "Goods", selected: 1 },
      { id: 0, name: "Service", selected: 0 },
    ];

    this.p_types_id = 1;
    this.prd_stock_stat = 1;
    var id = 0;
    this.apiService.readBase(id).subscribe((units: Unit[]) => {
      this.values = units["data"];
    });
    this.apiService.readFeat().subscribe((features: Feature[]) => {
      this.feats = features["data"];
    });
    this.readcatSub();
    // this.readmanfs();
    this.readBaseUnits();
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      this.branches = branch["data"];
    });

  // listing all current locations
  this.apiService.getAllPrdLoactions(1).subscribe((res) => {
    this.prd_locations = res.data
  });
  $('#addbatchbtn').hide();
  $('#stkbatchsel').hide();
    this.coreService.getToken();
    $("#select_qp").prop("disabled", false);
    $("#bunit_ean_qp").prop("disabled", false);

    this.base_url = this.apiService.PHP_API_SERVER;
  }

  langChange(){
    this.translate1.get(['Product.Goods', 'Product.Services']).subscribe((res: string) => {    
     
      this.p_types = [
        { id: 1, name: res['Product.Goods'], selected: 1 },
        { id: 0, name: res['Product.Services'], selected: 0 },
      ];
      
    });

  }
  readBaseUnits() {
    this.apiService.readBaseUnits().subscribe((baseunt: Unit[]) => {
      this.baseunits = baseunt["data"];
    });
  }
  readcatSub() {
    this.apiService.readCat().subscribe((catagories: Catagory[]) => {
      this.catagories = catagories["cat"];
      this.subcatagories = catagories["subcat"];
    });
  }
  openModal(content: any, size) {
    $('.qp_fproduct').trigger("reset");
    $("#idname_qp").hide();
    
    this.modalRef = this.modalService.open(content, {
      size: size,
      backdrop: "static",
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    $('#addbatchbtn').hide();
    $('#stkbatchsel').hide();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  translate() {
    if (this.prd_name) {
      let searchval = new FormData();
      searchval.append("prd_name", this.prd_name);
      this.apiService.translateProductName(searchval).subscribe((res) => {
        this.prd_alias = res["data"];
      });
    }
  }

  productBaseSubmit(formgroup) {
    this.pageLoadingImg = true;
    let searchval = new FormData(formgroup);
    if (this.p_types_id == 0) searchval.set("prd_stock_status", "false");
    else searchval.set("prd_stock_status", "" + this.prd_stock_status + "");

    searchval.append("prd_cat_id", this.catid);
    searchval.append("prd_sub_cat_id", this.prd_sub_cat_id);
    searchval.append("prd_base_unit_id", this.prd_base_unit_id);
    searchval.append("prd_tax_cat_id", this.prd_tax_cat_id);
    searchval.append("prd_supplier", this.prd_supplier);
    searchval.append("prd_type", this.p_types_id.toString());
    this.new_prd_unit_ids = this.prd_unit_ids;
    searchval.append('prd_units_new', JSON.stringify(this.prd_unit_ids));
    this.prd_unit_ids_stock.push(this.prd_unit_ids);

    this.apiService
      .createProductBase(searchval)
      .subscribe((product: Product) => {
        this.pageLoadingImg = false;
        if (product && product.error != null) {
          this.result = product.error;
        } else {
          $("#basic_qp").hide();
          $("#stock").hide();
          $("#feature").hide();
          $("#unit_qp").show();
          this.prodsummbasic = product["data"];
          this.prodid = product["data"][0].prd_id;
          this.cmp_stock_id = product["data"][0].cmp_stock_id;
          this.unitsforstock = product["data"][0].units;
          // this.unitsforstock.push({
          //   unit_type: 1,
          //   unit_id: product["data"][0].units,
          //   unit_name: product["data"][0].unit.unit_name,
          //   unit_base_qty: 1,
          // });
        }
      });
  }
  selectProd(name: string) {
    this.search = [];
    let searchval = new FormData();
    searchval.append("prod_name", name);
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.search = res['data'];
    });
    if (this.search)
      $('.prev-list').show();

  }
  removeName() {
    this.prods_all = [];
    this.unit_all = [];
    $('.prev-list').hide();
    if (this.prd_name != "")
      this.prd_short_name = this.prd_name;
  }
  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.prd_tax = selTax[0].taxcat_tax_per;
  }

  selectSubCats(catid: number) {
    this.prd_sub_cat_id = null;
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
    });
    const selRow = this.catagories.filter((categ) => categ.cat_id === catid);
    this.prd_tax_cat_id = selRow[0].cat_tax_cat_id;
    this.updateTaxPerc(selRow[0].cat_tax_cat_id);
  }
  selectDerUnits(baseunitids: number) {
    if (baseunitids) {
      this.der_base_unit = baseunitids;
      // sel der unit    
      this.apiService.readDerbyId(baseunitids).subscribe((res: Unit[]) => {
        this.derunits = res['data'];

      });
    }
  }
  searchCat(search: string, flag) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    if (flag == 'y')
      $('.prev-list-cat').show();
  }
  selectPtype(prdtypes: number) {

    if (prdtypes == 1) {
      $('#stock_status_qp').show();
    } else {
      $('#stock_status_qp').hide();
    }
  }
  addUnitValues(derunitids: Unit, baseunitids: Unit, addunitlist) {
    // this.apiService.createUnitList(addunitlist.value).subscribe((prod: Product) => {
    //   this.pageLoadingImg = false;
    //   if (prod.error != null) {
    //     this.result = prod.error;
    //   } else {
        this.result = '';
        // add base unit in table
        if (this.buid && derunitids.unit_id) {
          $(".bunit_valid").text('');
          var bean = $("#bunit_ean").val();
          if (bean) {
            bean = bean.toString();
            $("#bunit_ean").val('');
          }
          else {
            bean = "";
          }
          this.prd_unit_ids.push({ "unit_type": 1, "unit_id": baseunitids.unit_id, "unit_name": baseunitids.unit_name, "ean_barcode": bean, "unit_rate": 0 });
          //  Remove duplicates     
          this.prd_unit_ids = this.coreService.removeDumplicateValueunit(this.prd_unit_ids);
          $("#buvlue").val('');
          $("#burate").val('');
          $(".bunitrate_valid").text('');
          $("#base-select").prop('disabled', true);
          $("#bunit_ean").prop('disabled', true);
          this.selDisable = true;
          $('#inputder').show();

        }
        else {
          $(".bunit_valid").text('');
        }
        //  end add base unit in table

        //  add der unit
        if (derunitids && derunitids.unit_id) {
          var featv = $("#duvlue").val();
          var fdeurate = $("#durate").val();
          var dean = $("#dunit_ean").val();
          if (dean) {
            dean = dean.toString();
            $("#dunit_ean").val('');
          }
          else {
            dean = "";
          }

          // check duplicates     
          var res = this.prd_unit_ids.some(el => el.unit_id === derunitids.unit_id);
          var resEan = this.prd_unit_ids.some(el => el.ean_barcode === dean);
          if (dean == "")
            resEan = false;
          if (res == false && resEan == false) {
            this.prd_unit_ids.push({ "unit_type": 0, "unit_id": derunitids.unit_id, "unit_name": derunitids.unit_name, "ean_barcode": dean, "unit_rate": fdeurate.toString() });

            //  Remove duplicates     
            this.prd_unit_ids = this.coreService.removeDumplicateValueunit(this.prd_unit_ids);
            $("#duvlue").val('');
            $("#durate").val('');
            $('#dunit_valid').hide();

          } else {
            $('#dunit_valid').show();
          }
        }
        //  end der units

    //   }
    // });
    //  end addunitlist form

  }
  deleteUnit(data: Unit) {
    if (data.unit_type == 1) {
      var res = confirm("Removing Base Unit will remove Derived units from the table");
      if (res) {
        this.prd_unit_ids = [];
        $('#dunit_valid').hide();
        $('#addbase-btn').show();
        $("#base-select").prop('disabled', false);
        $("#bunit_ean").prop('disabled', false);
        $("#buvlue").val('');
        $("#burate").val('');
        this.readBaseUnits();
        $('#inputder').hide();
        this.selDisable = false;
      }
    } else {
      var type = data.unit_id,
        i: number;
      for (i = this.prd_unit_ids.length - 1; i >= 0; --i) {
        if (this.prd_unit_ids[i].unit_id == type) {
          this.prd_unit_ids.splice(i, 1);
        }
      }
    }

  }
  //form add unit
  productUnitSubmit(formgroup: { value: any; }) {
    this.unitsforstock = [];
    formgroup.value.prd_id = this.prodid;
    formgroup.value.prd_units = this.prd_unit_ids;
    this.prd_unit_ids_stock.push(this.prd_unit_ids);
    this.apiService.createProductUnit(formgroup.value).subscribe((res: Product) => {
      if (res.error != null) {

      }
      else {
        this.coreService.openSnackBar('Updated', 'Close');
        this.unitsforstock = res['data'];
        this.prd_unit_ids = [];
        this.derunitids = 'Derived Units';
        $('#dunit_valid').hide();
        this.setderived = false;
        res.error = '';

      }

    });

  }

  //form add Features
  productFeatSubmit(formgroup: { value: any; }) {
    formgroup.value.prd_id = this.prodid;
    formgroup.value.prd_features = this.dataSource;
    this.apiService.createProductFeat(formgroup.value).subscribe((res: Product) => {
      if (res.error != null) {
      }
      else {
        this.coreService.openSnackBar('Updated', 'Close');
        this.dataSource = [];
        this.featids = 'Select Features';
        this.setfeat = false;



      }

    });


  }


  //form add Stock
  productStkSubmit(formgroup: { value: any; }) {
    this.loading=true;
    var upt_unit_rates = [];
    var unit_rates = [];
    var errorobj = false;
    var errordp = this.errValidDp(this.resDup = null);
    // add unit ids  
    $('input[name="unitratechk"]:checked').each(function () {

      upt_unit_rates.push({ "unit_id": $(this).val() });
    });
    //  add unit rates for final sotck submit arr:  unit_rates
    $('input.edit_unit_rate').each(function () {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();
      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          //  unit validation  
          // console.log(unit_rt);        
          if (!unit_rt) {
            errorobj = true;
          } else if (unit_rt > 0) {
            errorobj = false;
          } else if (unit_rt == 0) {
            errorobj = false;
          } else {
            errorobj = true;
          }

          unit_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
        }
      });

    });
    this.mrp = (unit_rates[0].unit_rate);

    formgroup.value.prd_id = this.prodid;
    formgroup.value.cmp_stock_id = this.cmp_stock_id;
    formgroup.value.unit_rates = unit_rates;
    // validation
    var errorobjunitrate = this.errValidSr(errorobj);
    var prval = $('#prrate').val();
    var errorobjprate = this.errValidPrt(prval);
    if (!errorobjprate && !errorobjunitrate) {
      this.apiService.createProductStock(formgroup.value).subscribe((res: Product) => {
        this.loading=false;
        if (res.error != null) {

        }
        else {
          this.addStock = 1;
          this.coreService.createfunct(formgroup, res.error, 'Updated');
          // $('#set-stockModal').hide();
          // $('.modal-backdrop').hide();
          this.unitsforstock = [];
          // this.stockaddprate = res['data']['purchase_rate'];
          //  $('#prrate').val(this.stockaddprate);
          this.setstock = false;
          this.branchselids = [];
          // let element:HTMLElement = document.getElementById('set-stockModal') as HTMLElement;
          // element.click();
          $('#clsmdl').trigger('click');
          // formgroup.value.reset();       
        }
      });
    }


  }
  errValidSr(errorobj: boolean) {
    if (errorobj == true) {
      $('#err_sr').show();
      return true;
    }
    else {
      $('#err_sr').hide();
      errorobj = false;
      return false;
    }
  }
  errValidPrt(errFied: any) {
    if (!errFied) {
      $('#err_prate').show();
      return true;
    } else {
      $('#err_prate').hide();
      return false;
    }

  }
  errValidDp(errorobj: boolean) {
    if (errorobj == true) {
      $('#err_dp').show();
      return true;
    }
    else {
      $('#err_dp').hide();
      return false;

    }
  }
  addFeatureValue(featids: Feature, addfeatf) {
    this.apiService.createFeatList(addfeatf.value).subscribe((prod: Product) => {
      this.pageLoadingImg = false;
      if (prod.error != null) {
        this.result = prod.error;
      } else {
        this.result = '';
        // check duplicates
        const found = this.dataSource.some(el => el.feat_id === featids.feat_id);
        if (found == false) {
          var featv = $("#fvlue").val();
          this.dataSource.push({ "feat_id": featids.feat_id, "feat_name": featids.feat_name, "feat_val": featv.toString() });
          $("#fvlue").val('');
          this.result = '';
          addfeatf.reset();
          $('#dfeat_valid').hide();
        } else {
          $('#dfeat_valid').show();
        }
      }
    });
  }

  deleteFeat(id: number) {
    var type = id,
      i: number;
    for (i = this.dataSource.length - 1; i >= 0; --i) {
      if (this.dataSource[i].feat_id == type) {
        this.dataSource.splice(i, 1);
      }
    }

  }
  showcalculateMdlQP(id: number) {
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#mrp_id").val(id);
    $("#calculateMdlQP").show();
  }
  closecalculateMdlQP() {
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#sp_tax").val('');
    $("#calculateMdlQP").hide();
  }
  calculateTax(keyword) {
    if (!isNaN(keyword)) {
      console.log(this.prd_tax);
      let t = keyword - ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword - t;
      $("#sp").val(p);
      $("#sp_tax").val(t);
    } else {
      $("#sp").val('');
      $("#sp_tax").val('');
    }
  }
  showCalculatedTax() {
    let mrp_id = $("#mrp_id").val();
    let sp = $("#sp").val();
    $(".stk_mrp_" + mrp_id).val(sp);
    $("#calculateMdlQP").hide();
  }

  exl_tax(keyword,id){
    if(!isNaN(keyword)){
      keyword = Number (keyword);
      let t = keyword+ (keyword * this.prd_tax) / 100;
      $(".stk_mrp2_inp2_"+id).val(t);
    }
  }
  inc_tax(keyword,id){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $(".stk_mrp2_"+id).val(p);
    }
  }

  addLocation() {
    $('#srchbatchbtn').show();
    $('#addbatchbtn').hide();
    $('#stkbatchsel').hide();
    $('#batch1').show();

  }

  searchLoc() {
    $('#srchbatchbtn').hide();
    $('#addbatchbtn').show();
    $('#stkbatchsel').show();
    $('#batch1').hide();
  }

   //form search unit
   searchUnit(search: string, flag) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-unit').show();
  }

  removeUnitsug() {
    $('.prev-list-unit').hide();
  }
  unit_types = [
    { id: 1, name: 'Base Unit' },
    { id: 0, name: 'Derived Unit' }
  ];
  selectType(id: number) {
    if (id == 0) {
      $(".buclass").show();
      this.apiService.readBase(id).subscribe((units: Unit[]) => {
        this.bunitforder = units['data'];
      });
      $("#uqtysh").prop("disabled", false);

    } else {
      $(".buclass").hide();
      $("#uqtysh").prop("disabled", true);
    }
  }
  createUnit(form: { value: Unit; }) {
    if (form.value.unit_type == 1) {
      form.value.unit_base_id = 0;
      form.value.unit_base_qty = 1;
    }
    this.apiService.createUnit(form.value).subscribe((unit: Unit) => {
      if (unit.error != null) {
        this.result = unit.error;
      }
      else {
        this.result = '';
        this.coreService.createfunct(form, this.result, unit.message);
        this.readBaseUnits();
        this.modalRef.close();
      }

    });

  }


  createDUnit(form: { value: any; }) {
    this.apiService.createUnit(form.value).subscribe((unit: Unit) => {
      if (unit.error != null) {
        this.result2 = unit.error;
      }
      else {
        this.result2 = '';
        this.coreService.showMessage(unit.message);
        this.der_unit_name = '';
        this.der_unit_base_qty= '';
        this.der_unit_code= '';
        this.der_unit_display= '';
        this.der_unit_remarks= '';

        this.apiService.readDerbyId(this.der_base_unit).subscribe((res: Unit[]) => {
          this.derunits = res['data'];
        });
        document.getElementById('closeAddMdl').click();
      }

    });

  }
  searchDUnit(search: string, flag) {
    let searchval = new FormData();
    this.der_unit_code= search;
    this.der_unit_display= search;
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-unit').show();
  }
}
