import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-export-allproducts',
  templateUrl: './export-allproducts.component.html',
  styleUrls: ['./export-allproducts.component.css']
})
export class ExportAllproductsComponent implements OnInit {

  @ViewChild('closbtn', { static: false }) closbtn: ElementRef;
  fileName= 'export_all_produtcs.xlsx';
  fileName1= 'export_all_produtcs_sale_rate.xlsx';

  search: any;
  prdCat: any[];
  categories: any;
  cat_id: any;
  prd_id: string;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  slnum: number;
  prduntdtls: any;
  curpage: number;
  lastpage: number;
  prd_ean: string;
  barcode: string;
  err_msg: any;
  brcd_ean_err: any;
  brcd_err: any;
  total_prds: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  ExptotalProducts: any;
  Expprduntdtls: any;
  derived_unt_ean: any;
  derived_unt: any;
  from: any;
  subcatagories: any;
  changeBox: any;
  sub_cat_id: any;
  Expprduntdtls1: any;
  user_id: any;
  Expprduntdtls2: any;
  Explastsalerates: any;
  password: any;
  err_pswd: string;
  Export_elemnt: any;
  export_excel: any;
  

  constructor( private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {


    // const searchval = new FormData();
    // this.apiService.productUnitDetails(searchval, 1).subscribe((res: any) => {
    //   this.pageLoadingImg = false;
    //   this.prduntdtls=res['data'];
    //   this.curpage=Number(res.current_page);
    //   this.lastpage=Number(res.last_page);
    //   this.total_prds=res.total;
    //   this.from=res['from'];
    //   this.pgEnd(this.curpage, this.lastpage);
    //   this.pgStart(this.curpage);
    //   this.slnum = 0;


    // });
    this.user_id=this.coreService.getUserData('usr_id');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }


  prdUntDetail(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
  this.apiService.exportAllProducts(formdata.value, pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.prduntdtls=res['data'];
    this.curpage=Number(res.current_page);
    this.lastpage=Number(res.last_page);
    this.total_prds=res.total;
    this.err_msg=res['error'];
    this.brcd_err=res['key1'];
    this.brcd_ean_err=res['key2'];
    this.from=res['from'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

// console.log(this.prduntdtls);

    });
  }


    // search product
    selectProd(name: string) {
      if(this.prd_id){
        this.barcode='';
        this.prd_ean='';
      }
      let searchval = new FormData();
      searchval.append("prod_name", name);
      searchval.append("flag[]", "1");
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.search = res['data'];
      });
    }


    // search category

     searchCat(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.categories = res['data'];     
      });  
    }

    // search sub category

    searchSubcat(search: string)
{
      let searchval = new FormData();      
      searchval.append("subcat_name", search);    
       this.apiService.getSubcatByName(searchval).subscribe((res) => {
        this.subcatagories = res['data'];                
       });
       $('.prev-list').show(); 
}
    
    selectSubCats(catid: number) {
      this.apiService.getAllSubCatbyId(catid).subscribe((res) => {
        this.subcatagories = res['data'];
       
  
      });
    }

    listPrduct(cat_id){
      // this.prd_id=null;
      if(this.cat_id){
        this.barcode='';
        this.prd_ean='';
      }
      this.sub_cat_id=null;
      this.apiService.getPrdByCatId(cat_id).subscribe((res) => {
        this.search = res['data'];     

        this.selectSubCats(cat_id);
      });  
    }
    selectedprd(prdId :number){
      this.sub_cat_id=null;
      this.cat_id=null;
      this.apiService.getCatByPrdId(prdId).subscribe((res) => {
        this.categories = res['data'];     

        // console.log(this.categories);
        
      });  
    }

    pgEnd(curr, end) {
      if (curr == end) {
        this.pgend = true;
      } else {
        this.pgend = false;
      }
  
    }
  
    pgStart(curr) {
      if (curr == 1) {
        this.pgstart = true;
      } else {
        this.pgstart = false;
      }
    }

 
    exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }

    exportEXCL(Expformdata: { value: any; }){
      this.exportLoader = true;
       
        // debugger;
        Expformdata.value.export = 'export';
        
        this.apiService.exportAllProducts(Expformdata.value, 1).subscribe((res: any) => {
            this.Expprduntdtls = res['data'];
            this.ExptotalProducts =res.total;
            this.derived_unt=res['data'];
            this.derived_unt_ean=res['data'];
            
        
        setTimeout(() => {
          this.exportexcel(); 
      }, 3000);
      
      Expformdata.value.export = '';
     
  
      });
  
  
  
      }

      // code to export all products & details

      exportEXCL2(Expformdata: { value: any; }){
        this.exportLoader = true;
         
          // debugger;
          Expformdata.value.export = 'export';
          Expformdata.value.export2 = 'export2';
          this.export_excel='export-group1';
          
          this.apiService.exportAllProducts(Expformdata.value, 1).subscribe((res: any) => {
              this.Expprduntdtls1 = res.data;
              this.export_excel =res.export;
           
              
              // this.derived_unt=res['data'];
              // this.derived_unt_ean=res['data'];
              
          
          setTimeout(() => {
            this.exportexcel1(); 
        }, 3000);
        
        Expformdata.value.export = '';
       
    
        });
    
        }

        exportexcel1(): void 
        {
           /* table id is passed over here */   
           let element = document.getElementById(this.export_excel); 
           const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });
    
           /* generate workbook and add the worksheet */
           const wb: XLSX.WorkBook = XLSX.utils.book_new();
           XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
           /* save to file */
           XLSX.writeFile(wb, this.fileName);
           this.exportLoader = false;
          
        }

        // code tp export all product last sales rate
        exportEXCL3(Expformdata: { value: any; }){
        
            this.err_pswd='';
            Expformdata.value.export = 'export';
            if(this.password !='F@#4050307'){
              this.err_pswd='Password Not Match';
            }else{
              this.err_pswd='';
              this.closbtn.nativeElement.click();
            }
            

           if(this.err_pswd==''){
            this.exportLoader = true;
            this.apiService.exportAllProductsSaleRate(Expformdata.value, 1).subscribe((res: any) => {
              if(res['data']){
                this.Expprduntdtls2 = res['data'];
                this.Explastsalerates = res['lst_sales_heads'];
                this.Export_elemnt = res.export_sec;
                console.log('this.Export_elemnt');
                console.log(this.Export_elemnt);
                
                
              }
                
                // this.ExptotalProducts =res.total;
                // this.derived_unt=res['data'];
                // this.derived_unt_ean=res['data'];
                
            
            setTimeout(() => {
              this.exportexcel2(); 
          }, 3000);
          
          Expformdata.value.export = '';
         
      
          });
           }
      
          }

          exportexcel2(): void 
          {
             /* table id is passed over here */   
             let element = document.getElementById(this.Export_elemnt); 
             const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });
      
             /* generate workbook and add the worksheet */
             const wb: XLSX.WorkBook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
             /* save to file */
             XLSX.writeFile(wb, this.fileName1);
             this.exportLoader = false;
            
          }
  

}
