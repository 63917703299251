import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-cash-counter-report',
  templateUrl: './cash-counter-report.component.html',
  styleUrls: ['./cash-counter-report.component.css']
})
export class CashCounterReportComponent implements OnInit {
  pageLoadingImg: boolean;
  dateLoadingImg: boolean;
  date_selected : any;
  date: any;
  date1: any;
  date2: any;
  time1: any;
  time2: any;
  show: any;
  summary: any;
  repDate: any;
  totalData: number;
  printError: any;
  counter_settings: any;
  tot_bank_sale : any;
  tot_cash_sale : any;
  tot_credit_sale : any;
  total : any;
  server_sync_time : any;
  tot_cash_sale_ret : any;
  tot_closing_cash : any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  country_dec: number=2;
  fileName = 'cash_counter_report.xlsx';
  exportLoader: boolean;

  constructor(private apiService: ApiService, private coreService: CoreService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private translate: TranslateService) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
    // this.getCounterSetting();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }

  getCounterSetting(sel_date) {
    let searchval = new FormData();    
    sel_date = this.apiService.formatDate(sel_date);  
    searchval.append("sel_date", sel_date);
    this.apiService.getCounterSetting(searchval).subscribe((res) => {
      this.date_selected = true;
      if (res['data']) {
        this.counter_settings = res['data'];
        this.date1 = new Date(this.counter_settings.op_date);
        this.date2 = new Date(this.counter_settings.cl_date);
        let opt = "2000,10,10,"+this.counter_settings.op_time;
        let clt = "2000, 10, 10,"+this.counter_settings.cl_time;
        this.time1 = new Date(opt);
        this.time2 = new Date(clt);
      }
    });

  }

  search(formdata: { value: any; }) {
    this.printError = [];
    if (this.time1 === "" || this.time1 === undefined || this.time1 === null) {
      this.printError.push({ 'op_time': 'Required' });
      $('#op_time').css("border", "1px solid red");
    }
    else {
      $('#op_time').css("border", "1px solid #dedede");
    }

    if (this.time2 === "" || this.time2 === undefined || this.time2 === null) {
      this.printError.push({ 'cl_time': 'Required' });
      $('#cl_time').css("border", "1px solid red");
    }
    else {
      $('#cl_time').css("border", "1px solid #dedede");
    }

    if (this.date1 === "" || this.date1 === undefined || this.date1 === null) {
      this.printError.push({ 'op_date': 'Required' });
      $('#op_date').css("border", "1px solid red");
    }
    else {
      $('#op_date').css("border", "1px solid #dedede");
    }

    if (this.date2 === "" || this.date2 === undefined || this.date2 === null) {
      this.printError.push({ 'cl_date': 'Required' });
      $('#cl_date').css("border", "1px solid red");
    }
    else {
      $('#cl_date').css("border", "1px solid #dedede");
    }

    if (this.date === "" || this.date === undefined || this.date === null) {
      this.printError.push({ 'sel_date': 'Required' });
      $('#sel_date').css("border", "1px solid red");
    }
    else {
      $('#sel_date').css("border", "1px solid #dedede");
    }

    if (this.printError.length <= 0) {


      this.pageLoadingImg = true;


      if (formdata.value.time1) {
        this.time1 = new Date(formdata.value.time1);
      }
      if (formdata.value.time2) {
        this.time2 = new Date(formdata.value.time2);
      }

      if (formdata.value.date1) {
        this.date1 = new Date(formdata.value.date1);
      }
      if (formdata.value.date2) {
        this.date2 = new Date(formdata.value.date2);
      }

      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;

      formdata.value.date = this.apiService.formatDate(this.date);

      this.apiService.cashCounter(formdata.value).subscribe((res: any) => {
        this.pageLoadingImg = false;
        if(res['data']['data']){
          this.show = res.data;
          this.summary = res.data.data;
          this.repDate = res.data.Date;
          this.tot_bank_sale = res.data.tot_bank_sale;
          this.tot_cash_sale = res.data.tot_cash_sale;
          this.tot_credit_sale = res.data.tot_credit_sale;
          this.total = res.data.total;
          this.server_sync_time = res.data.server_sync_time;

          this.tot_cash_sale_ret = res.data.tot_cash_sale_ret;
          this.tot_closing_cash  = res.data.tot_closing_cash;
        }

      });
    }

  }

  exportEXCL() {
    this.exportLoader = true;
    setTimeout(() => {
      this.exportexcel();
    }, 1000);
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('cash_counter_excel');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  // pdf download
  generatePdf() {

    console.log(this.summary);
    
  
    const heads = [
      this.translate.instant('HOME.USER'),
      this.translate.instant('Vat.Total_Sales'),
      this.translate.instant('Common.Cash_Sales'),
      this.translate.instant('Common.Credit_sales'),
      this.translate.instant('HOME.Card_Bank'),
      this.translate.instant(' '),
      this.translate.instant('Common.Cash_Sales'),
      this.translate.instant('Common.Cash_Sales_Return'),
      this.translate.instant('Common.Closing_Cash')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.summary.forEach(item=>{
        data.push([
          item.usr_name,
          (item.total_sales).toFixed(this.country_dec),
          Number(item.cash_sale).toFixed(this.country_dec),
          Number(item.credit_sale).toFixed(this.country_dec),
          (item.bank_sale).toFixed(this.country_dec),
          '',
          (item.cash_sale).toFixed(this.country_dec),
          Number(item.cash_sale_ret).toFixed(this.country_dec),
          (item.closing_cash).toFixed(this.country_dec),
        ])
      })

      data.push([
        this.translate.instant('HOME.total'),
        (this.total).toFixed(this.country_dec),
        (this.tot_cash_sale).toFixed(this.country_dec),
        (this.tot_credit_sale).toFixed(this.country_dec),
        (this.tot_bank_sale).toFixed(this.country_dec),
        '',
        (this.tot_cash_sale).toFixed(this.country_dec),
        (this.tot_cash_sale_ret).toFixed(this.country_dec),
        (this.tot_closing_cash).toFixed(this.country_dec),
      ])

  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.Cash_counter')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.date')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.repDate.date,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.opening_time')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.repDate.date1,  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.closing_time')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.repDate.date2,  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.last_sync_time')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.server_sync_time,  valueXPos, headerHeight + 35);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
  
     
      doc.save(`Cash Counter.pdf`);


    
  }

}
