import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { ProductionService } from '../../../../../service/production.service';
import { User } from '../../../../../model/user.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-production-product',
  templateUrl: './production-product.component.html',
  styleUrls: ['./production-product.component.css']
})
export class ProductionProductComponent implements OnInit {
  fileName= 'production_product_summary.xlsx';

  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: string[];
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: string;
  prd_name: string;
  pid1: number;
  pid2: number;
  catagories: any;
  prd_formula: string;
  pcost1: any;
  pcost2: any;
  prate1: any;
  prate2: any;
  pamount1: any;
  pamount2: any;
  pcomm1: any;
  pcomm2: any;
  pmisc1: any;
  pmisc2: any;
  prod_id: any;
  prod_cost: any;
  prod_rate: any;
  prod_amount: any;
  prod_comm: any;
  prod_misc: any;
  prod_cat_id: any;
  tableCont: boolean;
  values: string[];
  isnoData : boolean;
  usr_id:any;
  filter:any;
  staffs:string[];
  datewise : string;
  viewType:any;
  repDate:any;ucost1:any;unit_cost:any;ucost2:any;pqty1:any;unit_qty:any;pqty2:any;ptot1:any;tot_amount:any;ptot2:any;
  mfg1:any;mfg2:any;exp1:any;exp2:any;period_mfg:any;period_exp:any;inspt_id:any;production_commission:any;ModelData:any;
  up: any;
  formula_all: any;
  stkprd_all: any;
  AllDet: any;
  subcatagories: any;
  changeBox: any;
  mfs: any;
  godowns: any;
  gd_id: any;
  exportLoader: boolean;
  Expdatas: any;
  Expdatewise: any;
  ExpAllDet: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  ExprepDate: any;
  country_dec: number=2;
  branch_address: any;
  heads: any[];
  mfgNull: any;
  expNull: any;
  cur_lang: string;
  filter_branch_id: number;
  branch_all: any;
  userType: any;

  constructor(private productionService: ProductionService, private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();

    this.country_dec=this.coreService.setDecimalLength();
    this.readProdSumm(1);
    this.listStaff();    
    this.up = this.coreService.getUserPrivilage();
    this.listProductFormula();

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterDate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  ngAfterContentInit(){
    
    this.getGodownList();
    this.searchCat('');
    this.searchManf('');
    this.gd_id = '';

  }

  readProdSumm(pageno) {  
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();  
    this.productionService.prodProd(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data']['data'];
      this.datewise = res['data'].datewise;
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];   
      this.repDate = res['data']['Date'];
      this.AllDet = res['data']['allDet'];


      if(this.datas && this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) { 
 
    formdata.value.filter = 1; 
     this.pageLoadingImg = true;
    this.isnoData = false;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.productionService.prodProd(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.datewise = res['data'].datewise;
      this.repDate = res['data']['Date'];
      this.AllDet = res['data']['allDet'];

      
      if(this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];

  //form search  for category
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  listStaff()
  {
    this.apiService.listAllStaff().subscribe((res) => {
      this.staffs = res['data'];     
    });

  }

  listProductFormula() { 
    this.productionService.getAllProductionFormula(1, 5000).subscribe((res) => {
      this.formula_all = res.data.data;
    });
  }
  searchFormula(search: string) {
    const searchval = new FormData();
    searchval.append('formula_name', search);
    this.productionService.searchProductionFormula(searchval).subscribe((res) => {
      this.formula_all = res.data;
    });
  }

  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }



  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.filter = 1;
     // debugger;
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        }
        this.isnoData = false;
        this.productionService.prodProd(formdata.value, 1).subscribe((res: any) => {
          
          this.Expdatas = res['data']['data']['data'];
          this.Expdatewise = res['data'].datewise;
          this.ExprepDate = res['data']['Date'];
          this.ExpAllDet = res['data']['allDet'];
    
          
          if(this.Expdatas.length==0)
          this.isnoData = true;
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    formdata.value.export = '';
    formdata.value.filter = '';
    
   

    });



    }

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;
  formdata.value.filter = 1;
   // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      }
      this.isnoData = false;
      this.productionService.prodProd(formdata.value, 1).subscribe((res: any) => {
        
        this.Expdatas = res['data']['data']['data'];
        this.Expdatewise = res['data'].datewise;
        this.ExprepDate = res['data']['Date'];
        this.ExpAllDet = res['data']['allDet'];
  
        
        if(this.Expdatas.length==0)
        this.isnoData = true;
   
        if(this.datewise == 'Product Report Detailed View'){
          this.heads = [
            this.translate.instant('Table.sl_no'),
            this.translate.instant('Production.output_products'),
            this.translate.instant('Van.Prdn') + '\n' + this.translate.instant('Common.id'),
            this.translate.instant('Van.Prdn') + '\n' + this.translate.instant('Table.date'),
            this.translate.instant('Production.formula_name'),
            this.translate.instant('Production.output_quantity'),
            this.translate.instant('HOME.UNIT') + '\n' + this.translate.instant('Production.cost'),
            this.translate.instant('HOME.total') + '\n' + this.translate.instant('Table.amnt'),
            this.translate.instant('Purchase.batch'),
            this.translate.instant('Purchase.mfg') + '\n' + this.translate.instant('Table.date'),
            this.translate.instant('Purchase.exp') + '\n' + this.translate.instant('Table.date')
          ];
        } else {
          this.heads = [
            this.translate.instant('Table.sl') + '\n' + this.translate.instant('Common.no'),
            this.translate.instant('Production.output_products'),
            this.translate.instant('Table.output_quantity'),
            this.translate.instant('Table.total_amount')
          ];
        }
      
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
      
          if(this.datewise == 'Product Report Detailed View'){

            this.Expdatas.forEach((item,i)=>{
              data.push([
                i+1,
                item.product,
                item.prodn_id,
                this.datePipe.transform(item.pdate, 'dd/MM/yyyy'),
                item.formula,
                item.qty + ' ' + item.unitcode,
                (item.rate).toFixed(this.country_dec),
                ((item.qty * item.rate)).toFixed(this.country_dec),
                item.batch,
                this.mfgNull,
                this.expNull
              ])

              if(item.mfd == '0000-00-00 00:00:00'){
                this.mfgNull = "na"
              } else {
                this.mfgNull =  this.datePipe.transform(item.mfd, 'dd/MM/yyyy')
              }

              if(item.exp == '0000-00-00 00:00:00'){
                this.expNull = "na"
              } else {
                this.expNull =  this.datePipe.transform(item.exp, 'dd/MM/yyyy')
              }

            })

          } else {

            this.Expdatas.forEach((item,i)=>{
              data.push([
                i+1,
                item.product,
                item.totalqty + ' ' + item.unitcode,
                (item.totalamount).toFixed(this.country_dec)
              ])
            })

          }
      
          let doc = this.excelService.getPdfObj();
      
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
      
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);

          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Van.production_product_report')}`,  valueXPos, headerHeight + 15);

          doc.setFontSize(10);
          doc.text( this.Expdatewise,  lblXPos, headerHeight + 20);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + (this.ExpAllDet.totalamount ).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExpAllDet.totQty,  valueXPos, headerHeight + 30);
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, this.heads, data, null);
      
         
          doc.save(`Production Product Report.pdf`);
  
          this.exportLoader = false;

  });

}
    getAllBranch() {
      this.apiService.readAllBranch().subscribe((res:any) => {
        this.branch_all = res.data;
        this.filter_branch_id = res.selected;
    
        // console.log(  this.branch_all );
      //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }
    
    searchBranch(search: string) {
      let searchval = new FormData();
      searchval.append("branch_name", search);
      this.apiService.getBranchByName(searchval).subscribe((res:any) => {
        this.branch_all = res.data;
        // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }


}


