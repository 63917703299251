import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-falcon-print-temp2',
  templateUrl: './falcon-print-temp2.component.html',
  styleUrls: ['./falcon-print-temp2.component.css']
})
export class FalconPrintTemp2Component implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  noPage = [];
  noRowPerPage = 14;
  ttlPage = 1;
  data = [];
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      this.data = this.saleQtnData.sales_sub;
      this.ttlPage = Math.ceil(this.saleQtnData.sales_sub.length /this.noRowPerPage);
      this.noPage = this.createRange(this.ttlPage);
    }
  }
  createRange(number,start=0){
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

}
