import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// component
import { PurchaseDashComponent } from './purchase-dash/purchase-dash.component';
import { PurchaseComponent } from './purchase-dash/purchase/purchase.component';
import { PurchaseVoidComponent } from './purchase-dash/purchase-void/purchase-void.component';
import { PurchaseReturnComponent } from './purchase-dash/purchase-return/purchase-return.component';
import { PurchaseReturnVoidComponent } from './purchase-dash/purchase-return-void/purchase-return-void.component';
import { PurchaseQuotationComponent } from './purchase-dash/purchase-quotation/purchase-quotation.component';
import { QuickPurchaseComponent } from './purchase-dash/quick-purchase/quick-purchase.component';
import { DemoQuickPurchaseComponent } from './purchase-dash/demo-quick-purchase/demo-quick-purchase.component';
import { GoodsReceiptComponent } from './purchase-dash/goods-receipt/goods-receipt.component';




const routes: Routes = [
 {path: 'purchase', component: PurchaseDashComponent},
 {path: 'purchase/purchase', component: PurchaseComponent},
 {path: 'purchase/void', component: PurchaseVoidComponent},
 {path: 'purchase/return', component: PurchaseReturnComponent},
 {path: 'purchase/return-void', component: PurchaseReturnVoidComponent},
 {path: 'purchase/quotation', component: PurchaseQuotationComponent},
 {path: 'purchase/purchase/:id', component: PurchaseComponent},
 {path: 'purchase/purchase/edit/:purch_id', component: PurchaseComponent},

 {path: 'quick-purchase', component: QuickPurchaseComponent},
 {path: 'demo-quick-purchase', component: DemoQuickPurchaseComponent},
 {path: 'goods-receipt', component: GoodsReceiptComponent},
 { path: 'goods-receipt/purchase/:gds_rec_id', component: QuickPurchaseComponent},


];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PurchaseRoutingModule {}