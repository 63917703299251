import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchase-and-return-report-summary',
  templateUrl: './purchase-and-return-report-summary.component.html',
  styleUrls: ['./purchase-and-return-report-summary.component.css']
})
export class PurchaseAndReturnReportSummaryComponent implements OnInit {
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  from: any;
  vch_type: any;
  add_by: any;
  payment_type: any;
  ExpModelData: any;
  fileName = 'purchase_and_return_summary.xlsx';
  exp_report: any;
  exp_from: any;
  exp_purchaseTotal: any;
  exp_purchDiscTotal: any;
  exp_purchInclVat: any;
  exp_totPurchVat: any;
  exp_purchExclVat: any;
  exp_balanceAmt: any;
  exp_reportType: any;
  exp_returnTotal: any;
  exp_returnDiscTotal: any;
  exp_retInclVat: any;
  exp_retVat: any;
  exp_retExclVat: any;
  exp_balanceExclVatAmt: any;
  exp_repDate: any;
  up: any;
  purchDiscTotal: any;
  purchInclVat: any;
  purchVat: any;
  purchExclVat: any;
  returnDiscTotal: any;
  retInclVat: any;
  retVat: any;
  retExclVat: any;
  balanceExclVatAmt: any;
  totPurchVat: any;
  pdf: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  country_dec: string;
  cur_lang: string;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe) { }
  pageLoadingImg:boolean;
  slnum:number;
  curpage:number;
  lastpage:number;
  pgend:boolean;
  pgstart:boolean;
  report:any;
  purchaseTotal;
  returnTotal;
  balanceAmt;
  supplierAll:any;
  values:any;
  resultobj:any;
  period_type:any;
  date1:any;
  date2:any;
  purch_ref_filter:any;
  voucher_filter:any;
  reportType:any;
  repDate:any;
  purch_supp_id:any;

  filter_date_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];



  filter_type = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '2', name: 'Voided' },

  ];

  voucher_type = [
    { id: '1', name: 'Purchase & Return' },
    { id: '2', name: 'Purchase' },
    { id: '3', name: 'Return' },


  ];
  exportLoader: boolean;
  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
    
    this.searchUser('');
    this.searchSupplier('');
    this.up = this.coreService.getUserPrivilage();
    this.exportLoader = false;
    this.getAllBranch();
    // this.filter_branch_id = 1;
    this.userType =  this.coreService.getUserData('user_type');
    
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('rep_type','1');
    this.vch_type = '1';
    this.apiService.purchaseAndreturn(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;

      this.report = res.data.data;
      this.from = res.data.from;
      this.pdf = res.data.pdf;
      // this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.purchaseTotal = res.data.totalCount.purchaseTotal;
      this.purchDiscTotal = res.data.totalCount.purchDic;
      this.purchInclVat = res.data.totalCount.purchinclVat;
      this.totPurchVat = res.data.totalCount.purchVat;
      this.purchExclVat = res.data.totalCount.purchExclVat;
      this.balanceAmt = res.data.totalCount.balanceAmt;
      this.reportType = res.data.reportType;
      this.returnTotal = res.data.totalCount.returnTotal;
      this.returnDiscTotal = res.data.totalCount.returnDisc;
      this.retInclVat = res.data.totalCount.retInclVat;
      this.retVat = res.data.totalCount.returnVat;
      this.retExclVat = res.data.totalCount.returnExclVat;
      this.balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
      this.branch_display_name = this.coreService.getUserData('branch_disp_name');
      this.branch_name = this.coreService.getUserData('branch_name');
      this.branch_display_code = this.coreService.getUserData('branch_code');
      this.branch_address = this.coreService.getUserData('branch_address');

    });

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
   
   

  
  }

  ngOnDestroy(){
    $('.close').click();
 
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }


  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();

    } else {
      $('#val1').hide();


    }

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }












  pageNext(formdata: { value: any; }, pageno: any) {
    
    this.pageLoadingImg = true;
    // const searchval = new FormData();
    formdata.value.export = '';
    this.apiService.purchaseAndreturn(formdata.value,pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.pdf = res.data.pdf;
      this.report = res.data.data;
      // this.purchInvSum = res.data.data;
      this.from = res.data.from;

      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.purchaseTotal = res.data.totalCount.purchaseTotal;
      this.purchDiscTotal = res.data.totalCount.purchDic;
      this.purchInclVat = res.data.totalCount.purchinclVat;
      this.totPurchVat = res.data.totalCount.purchVat;
      this.purchExclVat = res.data.totalCount.purchExclVat;
      this.balanceAmt = res.data.totalCount.balanceAmt;
      this.reportType = res.data.reportType;
      this.returnTotal = res.data.totalCount.returnTotal;
      this.returnDiscTotal = res.data.totalCount.returnDisc;
      this.retInclVat = res.data.totalCount.retInclVat;
      this.retVat = res.data.totalCount.returnVat;
      this.retExclVat = res.data.totalCount.returnExclVat;
      this.balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
      this.reportType = res.data.reportType;
      this.repDate = res.data.Date;
    
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
   }

 //form search
 searchUser(search: string) {
  let searchval = new FormData();
  searchval.append("usr_name", search);
  this.apiService.getUserByName(searchval).subscribe((res) => {
    this.values = res['data'];
  });
}
exportexcel(): void {
  /* table id is passed over here */
  let element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  this.exportLoader = false;

}
export(formdata: { value: any; }) {
  this.exportLoader = true;

  // debugger;
  formdata.value.export = 'export';
  this.apiService.purchaseAndreturn(formdata.value,1).subscribe((res: any) => {
    this.pageLoadingImg = false;

    

    this.exp_report = res.data.data;
    // this.purchInvSum = res.data.data;
    this.exp_from = res.data.from;

    this.exp_purchaseTotal = res.data.totalCount.purchaseTotal;
    this.exp_purchDiscTotal = res.data.totalCount.purchDic;
    this.exp_purchInclVat = res.data.totalCount.purchinclVat;
    this.exp_totPurchVat = res.data.totalCount.purchVat;
    this.exp_purchExclVat = res.data.totalCount.purchExclVat;
    this.exp_balanceAmt = res.data.totalCount.balanceAmt;
    this.exp_reportType = res.data.reportType;
    this.exp_returnTotal = res.data.totalCount.returnTotal;
    this.exp_returnDiscTotal = res.data.totalCount.returnDisc;
    this.exp_retInclVat = res.data.totalCount.retInclVat;
    this.exp_retVat = res.data.totalCount.returnVat;
    this.exp_retExclVat = res.data.totalCount.returnExclVat;
    this.exp_balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
    this.exp_repDate = res.data.Date;
  
    this.slnum = 0;
    this.ExpModelData = true;


    setTimeout(() => {
      this.exportexcel();
    }, 3000);



  });



}


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
  
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

  // debugger;
  formdata.value.export = 'export';
  this.apiService.purchaseAndreturn(formdata.value,1).subscribe((res: any) => {
    this.pageLoadingImg = false;

    

    this.exp_report = res.data.data;
    // this.purchInvSum = res.data.data;
    this.exp_from = res.data.from;

    this.exp_purchaseTotal = res.data.totalCount.purchaseTotal;
    this.exp_purchDiscTotal = res.data.totalCount.purchDic;
    this.exp_purchInclVat = res.data.totalCount.purchinclVat;
    this.exp_totPurchVat = res.data.totalCount.purchVat;
    this.exp_purchExclVat = res.data.totalCount.purchExclVat;
    this.exp_balanceAmt = res.data.totalCount.balanceAmt;
    this.exp_reportType = res.data.reportType;
    this.exp_returnTotal = res.data.totalCount.returnTotal;
    this.exp_returnDiscTotal = res.data.totalCount.returnDisc;
    this.exp_retInclVat = res.data.totalCount.retInclVat;
    this.exp_retVat = res.data.totalCount.returnVat;
    this.exp_retExclVat = res.data.totalCount.returnExclVat;
    this.exp_balanceExclVatAmt = res.data.totalCount.balanceExclAmt;
    this.exp_repDate = res.data.Date;
  
    this.slnum = 0;
    this.ExpModelData = true;


    const heads = [
      this.translate.instant('Table.sl') + '\n' + this.translate.instant('Common.no'),
      this.translate.instant('Common.Purch')+ '\n' + this.translate.instant('Purchase.refno'),
      this.translate.instant('Table.date'),
      this.translate.instant('Purchase.supplier'),
      this.translate.instant('Common.Inv_No'),
      this.translate.instant('Purchase.payment_type'),
      this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'),
      this.translate.instant('Van.Return') + '\n' + this.translate.instant('Table.amnt')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 87;
      const data = [];

      this.exp_report.forEach((item,i)=>{
        const arr=[
          i+1,
          item.purch_ref_no,
          this.datePipe.transform(item.date, 'dd/MM/yyyy'),
          item.inv_no
        ]

        if(this.translate.currentLang == 'English'){
          arr.splice(3,0, item.supp)
        } else if(this.translate.currentLang == 'Arabic'){
          arr.splice(3,0, item.supp_alias)
        }

        if(item.pay_type == '1'){
          arr.splice(5,0, 'Credit')
          arr.splice(6,0, (item.net_amount).toFixed(this.country_dec))
          arr.splice(7,0, ' ')
        } else if(item.pay_type == '2'){
          arr.splice(5,0, 'Cash')
          arr.splice(6,0, ' ')
          arr.splice(7,0, (item.net_amount).toFixed(this.country_dec))
        } else {
          arr.splice(5,0, ' ')
          arr.splice(6,0, ' ')
          arr.splice(7,0, ' ')
        }

        data.push(arr)
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.exp_repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.exp_repDate.date1 + '   to   ' + this.exp_repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Purchase.Purchase_And_Return_Summary')}`,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.net_purchase_amnt')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + (this.purchaseTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totol_disc')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + (this.purchDiscTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totol_amnt_incl_vat')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + (this.purchInclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Total_VAT_Amount')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + (this.totPurchVat).toFixed(this.country_dec),  valueXPos, headerHeight + 35);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_amount')}` + ' ' + `${this.translate.instant('Common.Exclude_VAT')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + (this.purchExclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 40);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Balance_Amount')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' + (this.balanceAmt).toFixed(this.country_dec),  valueXPos, headerHeight + 45);

      if(this.reportType){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 50);
        doc.setFontSize(10);
        doc.text(':  ' + this.reportType,  valueXPos, headerHeight + 50);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.net_return_amnt')}`, lblXPos, headerHeight + 55);
      doc.setFontSize(10);
      doc.text(':  ' + (this.returnTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 55);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totol_return_disc')}`, lblXPos, headerHeight + 60);
      doc.setFontSize(10);
      doc.text(':  ' + (this.returnDiscTotal).toFixed(this.country_dec),  valueXPos, headerHeight + 60);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totol_ret_incl_vat')}`, lblXPos, headerHeight + 65);
      doc.setFontSize(10);
      doc.text(':  ' + (this.retInclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 65);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Sales.Total_Returned_Vat_Amount')}`, lblXPos, headerHeight + 70);
      doc.setFontSize(10);
      doc.text(':  ' + (this.retVat).toFixed(this.country_dec),  valueXPos, headerHeight + 70);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.Total_Retured_Amount')}` + ' ' + `${this.translate.instant('Common.Exclude_VAT')}`, lblXPos, headerHeight + 75);
      doc.setFontSize(10);
      doc.text(':  ' + (this.retExclVat).toFixed(this.country_dec),  valueXPos, headerHeight + 75);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Balance_Amount')}` + ' ' + `${this.translate.instant('Common.Exclude_VAT')}`, lblXPos, headerHeight + 80);
      doc.setFontSize(10);
      doc.text(':  ' + (this.balanceExclVatAmt).toFixed(this.country_dec),  valueXPos, headerHeight + 80);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 85, lblXPos, heads, data, null);
  
     
      doc.save(`Purchase and Return Summary.pdf`);

      this.exportLoader = false;

  });
   
  }

}
