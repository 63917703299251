import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-delivery-challan-itemwise',
  templateUrl: './delivery-challan-itemwise.component.html',
  styleUrls: ['./delivery-challan-itemwise.component.css']
})
export class DeliveryChallanItemwiseComponent implements OnInit {
  fileName= 'delivery_challan_rpt_itemwise.xlsx';

  branch_all: any;
  products: any[];
  // catagories: any;
  users: any;
  taxCategories: any;

  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  pageLoadingImg: boolean;
  repDate: any;
  delReptItm: any;
  itemssss: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_amount: any;
  total_discount_amount: any;
  total_qty_sold: any;
  exportLoader: boolean;
  ExpdelReptItm: any;
  Exptotal_amount: any;
  Exptotal_discount_amount: any;
  Exptotal_qty_sold: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  country_dec: number=2;
  cur_lang: string;
  userType: any;
  filter_branch_id: number;
  prd: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.filter_branch_id = 1;
    this.getAllBranch();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval=new FormData(); 
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.apiService.delChallanItemWise(searchval,1).subscribe((res: any) => {
    
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.delReptItm = res['data']['data'];
      
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
    
    });
    this.userType = this.coreService.getUserData('user_type');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.getAllTaxCategories();

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.delChallanItemWise(formdata.value, pageno).subscribe((res: any) => {
    
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.delReptItm = res['data']['data'];
      
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
      // if(res.data.profit_rep || res.data.det_profit_rep){
      //   if(res.data.profit_rep){
      //     this.enablePrft = true;
      //   }
      //   this.total_item_cost = res.data.profit.total_item_cost;
      //   this.total_item_profit = res.data.profit.total_item_profit;
      // }else{
      //   this.enablePrft = false;
      // }
      // this.errorPassword = '';

      // this.pgEnd(this.curpage, this.lastpage);
      // this.pgStart(this.curpage);
      // if(!res.data.msg){
      //   $('.close').trigger('click');
      // }else{
      //   this.errorPassword = res.data.msg;
      // }
    });

  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    // searchCat(search: string) {
    //   const searchval = new FormData();
    //   searchval.append('cat_name', search);
    //   this.apiService.getCatbyName(searchval).subscribe((res) => {
    //     this.catagories = res['data'];
    //   });
  
    // }

    searchUser(search: string) {
      let searchval = new FormData();
      searchval.append("usr_name", search);
      this.apiService.getUserByName(searchval).subscribe((res) => {
        this.users = res['data'];
      });
    }

    getAllTaxCategories(){
      this.apiService.getAllTaxCategory().subscribe((resp) => {
  
        this.taxCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
      });
    }

    exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });
  
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
      
    }
  
    exportEXCL(formdata: { value: any; }){
      this.exportLoader = true;
       
      
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        
          formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
          formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        
        }
      this.apiService.delChallanItemWise(formdata.value,1).subscribe((res: any) => {
          this.pageLoadingImg = false;
          
          
          this.ExpdelReptItm = res['data']['data'];
  
          this.Exptotal_amount = res['data']['total_amount'];
          this.Exptotal_discount_amount = res['data']['total_discount_amount'];
          this.Exptotal_qty_sold = res['data']['total_qty_sold'];
          this.repDate = res.data.Date;
  
  
  
          setTimeout(() => {
          this.exportexcel();
      }, 3000);
  
  
  
      });
  
    }

  

    getAllBranch() {
      this.apiService.readAllBranch().subscribe((res:any) => {
        this.branch_all = res.data;
        this.filter_branch_id = res.selected;
    
        // console.log(  this.branch_all );
      //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      });
    }
    
  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;
       
      
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        
          formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
          formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        
        }
      this.apiService.delChallanItemWise(formdata.value,1).subscribe((res: any) => {
          this.pageLoadingImg = false;
          
          
          this.ExpdelReptItm = res['data']['data'];
  
          this.Exptotal_amount = res['data']['total_amount'];
          this.Exptotal_discount_amount = res['data']['total_discount_amount'];
          this.Exptotal_qty_sold = res['data']['total_qty_sold'];
          this.repDate = res.data.Date;

          console.log(this.ExpdelReptItm);
          

          const heads = [
            this.translate.instant('Common.prd_name'),
            '',
            this.translate.instant('Purchase.ref_no'),
            this.translate.instant('Common.date'),
            this.translate.instant('Table.item_cost'),
            this.translate.instant('Table.rate'),
            this.translate.instant('Table.qty'),
            this.translate.instant('Table.amt'),
            this.translate.instant('Sales.Item_Disc'),
            this.translate.instant('HOME.challan_no')
          ];
      
            const lblXPos = 10;
            const headerHeight = 15;
            const valueXPos = 60;
            const data = [];
      
            this.ExpdelReptItm.forEach(item=>{
              if (this.translate.currentLang === 'Arabic') {
                this.prd= item.prd_alias;
            } else if (this.translate.currentLang === 'English') {
                this.prd= item.prd_name;
            }
              data.push([
              this.prd,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              ''
              ])

              item.items.forEach(item1=>{
                data.push([
                  '',
                  item1.prd_barcode,
                  item1.del_chellan_sub_sales_inv_no,
                  item1.del_chellan_sub_date,
                  (item1.del_chellan_sub_prate).toFixed(this.country_dec),
                  (item1.challansub_rate).toFixed(this.country_dec),
                  item1.del_chellan_sub_qty,
                  (item1.challan_amount).toFixed(this.country_dec),
                  (item1.challansub_discount).toFixed(this.country_dec),
                  item1.branch_inv_no
                ])
              })

            })
        
            let doc = this.excelService.getPdfObj();
      
            var addressLines = this.branch_address.split('\r\n');
            var addressText = addressLines.join(', ');
      
            doc.setFont('Amiri');
            doc.setFontSize(12);
            doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
            doc.setFont('Amiri');
            doc.setFontSize(9);
            doc.text(addressText, 71, headerHeight + 2);
      
            if(this.repDate){
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
              doc.setFontSize(10);
              doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
            }
      
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
            doc.setFontSize(10);
            doc.text(':  ' + `${this.translate.instant('Sales.Delivery_Challan_item_wise')}`,  valueXPos, headerHeight + 15);
      
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.ttl_challan_amnt')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.Exptotal_qty_sold,  valueXPos, headerHeight + 20);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 25);
            doc.setFontSize(10);
            doc.text(':  ' + (this.Exptotal_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
  
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 30);
            doc.setFontSize(10);
            doc.text(':  ' + (this.Exptotal_discount_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 30);
            
            doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
        
           
            doc.save(`Delivery Note Item Wise.pdf`);
      
            this.exportLoader = false;
      });
  
  }
      
}
