import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sales-order-rep',
  templateUrl: './sales-order-rep.component.html',
  styleUrls: ['./sales-order-rep.component.css']
})
export class SalesOrderRepComponent implements OnInit {
 

  fileName= 'sales_order_report.xlsx';

  filter_types_orderdate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_types_delv_date = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'cu', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  report_type=[
    { id: 1, name: 'Summary' },
    { id: 2, name: 'Date Wise' },
    { id: 3, name: 'Detailed' },
  ];
  stats_name = {
    '1': 'Pending',
    '2': 'Invoiced', 
  };

  Status = [
    { id: '2', name: 'Invoiced' },
    { id: '1', name: 'Pending' },

  ];
  pageLoadingImg: boolean;
  products: any[];
  salesOrdRpt: any;
  pageFrom: any;
  repDate: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  total_product: any;
  exportLoader: boolean;
  ExpsalesOrdRpt: any;
  Exptotal_product: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  rep_type: number;
  reportType: any;
  cur_lang: string;
  prd: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.langChange();
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.rep_type=3;
    this.apiService.salesOrderRpt(searchval, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.salesOrdRpt = res['data']['data'];
      this.reportType = res['data']['Report'].report_type;
      // console.log(this.salesOrdRpt);
      
      
      this.repDate = res.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_product = res.data.total_numbr_of_prodcts;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }

  langChange(){
    this.translate.get(['Common.today','Common.summary','Common.date_wise','Common.detailed', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Sales.Invoiced','transaction.Pending']).subscribe((res: string) => {    
     
      
      this.filter_types_orderdate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.report_type=[
        { id: 1, name: res['Common.summary'] },
        { id: 2, name: res['Common.date_wise'] },
        { id: 3, name: res['Common.detailed'] },
      ];
      this.filter_types_delv_date = [
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name:  res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name:  res['Common.last_yr'] },
        { id: 'cu', name:  res['Common.cust_date'] },
        { id: '', name:  res['Common.all'] }
    
      ];

      this.Status = [
        { id: '2', name: res['Sales.Invoiced'] },
        { id: '1', name: res['transaction.Pending'] },
    
      ];
    });

  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN' || this.userType == 'AUDITOR' ) {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    
   
   
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    if (formdata.value.period_type_d === 'cu') {
  
      formdata.value.date_d1 = (formdata.value.date_d1) ? this.apiService.formatDate(formdata.value.date_d1) : formdata.value.date_d1;
      formdata.value.date_d2 = (formdata.value.date_d2) ? this.apiService.formatDate(formdata.value.date_d2) : formdata.value.date_d2;
      
    }
    this.apiService.salesOrderRpt(formdata.value, pageno).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.salesOrdRpt = res['data']['data'];
      this.reportType = res['data']['Report'].report_type
      // console.log(this.salesOrdRpt);
      
      
      this.repDate = res.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_product = res.data.total_numbr_of_prodcts;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });

  }

     // search product
     selectProd(name: string) {

      const searchval = new FormData();
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      searchval.append('prod_name', name);
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }


  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
    
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    if (formdata.value.period_type_d === 'cu') {
  
      formdata.value.date_d1 = (formdata.value.date_d1) ? this.apiService.formatDate(formdata.value.date_d1) : formdata.value.date_d1;
      formdata.value.date_d2 = (formdata.value.date_d2) ? this.apiService.formatDate(formdata.value.date_d2) : formdata.value.date_d2;
      
    }
    this.apiService.salesOrderRpt(formdata.value, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.ExpsalesOrdRpt = res['data']['data'];
      this.reportType = res['data']['Report'].report_type
      // console.log(this.salesOrdRpt);
      
      
      this.repDate = res.data.Date;
      this.Exptotal_product = res.data.total_numbr_of_prodcts;



        setTimeout(() => {
        this.exportexcel();
    }, 3000);

    formdata.value.export =  '';

    });


  }

  // pdf download
  generatePdf(formdata: { value: any; }) {
    this.exportLoader = true;
     
    
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    if (formdata.value.period_type_d === 'cu') {
  
      formdata.value.date_d1 = (formdata.value.date_d1) ? this.apiService.formatDate(formdata.value.date_d1) : formdata.value.date_d1;
      formdata.value.date_d2 = (formdata.value.date_d2) ? this.apiService.formatDate(formdata.value.date_d2) : formdata.value.date_d2;
      
    }
    this.apiService.salesOrderRpt(formdata.value, 1).subscribe((res:any) => {
      
      this.ExpsalesOrdRpt = res['data']['data'];
      this.reportType = res['data']['Report'].report_type
      // console.log(this.salesOrdRpt);
      
      this.repDate = res.data.Date;
      this.Exptotal_product = res.data.total_numbr_of_prodcts;

      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Table.itm_name'),
        this.translate.instant('HOME.order_qty')
      ];

      if(this.rep_type == 3){
        heads.splice(2,0, this.translate.instant('Purchase.ref_no'))
        heads.splice(3,0, this.translate.instant('HOME.order_no'))
        heads.splice(5,0, this.translate.instant('Common.Delivery_date'))
        heads.splice(6,0, this.translate.instant('Table.status'))
      }

      if(this.rep_type != 1){
        heads.splice(4,0, this.translate.instant('HOME.order_date'))
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 45;
        const data = [];
  
        this.ExpsalesOrdRpt.forEach((item,i)=>{
          item.items.forEach((item1,j)=>{
            if (this.translate.currentLang === 'Arabic') {
              this.prd= item.prd_alias;
          } else if (this.translate.currentLang === 'English') {
              this.prd= item.prd_name;
          }
            const arr = [
              this.prd,
              item1.sales_ord_sub_qty
             ];

             if(j==0){
              arr.splice(0,0, i + 1)
            } else {
              arr.splice(0,0, '')
            }

            if(this.rep_type == 3){
              arr.splice(2,0, item1.order_id)
              arr.splice(3,0, item1.branch_inv_no)
              arr.splice(5,0,  this.datePipe.transform(item1.delvry_date, 'dd/MM/yyyy'))

              if(item1.status == 1){
                arr.splice(6,0, 'Pending')
              } else if(item1.status == 2){
                arr.splice(6,0, 'Invoiced')
              }
            }

            if(this.rep_type != 1){
              arr.splice(4,0,  this.datePipe.transform(item1.sales_ord_sub_date, 'dd/MM/yyyy'))
            }
  
            data.push(arr)
            
          })
        })

    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Sales.sale_order_rep_item_wise')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.Exptotal_product,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.reportType,  valueXPos, headerHeight + 25);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
    
       
        doc.save(`Sales Order Report (item Wise).pdf`);
  
        this.exportLoader = false;

    });
    
    formdata.value.export =  '';
  }

}
