import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserComponent } from "./view/companymaster/user/user.component";
import { ProductComponent } from "./view/product/product.component";
import { HomeComponent } from "./view/home/home.component";
import { UnitComponent } from "./view/product/unit/unit.component";
import { FeatureComponent } from "./view/product/feature/feature.component";
import { CatagoryComponent } from "./view/product/catagory/catagory.component";
import { SubcatagoryComponent } from "./view/product/subcatagory/subcatagory.component";
import { ManufactureComponent } from "./view/product/manufacture/manufacture.component";
import { EditproductComponent } from "./view/product/editproduct/editproduct.component";
import { MasterComponent } from "./view/master/master.component";
import { ProductdashComponent } from "./view/productdash/productdash.component";
import { LedgerComponent } from "./view/ledger/ledger.component";
import { LedgermanageComponent } from "./view/ledger/ledgermanage/ledgermanage.component";
import { LedgergroupComponent } from "./view/ledger/ledgergroup/ledgergroup.component";
import { CompanymasterComponent } from "./view/companymaster/companymaster.component";
import { BranchComponent } from "./view/companymaster/branch/branch.component";
import { LogoutComponent } from "./view/companymaster/logout/logout.component";
import { LoginComponent } from "./view/companymaster/login/login.component";
import { UpdateMutiProductComponent } from "./view/product/update-muti-product/update-muti-product.component";
import { HrdashComponent } from "./view/hrdash/hrdash.component";
import { DepartmentComponent } from "./view/hr/department/department.component";
import { DesignationComponent } from "./view/hr/designation/designation.component";
import { EmployeeComponent } from "./view/hr/employee/employee.component";
import { IqamaComponent } from "./view/hr/iqama/iqama.component";
import { PassportComponent } from "./view/hr/passport/passport.component";
import { BaladiyahComponent } from "./view/hr/baladiyah/baladiyah.component";
import { LicenceComponent } from "./view/hr/licence/licence.component";
import { EmpReportComponent } from "./view/hr/emp-report/emp-report.component";
import { DeductionsComponent } from './view/hr/deductions/deductions.component';
import { AttendanceComponent } from './view/hr/attendance/attendance.component';
import { CompanyHolidaysComponent } from './view/hr/company-holidays/company-holidays.component';
import { PayrollSettingsComponent } from './view/hr/payroll-settings/payroll-settings.component';
import { PayrollComponent } from './view/hr/payroll/payroll.component';
import { AdditionsComponent } from './view/hr/additions/additions.component';
import { ViewInvoiceComponent } from "./view/view-invoice/view-invoice.component";
import { InvoiceDesignComponent } from "./invoice-design/invoice-design.component";
import { InvoiceTwoComponent } from "./invoice-two/invoice-two.component";
import { SalesThermalBillComponent } from "../app/module/sales-thermal-bill/sales-thermal-bill.component";
import { PriceChekerComponent } from "./view/price-cheker/price-cheker.component";
import { DashboardTwoComponent } from "./dashboard-two/dashboard-two.component";
import { InvoiceWithoutHeaderComponent } from "./invoice-without-header/invoice-without-header.component";
import { PostDatedChequeComponent } from "./module/transaction/transaction-dash/vouchers/post-dated-cheque/post-dated-cheque.component";
import { WeightScaleItemsComponent } from './view/product/weight-scale-items/weight-scale-items.component';
import { InvoiceThreeComponent } from "./invoice-three/invoice-three.component";
import { InvoiceFourComponent } from "./invoice-four/invoice-four.component";
import { QuickProductCreateComponent } from "./view/product/quick-product-create/quick-product-create.component";
import { ProductLocationComponent } from "./view/product/product-location/product-location.component";
import { ExpiryNotificationComponent } from "./module/batch/expiry-notification/expiry-notification.component";
import { TemplateRtlComponent } from "./template-rtl/template-rtl.component";
import { TemplateTwoRtlComponent } from "./template-two-rtl/template-two-rtl.component";
import { CalendarComponent} from "./calendar/calendar.component";
import { NewInvoiceComponent } from "./new-invoice/new-invoice.component";
import { ServiceTypeComponent } from "./module/service/service-type/service-type.component";
import { SalesOrderListComponent } from "./module/transaction/transaction-dash/sales/sales-order-list/sales-order-list.component";
import { FreightTypeComponent } from "./module/freight/freight-type/freight-type.component";
import { NewDashboardComponent } from "./new-dashboard/new-dashboard.component";
import { RebateReportComponent } from "./module/report/report-dash/rep-purchase/rebate-report/rebate-report.component";
import { ExciseCategoryComponent } from "./view/product/excise-category/excise-category.component";
import { OfferFlyerPosterComponent } from "./view/product/offer-flyer-poster/offer-flyer-poster.component";
import { SalesInvoiceComponent } from "./module/transaction/transaction-dash/sales/sales-invoice/sales-invoice.component";
import { SalesReturnComponent } from "./module/transaction/transaction-dash/sales/sales-return/sales-return.component";
import { PurchaseReturnComponent } from "./module/purchase/purchase-dash/purchase-return/purchase-return.component";
import { CreditNoteComponent } from "./module/transaction/transaction-dash/vouchers/credit-note/credit-note.component";
import { DebitNoteComponent } from "./module/transaction/transaction-dash/debit-note/debit-note.component";
import { FavoriteComponent } from "./module/favorite/favorite.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "home", component: HomeComponent },
  { path: "user", component: UserComponent },
  { path: "product", component: ProductComponent },
  { path: "editproduct", component: EditproductComponent },
  { path: "unit", component: UnitComponent },
  { path: "quick_product_create", component: QuickProductCreateComponent },
  { path: "quick_product/editproduct/:prd_id", component: EditproductComponent },
  { path: "feature", component: FeatureComponent },
  { path: "catagory", component: CatagoryComponent },
  { path: "subcatagory", component: SubcatagoryComponent },
  { path: "manufacture", component: ManufactureComponent },
  { path: "master", component: MasterComponent },
  { path: "products", component: ProductdashComponent },
  { path: "manage_ledger", component: LedgermanageComponent },
  { path: "manage_group", component: LedgergroupComponent },
  { path: "manage_company", component: CompanymasterComponent },
  { path: "branch", component: BranchComponent },
  { path: "logout", component: LogoutComponent },
  { path: "update-multiple-product", component: UpdateMutiProductComponent },
  { path: "pos", loadChildren: "./module/pos/pos.module#PosModule" },
  { path: "hr", component: HrdashComponent },
  { path: "department", component: DepartmentComponent },
  { path: "designation", component: DesignationComponent },
  { path: "employee", component: EmployeeComponent },
  { path: "iqama", component: IqamaComponent },
  { path: "passport", component: PassportComponent },
  { path: "baladiyah", component: BaladiyahComponent },
  { path: "licence", component: LicenceComponent },
  { path: "deductions", component: DeductionsComponent },
  { path: "attendance", component: AttendanceComponent },
  { path: "company_holidays", component: CompanyHolidaysComponent },
  { path: "payroll_settings", component: PayrollSettingsComponent },
  { path: "payroll", component: PayrollComponent },
  { path: "additions", component: AdditionsComponent },
  { path: "emp-report", component: EmpReportComponent },
  { path: "view_invoice/:inv_id/:cmpny", component: ViewInvoiceComponent },
  { path: "invoice-design", component: InvoiceDesignComponent },
  { path: "invoice-two", component: InvoiceTwoComponent },
  { path: "sales-thermalbill", component: SalesThermalBillComponent },
  { path: "price_checker", component: PriceChekerComponent },
  { path: "dashboard-two", component: DashboardTwoComponent },
  { path: "invoice-without-header", component: InvoiceWithoutHeaderComponent },
  { path: "post-dated-cheque", component: PostDatedChequeComponent },
  { path: "sales-order-list", component: SalesOrderListComponent },
  { path: "weight_scale_items", component: WeightScaleItemsComponent },
  { path: "product_location", component: ProductLocationComponent },
  { path: "excise_category", component: ExciseCategoryComponent },
  { path: "invoice-three", component: InvoiceThreeComponent },
  { path: "batch/expiry-notification", component: ExpiryNotificationComponent },
  { path: "invoice-four", component: InvoiceFourComponent },
  { path: "template-rtl", component: TemplateRtlComponent },
  { path: "template-two-rtl", component: TemplateTwoRtlComponent },
  { path: "calendar", component: CalendarComponent },
  { path: "new-invoice", component: NewInvoiceComponent },
  { path: "service-type", component: ServiceTypeComponent },
  { path: "purchase-freight-type", component: FreightTypeComponent },
  { path: "new-dashboard", component: NewDashboardComponent },
  { path: "rebate-report", component: RebateReportComponent },
  {path:"offer-flyer-poster",component:OfferFlyerPosterComponent},
  { path: "sales-invoices/:einvoice_pending", component: SalesInvoiceComponent},
  { path: "sales_return/:einvoice_pending", component: SalesReturnComponent},
  { path: "purchase/return/:einvoice_pending", component: PurchaseReturnComponent},
  { path: "credit-note/:einvoice_pending", component: CreditNoteComponent},
  { path: "favorite", component: FavoriteComponent },
  { path: "debit_note/:einvoice_pending", component: DebitNoteComponent},
  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
