import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportdashComponent } from './importdash/importdash.component';
import { ProductComponent } from './importdash/product/product.component';

import { FormsModule } from '@angular/forms';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';
import { ExcelImportRoutingModule } from './excel-import-routing.module';
// import { TransactionDashComponent } from './transaction-dash/transaction-dash.component';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatSelectModule} from '@angular/material/select';

// translation
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CustomerComponent } from './importdash/customer/customer.component';
import { UserPrivilegesComponent } from './importdash/user-privileges/user-privileges.component';
import { SharedModule } from 'src/app/shared/shared.module';
// import { ReceiptComponent } from './transaction-dash/vouchers/receipt/receipt.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}



@NgModule({
  declarations: [ImportdashComponent, ProductComponent, CustomerComponent, UserPrivilegesComponent],
  imports: [
    CommonModule,
    ExcelImportRoutingModule,
    FormsModule,
    DateInputsModule,
    NgSelectModule,
    ComfunctModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    SharedModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})

export class ExcelImportModule { }
