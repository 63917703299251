import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-ledger-grouping',
  templateUrl: './ledger-grouping.component.html',
  styleUrls: ['./ledger-grouping.component.css']
})
export class LedgerGroupingComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
 
  curForm = 'Create';
  langText = {
    create: '',
    update: ''
  };
  ledgerGrouping :any=[];
  vans: any;
  isSubmitInProg: boolean;
  resultobj: any;
  searchleadger: any;
  ledgerId: null;
  ledger_list=[];
  ledgerGrouping_list: any;
  dupplicateErr: string;
  dupplicateVanErr: string;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {

    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    
    });
    this.searchVans('');
    this.getLedgerGrouping();
  }

  createLedgerGrouping(){
    this.curForm = this.langText.create;
    this.clearForm();
  }

  clearForm() {
    this.ledgerGrouping ={
      lg_id :null,
      lg_name:'',
      lg_description:'',
      lg_van_id:null,
    };
    this.dupplicateVanErr='';
    this.dupplicateErr='';
  }

  

  editLedgerGrouping(editData){
    this.curForm = this.langText.update;
    this.ledgerGrouping =editData;
  }

  getLedgerGrouping(){

    let searchval = new FormData();
    this.apiService.getLedgerGrouping(searchval).subscribe((res) => {
      this.ledgerGrouping_list = res.data;
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    
    });
  
  }

  validateAndSubmit() {


    if (confirm('Are U Sure to Want Add?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
        callFunction = 'createLedgerGrouping';
  
      this.apiService[callFunction](this.ledgerGrouping).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error; 
        } else {
          this.closeModal.nativeElement.click();
        
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.getLedgerGrouping();
          this.resultobj = {};
        }

      });
    }
  
}

vanAdd(van_id){
  
  if(van_id){
    this.dupplicateVanErr='';
    let duplicate='';
    duplicate=this.ledgerGrouping_list.filter((listLedg) => listLedg.lg_van_id === van_id);


    if(duplicate!=''){
      this.dupplicateVanErr='Van Already Added To A Group';
      this.ledgerGrouping.lg_van_id='';
    }else{
      this.dupplicateVanErr='';
    }

  }
}

//form search  ledger
searchLedger(search: string) {
  let searchval = new FormData();
  searchval.append("search", search);
  this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
    this.searchleadger = res['data']['data'];
  });
 
}

addToGrid(data,i){

  if(data){
    this.dupplicateErr='';
    let duplicate='';
     duplicate = this.ledgerGrouping_list[i].ledger_list.filter((AddedLedgers) => AddedLedgers.lgs_ledger_id === data.ledger_id);

    if(duplicate!=''){
      this.dupplicateErr='Already Added';
    }else{
      this.ledgerGrouping_list[i].ledger_list.push({ "lgs_ledger_id":data.ledger_id,"ledger_name":data.ledger_name });
      this.dupplicateErr='';

    }
  
  }
}

remove(index,j) {
  this.ledgerGrouping_list[index].ledger_list.splice(j, 1);
}
addLedgertoGrp(data){
  this.isSubmitInProg = true;
  data.sub_add=true;
  this.apiService.createLedgerGrouping(data).subscribe((res) => {
    this.isSubmitInProg = false;
    if (res.error != null) {
      this.resultobj = res.error; 
    } else {
     
      this.coreService.showMessage(res.message);
      this.getLedgerGrouping();
      this.resultobj = {};
    }

  });
}
}
