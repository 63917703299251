import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';
import { MatDialogModule} from '@angular/material';

// routing
import { DamagedRoutingModule} from './damaged-routing.module';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

// component
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';




@NgModule({
  declarations: [DashboardComponent],
entryComponents: [],
imports: [
  CommonModule,  
  ErpMaterialModule,
  NgSelectModule,
  BrowserAnimationsModule,
  MatCheckboxModule,
  MatTabsModule,
  MatSelectModule,
  FormsModule,
  ReactiveFormsModule, 
  ComfunctModule,  
  MatDialogModule, 
  DateInputsModule,
  SharedModule,
  DamagedRoutingModule, 
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  })
],
providers: [{provide: APP_BASE_HREF, useValue : '/' },DatePipe], 
bootstrap: [AppComponent],

})
export class DamagedProductModule { }
