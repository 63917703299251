import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import * as jspdf from 'jspdf';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { CurrencyPipe } from '@angular/common';
import { ExcelService } from '../../../../../service/excel.service';

@Component({
  selector: 'app-van-aging-rep',
  templateUrl: './van-aging-rep.component.html',
  styleUrls: ['./van-aging-rep.component.css']
})
export class VanAgingRepComponent implements OnInit {

  @ViewChild('exportall', { static: true }) reportContent: ElementRef;
  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;
  fileName = 'van_aging.xlsx';

  pageLoadingImg: boolean;
  value1: any;
  value2: any;
  inv_val1: any;
  inv_val2: any;
  sales_val1: any;
  sales_val2: any;
  disc_val1: any;
  agents: any;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

 


 

  godowns: any;
  salesSum: any;
  totalCat: any;
  totalProducts: any;
  sup_id: any;
  cust_id: any;
  cust_type: any;
  pay_type: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  sales_disc_filter: any;
  sales_filter: any;
  inv_filter: any;
  period_type: any;
  gd_id: any;
  agent_id: any;
  company: any;
  supplier: any;
  users: any;
  customer: any;
  added_by: any;
  sales_flags: any;
  summary: any;
  repDate: any;
  totalData: number;
  date1: any;
  date2: any;
  time1: any;
  time2: any;
  vat_reg_no: any;
  ModelData: boolean;
  show: any;
  userType: any;
  filter_branch_id: number;
  branch_all: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  Expshow: any;
  ExpsalesSum: any;
  Expsummary: any;
  total_bal_amnt: any;
  total_inv_amnt: any;
  vanline_all: any;
  vans: any;
  showfull: any;
  atot: any;
  btot: any;
  ctot: any;
  dtot: any;
  etot: any;
  ftot: any;
  opening_balnce: any;
  total_due: any;
  total_out:any;
  ExprepDate: any;
  Exptotal_bal_amnt: any;
  Expopening_balnce: any;
  Exptotal_due: any;
  Exptotal_out: any;
  Expatot: any;
  Expbtot: any;
  Expctot: any;
  Expdtot: any;
  Expetot: any;
  Expftot: any;
  Expshowfull: any;
  exportLoader: boolean;


  Prntshow: any;
  PrntsalesSum: any;
  Prntsummary: any;
  PrntrepDate: any;
  Prnttotal_bal_amnt: any;
  Prntopening_balnce: any;
  Prnttotal_due: any;
  Prnttotal_out: any;
  Prntatot: any;
  Prntbtot: any;
  Prntctot: any;
  Prntdtot: any;
  Prntetot: any;
  Prntftot: any;
  Prntshowfull: any;
  exclude_zero=false;
  country_dec: number=2;
  display_type: any;
  van_route: any;
  van_vanline_id: any;
  van_id =1;
  vanName: any;
  PrntvanName: any;
  ExpvanName: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }


  searchVanlines(search: string) {
    let searchval = new FormData();
    searchval.append("vanline_name", search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      this.vanline_all = res['data'];

    });
  }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.display_type = '1';

    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.totalData = 0;
    this.pageLoadingImg = true;
    this.van_id = 1;
    let searchval = new FormData();
    searchval.append("display_type", '1');
    searchval.append("van_id", '1');

    this.apiService.vanAgingReports(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;


      this.show = res;

      console.log("shameer");
      console.log(this.show);



      this.summary = res['data'];
      this.repDate = res['Date'];
      this.total_bal_amnt = res['total_bal_amnt'];
      this.opening_balnce = res['opening_balnce'];
      this.total_due = res['total_due'];
      this.total_out = res['totTotalAllOut'];
      this.van_route = res['van_route'];
      this.vanName =res['vanName'];

      this.atot = res['atot'];
      this.btot = res['btot'];
      this.ctot = res['ctot'];
      this.dtot = res['dtot'];
      this.etot = res['etot'];
      this.ftot = res['ftot'];
      this.showfull = res['showall'];
      console.log(this.total_bal_amnt);

      this.pageFrom = res['from'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.totalData = res['total'];
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.ModelData = false;

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.country_dec=this.coreService.setDecimalLength();
  }

  
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }


  ngAfterContentInit() {

    this.searchVans('');
    this.searchUser('');

  }
  // toggleExcZero(){
  //   $("#exc_0").prop("checked", !$("#exc_0").prop("checked"));
  //   this.exclude_zero = $("#exc_0").prop("checked");
  // }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }


  ngOnDestroy() {
    $('.close').click();

  }

  pageNext(formdata: { value: any; }, newform: { value: any; }, pageno: any) {
    console.log("gggggggg");
    console.log(formdata.value.period_type);
    this.pageLoadingImg = true;
   // formdata.value.datewise = newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.vanAgingReports(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){
      this.show = res['data'];
      console.log("shameer111");
      console.log(this.show);



      this.summary = res['data'];
      this.repDate = res['Date'];
      this.total_bal_amnt = res['total_bal_amnt'];
      this.opening_balnce = res['opening_balnce'];
      this.total_due = res['total_due'];
      //this.total_due = res['total_due'];
      this.total_out = res['totTotalAllOut'];
      this.van_route = res['van_route'];
      this.vanName =res['vanName'];
      this.atot = res['atot'];
      this.btot = res['btot'];
      this.ctot = res['ctot'];
      this.dtot = res['dtot'];
      this.etot = res['etot'];
      this.ftot = res['ftot'];
      this.pageFrom = res['from'];
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.totalData = res['total'];
      // console.log(this.totalData);
      this.showfull = res['showall'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }



  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }


  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      console.log(this.customer);
    });

  }
  searchCustomeralias(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
      console.log(this.customer);
    });

  }


  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchAgent(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.agents = res['data'];
    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'All' });
    });

  }

  // searchVans(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('van_name', search);
  //   this.apiService.searchVans(searchval).subscribe((res) => {
  //     this.vans = res['data'];
  //     this.vans.push({ "van_id": 'All', 'van_name': 'All' });

  //   });

  // }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  export() {
    // const doc = new jspdf('p', 'pt', 'A4');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const content = this.reportContent.nativeElement;

    // doc.fromHTML(content.innerHTML, 5, 5, {
    //   'width': 1000,
    //   'elementHandlers': specialElementHandlers
    // });
    // doc.setFontSize(5);
    // doc.save('asdfghj' + '.pdf');
  }




  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  saveAndPrint(Expformdata: { value: any; }) {

    // debugger;
    this.exportLoader = true;
    Expformdata.value.exclude_zero = this.exclude_zero;
    Expformdata.value.datewise = true;
    Expformdata.value.export = 'export';
    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
      Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
    }
    this.apiService.vanAgingReports(Expformdata.value, 1).subscribe((res: Sales) => {
      // if(res['data']['data']){
      this.Prntshow = res;
      console.log("shameer2222");
      console.log(this.Prntshow);

      this.Prntsummary = res['data'];
      this.PrntrepDate = res['Date'];
      this.Prnttotal_bal_amnt = res['total_bal_amnt'];
      this.Prntopening_balnce = res['opening_balnce'];
      this.Prnttotal_due = res['total_due'];
      this.Prnttotal_out = res['totTotalAllOut'];
      this.PrntvanName =res['vanName'];
      this.van_route = res['van_route'];
      this.Prntatot = res['atot'];
      this.Prntbtot = res['btot'];
      this.Prntctot = res['ctot'];
      this.Prntdtot = res['dtot'];
      this.Prntetot = res['data']['etot'];
      this.Prntftot = res['data']['ftot'];
      this.Prntshowfull = res['showall'];

      setTimeout(() => {
        this.exportLoader = false;
        let el: HTMLElement = this.printBtn.nativeElement;
        el.click();
    }, 2000);
      Expformdata.value.export = '';


    });



  }


  exportEXCL(Expformdata: { value: any; }) {

    // debugger;
    this.exportLoader = true;

    Expformdata.value.datewise = true;
    Expformdata.value.export = 'export';
    Expformdata.value.exclude_zero = this.exclude_zero;
    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
      Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
      Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
    }
    this.apiService.vanAgingReports(Expformdata.value, 1).subscribe((res: Sales) => {
      // if(res['data']['data']){
      this.Expshow = res;

      console.log("shameer3333");
      console.log(this.Expshow);

      this.Expsummary = res['data'];
      this.ExprepDate = res['Date'];
      this.Exptotal_bal_amnt = res['total_bal_amnt'];
      this.Expopening_balnce = res['opening_balnce'];
      this.Exptotal_due = res['total_due'];
      this.Exptotal_out = res['totTotalAllOut'];
     this.ExpvanName =res['vanName'];
      this.Expatot = res['atot'];
      this.Expbtot = res['btot'];
      this.Expctot = res['ctot'];
      this.Expdtot = res['dtot'];
      this.Expetot = res['etot'];
      this.Expftot = res['ftot'];
      this.Expshowfull = res['showall'];

      // }


      setTimeout(() => {
        this.exportexcel();
      }, 3000);

      Expformdata.value.export = '';


    });



  }

// pdf download
generatePdf(Expformdata: { value: any; }) {

      // debugger;
      this.exportLoader = true;

      Expformdata.value.datewise = true;
      Expformdata.value.export = 'export';
      Expformdata.value.exclude_zero = this.exclude_zero;
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
        Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
      }
      this.apiService.vanAgingReports(Expformdata.value, 1).subscribe((res: Sales) => {
        // if(res['data']['data']){
        this.Expshow = res;
  
        this.Expsummary = res['data'];
        this.ExprepDate = res['Date'];
        this.Exptotal_bal_amnt = res['total_bal_amnt'];
        this.Expopening_balnce = res['opening_balnce'];
        this.Exptotal_due = res['total_due'];
        this.Exptotal_out = res['totTotalAllOut'];
        this.ExpvanName =res['vanName'];
        this.van_route = res['van_route'];
        this.Expatot = res['atot'];
        this.Expbtot = res['btot'];
        this.Expctot = res['ctot'];
        this.Expdtot = res['dtot'];
        this.Expetot = res['etot'];
        this.Expftot = res['ftot'];
        this.Expshowfull = res['showall'];
  
        console.log(this.summary);
        
        // }

        const heads = [
          this.translate.instant('Table.sl_no'),
          this.translate.instant('Common.prd_name'),
        
        ];
       

        if(this.showfull){
         

        
            heads.splice(3,0,'1-30' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(4,0,'31-60' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(5,0,'60-90' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(6,0,'91-120' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(7,0,'120+' + ' ' + this.translate.instant('HOME.days'))
            heads.splice(8,0,this.translate.instant('HOME.total_stck'))

        }


        
      
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
      
          if(this.show != ''){

            this.Expsummary.forEach((item,i)=>{

              const arr = [
                ((this.curpage-1)*30)+i+1,
                item.prd_name,
                
                
              ];

          
             
             

              if(this.showfull){
                
               
                  arr.splice(3,0, (item.b).toFixed(this.country_dec)),
                  arr.splice(4,0, (item.c).toFixed(this.country_dec)),
                  arr.splice(5,0, (item.d).toFixed(this.country_dec)),
                  arr.splice(6,0, (item.e).toFixed(this.country_dec)),
                  arr.splice(7,0, (item.f).toFixed(this.country_dec)),
                  arr.splice(8,0, (item.duetot).toFixed(this.country_dec))
                 
              }

              data.push(arr)
            })

        }
      
          let doc = this.excelService.getPdfObj();
      
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
      
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
      
          if(this.ExprepDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Common.Van_Aging_Reports')}`,  valueXPos, headerHeight + 15);

         

         

          if(this.showfull){
          
                doc.setFontSize(10);
                doc.text(`${this.translate.instant('HOME.VAN')}`, lblXPos, headerHeight + 20);
                doc.setFontSize(10);
                doc.text(':  ' + (this.ExpvanName),  valueXPos, headerHeight + 20);
           
            
                doc.setFontSize(10);
                doc.text(`${this.translate.instant('HOME.total_stck')}`, lblXPos, headerHeight + 25);
                doc.setFontSize(10);
                doc.text(':  ' + (this.Exptotal_due - this.Exptotal_out).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

           
            
          }

        
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 65, lblXPos, heads, data, null);
      
         
          doc.save(`Van Aging Report.pdf`);
  
        Expformdata.value.export = '';
  
        this.exportLoader = false;
  
      });
  
}

searchVans(search: string) {
  const searchval = new FormData();
  searchval.append('van_name', search);
  this.apiService.searchVans(searchval).subscribe((res) => {
    this.vans = res['data'];
  });

}

}
