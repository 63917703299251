import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-cost-category',
  templateUrl: './cost-category.component.html',
  styleUrls: ['./cost-category.component.css']
})
export class CostCategoryComponent implements OnInit {
  fileName = 'cost_category_report.xlsx';

  filter_types: any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  cost_c: string[];
  cost_cs: string[];
  cost_cat: any;
  pageLoadingImg: boolean;
  subcatagories: any;
  costcentersubs: any;
  ledger_id: any;
  category: any;
  costcenter: any;
  costcentsub: any;
  cc_id: any;
  ccs_id: any;
  repDate: any;
  period_type: string;
  result: any[];
  cat_name: any;
  units_all: any[];
  cos_sub: any;
  cos_name: any;
  country_dec: string;
  branch_all: any;
  filter_branch_id:number;
  userType: any;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  exportLoader: boolean;

  // changeBox: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.getAllBranch();
    this.filter_branch_id = 1;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.period_type = 't';
    this.pageLoadingImg = true;
    this.userType = this.coreService.getUserData('user_type');

    const searchval = new FormData();

    searchval.append('period_type', 't');
    this.apiService.costCategoryReport(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.category = res.data;
      this.repDate = res.date;
      let catArr = [];
      res.data.forEach((element, i) => {

        let ccArr = [];
        element.cost_center.forEach((element1, i1) => {

          let ccsArr = [];
          element1.cost_sub.forEach((element2, i2) => {
            ccsArr.push({
              name: element2.name,
              c_amt: element2.c_amt,
              d_amt: element2.d_amt
            });

          });

          if (this.cc_id || (!this.ledger_id && !this.ccs_id)) {
            // console.log('if ccid')
            ccArr.push({
              name: element1.name,
              c_amt: element1.c_amt,
              d_amt: element1.d_amt,
              sub: ccsArr
            });
          } else {
            // console.log('else if ccid')
            console.log(ccArr);
            ccArr = ccsArr;
          }

        });

        if (this.ledger_id || (!this.cc_id && !this.ccs_id)) {
          
          catArr.push({
            name: element.name,
            c_amt: element.c_amt,
            d_amt: element.d_amt,
            sub: ccArr
          });
        } else {
          catArr = ccArr;
        }
      });
      this.result = catArr;
console.log(this.result);

    });

    this.country_dec=this.coreService.setDecimalLength();
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  costCategoryRep(formdata: { value: any; }) {



  
    if (formdata.value.cc_id) {
      this.cos_name = this.subcatagories.find(x => x.cc_id === formdata.value.cc_id).cc_name;
    }
    if (formdata.value.ccs_id) {
      this.cos_sub = this.costcentersubs.find(x => x.ccs_id === formdata.value.ccs_id).ccs_name;
    }
    if (formdata.value.ledger_id) {
      this.cat_name = this.cost_cat.find(x => x.ledger_id === formdata.value.ledger_id).ledger_name;
    }
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.costCategoryReport(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      this.category = res.data;
      this.repDate = res.date;
      let catArr = [];


      res.data.forEach((element, i) => {

        let ccArr = [];
        element.cost_center.forEach((element1, i1) => {

          let ccsArr = [];
          element1.cost_sub.forEach((element2, i2) => {
            // console.log('ccs ivide');
            
            ccsArr.push({
              name: element2.name,
              c_amt: element2.c_amt,
              d_amt: element2.d_amt
            });

          });

          if (this.cc_id || (!this.ledger_id && !this.ccs_id) || (this.ledger_id && (!this.cc_id && !this.ccs_id))) {
            // console.log('cc ivide');
            ccArr.push({
              name: element1.name,
              c_amt: element1.c_amt,
              d_amt: element1.d_amt,
              sub: ccsArr
            });
          } else {
            // console.log('cc ivide alla');
            // console.log(ccsArr);
            ccArr = ccsArr;
          }

        });
        if (this.ledger_id || (!this.cc_id && !this.ccs_id)) {
        // console.log('c ivide');
        
          catArr.push({
            name: element.name,
            c_amt: element.c_amt,
            d_amt: element.d_amt,
            sub: ccArr
          });
          // console.log(ccArr);

        } else {
          // console.log('c ivide alla');
          
          catArr = ccArr;

        }
      });

      // console.log('ajmal');

      this.result = catArr;
      console.log(this.result);

    });
  }

  searchCostCenter(search: string) {
    if (!this.ledger_id) {
      const searchval = new FormData();
      searchval.append('cc_name', search);
      this.apiService.searchCostCenter(searchval).subscribe((res) => {
        this.subcatagories = res['data'];
      });
    }


  }
  searchCostCenterSub(search: string) {
    // if (!this.ledger_id || !this.cc_id) {
    const searchval = new FormData();
    searchval.append('ccs_name', search);
    if (this.ledger_id) {
      searchval.append('ledger_id', this.ledger_id);
    }
    if (this.cc_id) {
      searchval.append('cc_id', this.cc_id);
    }
    this.apiService.searchCostCenterSub(searchval).subscribe((res) => {
      this.costcentersubs = res['data'];
    });
    // }
  }

  searchCostCatgory(search: string) {

    const searchval = new FormData();
    searchval.append('ccat_name', search);
    this.apiService.searchCostCatgory(searchval).subscribe((res) => {
      this.cost_cat = res['data'];
    });

  }


  selectCostCents(ledgid: number) {

    this.cc_id = null;
    this.ccs_id = null;
    this.searchCostCenter('');
    this.searchCostCenterSub('');

    this.apiService.getAllCostCentId(ledgid).subscribe((res) => {
      this.subcatagories = res['data'];

    });


  }


  selectCostCentSubs(cc_id: number) {
    this.ccs_id = null;
    this.searchCostCenterSub('');
    this.apiService.getAllCostCentSubId(cc_id).subscribe((res) => {
      this.costcentersubs = res['data'];


    });


  }

// pdf download
generatePdf() {
  
  const heads = [
    this.translate.instant('ACCOUNTS.Debit_Amount'),
    this.translate.instant('ACCOUNTS.Credit_Amount')
  ];

  if(this.ledger_id || (!this.ledger_id && !this.cc_id && !this.ccs_id)){
    heads.splice(0,0, this.translate.instant('Common.category'))
  }

    const lblXPos = 10;
    const headerHeight = 15;
    const valueXPos = 55;
    const data = [];

    this.category.forEach(item=>{
      data.push([
        item.name,
        item.d_amt,
        item.c_amt
      ])

      item.cost_center.forEach(item1=>{
        data.push([
        '     '+item1.name,
        '     '+(item1.d_amt).toFixed(this.country_dec),
        '     '+(item1.c_amt).toFixed(this.country_dec)
      ])

        item1.cost_sub.forEach(item2=>{
          data.push([
            '            '+item2.name,
            '            '+(item2.d_amt).toFixed(this.country_dec),
            '            '+(item2.c_amt).toFixed(this.country_dec)
        ])
        })

      })
    })

    let doc = this.excelService.getPdfObj();

    var addressLines = this.branch_address.split('\r\n');
    var addressText = addressLines.join(', ');

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    doc.text(addressText, 71, headerHeight + 2);

    if(this.repDate){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
    doc.setFontSize(10);
    doc.text(':  ' + `${this.translate.instant('HOME.cost_category_rep')}`,  valueXPos, headerHeight + 15);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Common.category')}`, lblXPos, headerHeight + 20);

    if(this.ledger_id){
      doc.setFontSize(10);
      doc.text(':  ' + this.cat_name,  valueXPos, headerHeight + 20);
    } else {
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 20);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.cost_center')}`, lblXPos, headerHeight + 25);
    
    if(this.cc_id){
      doc.setFontSize(10);
      doc.text(':  ' + this.cos_name,  valueXPos, headerHeight + 25);
    } else {
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 25);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.cost_sub_center')}`, lblXPos, headerHeight + 30);
    
    if(this.ccs_id){
      doc.setFontSize(10);
      doc.text(':  ' + this.cos_sub,  valueXPos, headerHeight + 30);
    } else {
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 30);
    }
    
    doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);

   
    doc.save(`Cost Category Report.pdf`);


  
}
getAllBranch() {
  this.apiService.readAllBranch().subscribe((res:any) => {
    this.branch_all = res.data;
    this.filter_branch_id = res.selected;

    // console.log(  this.branch_all );
  //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res:any) => {
    this.branch_all = res.data;
    // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

exportexcel(): void {
  /* table id is passed over here */
  let element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  this.exportLoader = false;
}

exportEXCL() {

  this.exportLoader = true;

    setTimeout(() => {
      this.exportexcel(); 
  }, 3000);

}

}
