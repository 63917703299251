import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../service/api.service';
import {Router} from "@angular/router"
import { CoreService } from '../../service/core.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class HeaderComponent implements OnInit {  
  search_res: string[];
  token: string;
  display_name:String; 
  eInvoiceSupport: boolean=false;
  branch_name:String;
  display_code:String;
  branch_e_invoice_enabled = 0;
  user_name:String;
  photo:any;
  base_url: string;
  usr_username: any;
  // headerSalesEinvoiceLink=0;
  search_inp : any;
  show_production = false;
  cheq_issue_date: Date = new Date();
  pdc_count: any;
  batch_ex_count: any;
  admin_notification:any;
  up: any;
  checkBatch =false;
  so_count= <any>0;
  userType: any;
  show_component: boolean;
  user_id: any;
  einvoice_count: any;


  constructor(config: NgbModalConfig, private modalService: NgbModal, public translate: TranslateService,
    private apiService: ApiService,public router : Router,private coreService: CoreService,) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;

    // translation
    translate.addLangs(['English', 'Chinese', 'Arabic','French']);

    if (sessionStorage.getItem("baseLang")) {
      translate.use(sessionStorage.getItem("baseLang"));
    } else {
      translate.use('English');
    }

    this.apiService.notificationChange.subscribe(value =>{
        this.pdc_count = value;
    })
    this.apiService.salesordernotificationChange.subscribe(value =>{
      this.so_count = value;
    });  
    this.apiService.notificationBatchChange.subscribe(value =>{
      this.batch_ex_count = value;
    })

    this.apiService.pendingEinvoiceNotificationChange.subscribe(value =>{
      this.einvoice_count = value;
    })


    
  }

  open(content) {
    this.modalService.open(content);
  }

  ngOnInit() {

    
    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');
    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
      }
    });

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });
    this.base_url = this.apiService.PHP_API_SERVER;
  //   if(!sessionStorage.auth_token){
  //     this.router.navigate(['/']);
  //   } 
  this.up = this.coreService.getUserPrivilage();
  this.display_name = this.coreService.getUserData('branch_disp_name');
  this.branch_name= this.coreService.getUserData('branch_name');
  this.display_code= this.coreService.getUserData('branch_code');
  this.user_name= this.coreService.getUserData('usr_name');
  this.usr_username= this.coreService.getUserData('usr_username');
  this.admin_notification = this.coreService.getUserData('admin_notification');
  if(this.admin_notification){
    $("#adminNtfnMdl").click();
  }

  this.userType =  this.coreService.getUserData('user_type');
  this.user_id =  this.coreService.getUserData('usr_id');
  if(this.userType == 'ADMIN'){
    if(this.user_id==1){
      this.show_component=true;
    }else{
      this.show_component=false;
    }
    
  }else{
    this.show_component=true;
  }
  // this.photo= this.coreService.getUserData('photo');
  this.photo= this.base_url + '/images/users/' + this.coreService.getUserData('photo');
  this.getEnbaleDisableModuleSettingBykey();
    if(!localStorage.getItem("auth_token")){
   this.router.navigate(['/']);
    }  
    
    this.apiService.updatePDCNotification();
    this.apiService.updateSalesOrderNotification();
    this.apiService.updatebatchExNotification();
    this.apiService.updateEinvoiceNotificationChange();
  }
  getEnbaleDisableModuleSettingBykey() {
    this.apiService.getEnbaleDisableModuleSettingBykey().subscribe((res) => {
      if (res['data']) {
        this.show_production = (res['data']['enable_production']) ? true: false;
      } 
    });
  }

  selDefault(lang) {
    alert("lang");
  }

  changeLang(bLang) {
    this.translate.use(bLang);
    sessionStorage.setItem("baseLang", bLang);
    this.apiService.updateLanguage();
    // if(this.router.url=="/ledger_detail_rep"){
    //   window.location.reload();
    // }
  }

  searchMenu(search: string) {
    if(search){
      let searchval = new FormData();
      searchval.append("keyword", search);
      this.apiService.searchMenu(searchval).subscribe((res) => {
        this.search_res = res['data'];
      });
      $('.search-list').show();
    }else{
      $('.search-list').hide();
    }
    
  }
  gotopage(link){
    $('.search-list').hide();
    this.search_inp = '';
    this.router.navigate(['/'+link]);
  }


  // search(e) {
  //   const filter = $('#search').val().toString().toLowerCase();
  //   // filter = filter.toLowerCase();
  //   const nodes = $('.card-body');
  //   let isElemnt = false;
  //   // let  divEml;

  //   let divEml: any;
  //   if ($('.menu_page .col-xl-3').length) {
  //     divEml = $('.menu_page .col-xl-3');
  //     isElemnt = true;
  //   }

  //   if ($('.sub_menu_page .col-xl-2').length){
  //     divEml = $('.sub_menu_page .col-xl-2');
  //     isElemnt = true;
  //   }
  //   if (isElemnt === true) {
  //     for (let i = 0; i < nodes.length; i++) {
  //       if (nodes[i].innerText.toLowerCase().includes(filter)) {
  //         divEml[i].style.display = 'block';
  //       } else {
  //         divEml[i].style.display = 'none';
  //       }
  //     }
  //   }
  // }

}
