import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-database-clearance',
  templateUrl: './database-clearance.component.html',
  styleUrls: ['./database-clearance.component.css']
})
export class DatabaseClearanceComponent implements OnInit {
  buttonLoadingImg: boolean;
  pageLoadingImg: boolean;
  product=true;
  customer=true;
  supplier=true;
  ledger=true;
  transaction=true;
  menuIds: any;
  allCheck: boolean = true;
  infoList = [];

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.infoList[0]='Ledger';
    this.infoList[1]='Products';
    this.infoList[2]='Customer';
    this.infoList[3]='Supplier';
    this.infoList[4]='Sales,Purchase,Vouchers,Branch Transfer&Receipt';
    this.infoList[5]='Godown,Godown Transfer';
    this.infoList[6]='van,van routes,van transaction';
    this.infoList[7]='stocks,price group,warranty';


  }

  checkboxes = [
    { name: 'ledger', checked: true, value: 'ledger', label: 'HOME.LEDGER' },
    { name: 'product', checked: true, value: 'product', label: 'HOME.PRODUCT' },
    { name: 'customer', checked: true, value: 'customer', label: 'HOME.CUSTOMER' },
    { name: 'supplier', checked: true, value: 'supplier', label: 'HOME.Suppliers' },
    { name: 'transaction', checked: true, value: 'transaction', label: 'HOME.transaction' },
    { name: 'godown', checked: true, value: 'godown', label: 'HOME.GODOWN' },
    { name: 'van', checked: true, value: 'van', label: 'HOME.VAN' },
    { name: 'opening stock', checked: true, value: 'opening stock', label: 'Table.opng_stk' }

  ];

  changeSelection(value: boolean) {
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = value;
    });
  }

  clearDatabase(formdata: { value: any; }) {

    if (confirm('Are you sure to execute this?')) {
    this.buttonLoadingImg = true;
    this.apiService.dbclearance(formdata.value).subscribe((res) => {

      this.buttonLoadingImg = false;
       this.menuIds = res.message;
       
       if (this.menuIds) {
             this.coreService.showMessage(this.menuIds);

       }
      
    });
  }

    
  }

  // changeSelection(value){
  //   for (var key in this.menuIds) {
  //     this.menuIds[key] = value;
  //  }

  // }

}
