import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { LangChangeEvent } from '@ngx-translate/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ExcelService } from '../../../../../service/excel.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs
@Component({
  selector: 'app-van-transfer-datewise',
  templateUrl: './van-transfer-datewise.component.html',
  styleUrls: ['./van-transfer-datewise.component.css']
})
export class VanTransferDatewiseComponent implements OnInit {
  fileName= 'van_transfer_datewise.xlsx';
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;
  van_id: any;

  searchProducts: any;
  catagories: any;
  company: any;
  vans: any;
  vanPrdData: any;
  vanTransData: any;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  totalRes: any;
  slnum: number;
  data: any;
  reportBy:any;
  rep_type:any;
  pageLoadingImg:boolean;
  reportType:any;
  repDate:any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;

  exp_vanTransData: any;
  exp_totalRes: any;


  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];


  rept_type = [
    { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];



  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  from: any;
  subcatagories: any;
  units_all: any;
  unit_name: any;
  
  unit_id: any;
  logoImgUrl: any = '';
  pdf_totalRes: any;
  country_dec: number=2;
  van_list: any;
  tablewise: boolean=false;
  filter_branch_id: number;
  userType: any;
  branch_all: any;
  cur_lang: string;
  PrdNAme: any;
  branch_address: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private excelService: ExcelService,private translate: TranslateService, private datepipe: DatePipe, private currencyPipe: CurrencyPipe) { }
  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.filter_branch_id = 1;
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.exportLoader = false;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    this.totalRes = {};
    this.company = this.searchComp('');
    this.catagories = this.searchCat('');
    this.vans = this.searchVans('');
    this.rep_type = 1;
    this.apiService.readVanTransfersReporteByDate(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.vanTransData = res.data.data;
      this.totalRes = res.data.totalDetails;
      this.reportBy = res.data.reportBy;
      this.reportType = res.data.reportType;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.from = res.data.from;
      this.van_list=this.vanTransData[0].vans;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.getAllUnit();
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  ngOnDestroy(){
    $('.close').click();
 
  }




   // Loading Api to include for Filters


   searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }
  //get Units
  getUnit(search:string) {
    const searchval = new FormData();
    searchval.append("prd_id",search);
    this.apiService.getUnitsItemwise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }

  
  selectUnits(unitid: string) {
    if (unitid) {
       
      this.unit_name = this.units_all.find(x => x.unit_id === unitid).unit_name;
         
    console.log(this.unit_name);
    
    }
  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.searchProducts = res.data;
    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      
    });
  }



  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
    if(this.tablewise){
      formdata.value.tablewise=1;   
    }
  this.apiService.readVanTransfersReporteByDate(formdata.value, pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
      
    this.vanTransData = res.data.data;
      this.totalRes = res.data.totalDetails;
      this.reportBy = res.data.reportBy;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.reportType = res.data.reportType;
      this.repDate = res.data.Date;
      this.from = res.data.from;
      this.van_list=this.vanTransData[0].vans;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element:any;
     if(this.tablewise){
       element = document.getElementById('export-group1');
     }else{
       element = document.getElementById('export-group');
     }
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    if(this.tablewise){
      formdata.value.tablewise=1;   
    }
    this.apiService.readVanTransfersReporteByDate(formdata.value, 1).subscribe((res: any) => {
        this.exportLoader = false;
        
        this.exp_vanTransData = res.data.data;
        this.exp_totalRes = res.data.totalDetails;
        this.reportBy = res.data.reportBy;
        this.reportType = res.data.reportType;
        this.repDate = res.data.Date;
        this.van_list=this.exp_vanTransData[0].vans;
        // this.from = res.data.from; 
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
      });
  }



  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  // pdf download
  generatePdf(formdata: any) {
    formdata.value.export = 'export';

    this.exportLoader = true;
    const rows = [];
    const headerHeight = 25;
    const lblXPos = 18;
    const valueXPos = 55;

    const heads = [
      this.translate.instant('HOME.sl_no'),
      this.translate.instant('Common.prd_name'),
      this.translate.instant('settings.Barcode'),
      this.translate.instant('HOME.qty'),
      // this.translate.instant('HOME.unit_wise_qty'),
      this.translate.instant('Table.transfer_rate'),
      this.translate.instant('Table.amnt'),
      
  ];

  
  if(this.unit_id &&this.prd_id) {
    heads.splice(3,0, this.translate.instant('HOME.unit_wise_qty'))
  }
  
    this.apiService.readVanTransfersReporteByDate(formdata.value, 1).subscribe((res: any) => {
      if (res.data && res.data.data) {
        this.pdf_totalRes = res.data.totalDetails;
        this.repDate = res.data.Date;
     
        res.data.data.forEach((data: any,i) => {
          if(this.translate.currentLang == "Arabic"){this.PrdNAme =  data.prd_alias}else{this.PrdNAme =  data.prd_name}
          const arr = [i+1, this.PrdNAme,data.prd_barcode, (data.stock_qty/ data.unit_base_qty ),(data.purch_rate* data.unit_base_qty).toFixed(this.country_dec),(data.purch_amount).toFixed(this.country_dec) ];

          if(this.unit_id &&this.prd_id) {
            arr.splice(3,0, data.unit_qty)
          }
          // rows.push([i, data.prd_name, data.stock_qty, data.unit_qty,
          //   data.purch_rate,data.purch_amount ]);

          rows.push(arr);
        });
      }


      let doc = this.excelService.getPdfObj();

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text('VAN STOCK DATEWISE', 85, headerHeight - 2);


    //
      doc.setFontSize(10);
      doc.text('Branch', lblXPos, headerHeight + 5);
      doc.setFontSize(11);
      doc.text(':  ' + this.branch_display_name,  valueXPos, headerHeight + 5);

      
      doc.setFontSize(10);
      doc.text(`Period`, lblXPos, headerHeight + 10);
      doc.text(`:  ${this.repDate.date1} To ${this.repDate.date2}`,  valueXPos, headerHeight + 10);
  

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 15);
      doc.text(':  ' +this.pdf_totalRes.total_products,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.num_of_van')}`, lblXPos, headerHeight + 20);
      doc.text(':  ' + this.pdf_totalRes.total_van,  valueXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totl_trans_qty')}`, lblXPos, headerHeight + 25);
      doc.text(':  ' + this.pdf_totalRes.total_stock_qty,  valueXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totl_purch_qty')}`, lblXPos, headerHeight + 30);
      doc.text(':  ' + (this.pdf_totalRes.total_purchase_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 30);
     

      doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, rows, this.logoImgUrl, this.branch_display_name);
      doc.save(`van_datewise.pdf`);
      this.exportLoader = false;

    });
    // if(this.is_notes){
    //   heads.splice(5,0, this.translate.instant('Purchase.notes'))
    // }

  }


  readvantransfer(){


    const searchval = new FormData();
    if(this.tablewise){
      searchval.append("tablewise", '1');    
    }
    this.apiService.readVanTransfersReporteByDate(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.vanTransData = res.data.data;
      this.totalRes = res.data.totalDetails;
      this.reportBy = res.data.reportBy;
      this.reportType = res.data.reportType;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.from = res.data.from;
      this.van_list=this.vanTransData[0].vans;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }
  tableView(formdata: { value: any; }){
    this.tablewise=true;

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
   
      formdata.value.tablewise=1;   
    
  this.apiService.readVanTransfersReporteByDate(formdata.value, 1).subscribe((res: any) => {
    this.pageLoadingImg = false;
      
    this.vanTransData = res.data.data;
      this.totalRes = res.data.totalDetails;
      this.reportBy = res.data.reportBy;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.reportType = res.data.reportType;
      this.repDate = res.data.Date;
      this.from = res.data.from;
      this.van_list=this.vanTransData[0].vans;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    
  }

  listView(formdata: { value: any; }){
    this.tablewise=false;

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
   
      formdata.value.tablewise='';   
    
  this.apiService.readVanTransfersReporteByDate(formdata.value, 1).subscribe((res: any) => {
    this.pageLoadingImg = false;
      
    this.vanTransData = res.data.data;
      this.totalRes = res.data.totalDetails;
      this.reportBy = res.data.reportBy;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.reportType = res.data.reportType;
      this.repDate = res.data.Date;
      this.from = res.data.from;
      this.van_list=this.vanTransData[0].vans;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  getAllUnit(){

    this.apiService.readUnit().subscribe((res) => {
      this.units_all = res['data'];   
 
               
     });
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
}
