import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import html2canvas from 'html2canvas';

import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

import { CurrencyPipe  } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-trial-balance',
  templateUrl: './trial-balance.component.html',
  styleUrls: ['./trial-balance.component.css']
})
export class TrialBalanceComponent implements OnInit {
  fileName = 'trail_balance.xlsx';


  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_type = '1';
  dtype: any;
  vch_filter: any;
  ret_ref_filter: any;



  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'Summary' },
    { id: '2', name: 'Group wise' },
    { id: '3', name: 'Detailed' },

  ];
  filterPurchrate = [
    { id: 'last', name: 'Last Purchase Rate' },
    { id: 'avg', name: 'Avg. Purchase Rate' },
    { id: 'avg_by_date', name: 'Avg. Purchase Rate By Date' }
  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  group_all: any;
  ledgerList: any;
  total_debit: any;
  total_credit: any;
  reportData: any;
  diffInOpengBal: any;

  math = Math;
  disp_purch_rate = 'last';
  period_type = '';
  ExpledgerList: any;
  ExpdiffInOpengBal: any;
  Exptotal_debit: any;
  Exptotal_credit: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_all: any;
  userType: any;
  exportLoader: boolean;
  headerImage = '';
  country_dec: number=2;
  constructor(private apiService: ApiService, private coreService: CoreService,
              private excelService: ExcelService, private currencyPipe: CurrencyPipe, private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.searchSupplier('');
    this.pageLoadingImg = true;

    const searchval = new FormData();
    this.apiService.trialBalanceReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.diffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

      this.ModelData = false;
    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.getHeaderImage();

    this.country_dec=this.coreService.setDecimalLength();
  }
  ngOnDestroy() {
    $('.close').click();

  }




  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('pageno', pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.trialBalanceReport(formdata.value, pageno).subscribe((res: any) => {

      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.diffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


      this.repDate = res.info.Date;
      // this.dispnote = res.data.show_notes;
      // this.debitAmt = res.data.debit_amnt;
      // this.crdAmt = res.data.credit_amnt;



    });
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.last_purchase_rate','Common.average_purchase_rate','Common.avg_purchase_rate_by_date','Common.summary','ACCOUNTS.Group_wise','Common.detailed']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.summary'] },
        { id: '2', name: res['ACCOUNTS.Group_wise'] },
        { id: '3', name: res['Common.detailed'] },
    
      ];
      this.filterPurchrate = [
        { id: 'last', name: res['Common.last_purchase_rate']},
        { id: 'avg', name: res['Common.average_purchase_rate'] },
        { id: 'avg_by_date', name: res['Common.avg_purchase_rate_by_date'] }
      ];
    });

  }



  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res: any) => {
      this.branch_all = res.data;

      // console.log(  this.branch_all );

    });
  }
  searchBranch(search: string) {
    const searchval = new FormData();
    searchval.append('branch_name', search);
    this.apiService.getBranchByName(searchval).subscribe((res: any) => {
      this.branch_all = res.data;
    });
  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });

  }



  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.voucherType = res.data;
    });
  }

  searchLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.getLedByName(searchval).subscribe((res: any) => {
      this.ledger_all = res.data;
    });
  }

  searchAccGroup(search: string) {
    const searchval = new FormData();
    searchval.append('name', search);
    this.apiService.getAccGroup(searchval).subscribe((res: any) => {
      this.group_all = res.data;

      console.log(this.group_all);
    });
  }



  // form search
  searchUser(search: string) {
    const searchval = new FormData();
    searchval.append('usr_name', search);
    this.apiService.getUserByName(searchval).subscribe((res: any) => {
      this.users = res.data;
    });
  }



  pgEnd(curr, end) {
    if (curr === end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr === 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  grpSummaryexport() {

  }

  openPDF() {
    $('#contentToConvert').show();

    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      // 208
      const pageHeight = 495;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0);
      // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      // var position = 0;
      // pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);

      // pdf.save('Account Group Summary Report.pdf'); // Generated PDF

    });

    $('#contentToConvert').hide();


  }

    // To get Header image which will be the part of pdf report
    getHeaderImage() {
      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_logo) {
          this.headerImage = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
        } else {
          this.headerImage = ''; // default
        }

        if (res.data.prnt_header_type === 2) {
          this.headerImage = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.headerImage = '';
        }

      });
    }

  exportPDF() {
    const heads = [this.translate.instant('ACCOUNTS.Particulars'),
    this.translate.instant('ACCOUNTS.Debit_Amount'),
    this.translate.instant('ACCOUNTS.Credit_Amount')];
    const data = [];
    const headerHeight = 52;

    this.ledgerList.forEach(item => {
      if ( item.display) {
        const arr = [(item.gtz).toFixed(this.country_dec), (item.ltz).toFixed(this.country_dec)];

        if(this.translate.currentLang == 'Arabic'){
          arr.splice(0,0, item.accgrp_name_ar)
        } else {
          arr.splice(0,0, item.accgrp_name)
        }

        data.push(arr);
      }

      if ( this.disp_type === '3') {
        item.ledgers.forEach(subledgers => {
            if (subledgers.gtz !== 0 || subledgers.ltz !== 0) {
              console.log(subledgers.ledger_name);
              const arr3 = [(subledgers.gtz).toFixed(this.country_dec),
              (subledgers.ltz).toFixed(this.country_dec)];

              if (this.translate.currentLang === 'Arabic' && subledgers.ledger_alias && subledgers.ledger_alias !== 'NILL') {
                arr3.splice(0, 0, '      ' + subledgers.ledger_alias);
              } else if (this.translate.currentLang === 'Arabic') {
                arr3.splice(0, 0, '      ' + subledgers.ledger_name_ar);
              } else {
                arr3.splice(0, 0, '      ' + subledgers.ledger_name);
              }

              data.push(arr3);
            }
        });
      }

      if ( item.sub_groups.length !== 0 && this.disp_type !== '1') {
        item.sub_groups.forEach(sub => {
          if (sub.display) {
            const arr3 = [(sub.gtz).toFixed(this.country_dec),
            (sub.ltz).toFixed(this.country_dec) ];

            if(this.translate.currentLang == 'Arabic'){
              arr3.splice(0,0, sub.accgrp_name_ar)
            } else {
              arr3.splice(0,0, sub.accgrp_name)
            }

            data.push(arr3);
          }

          if (this.disp_type === '3') {
            sub.ledgers.forEach(subitem => {
              if (subitem.gtz !== 0 || subitem.ltz !== 0) {
                const arr4 = [(subitem.gtz).toFixed(this.country_dec),
                (subitem.ltz).toFixed(this.country_dec)];

                if (this.translate.currentLang === 'Arabic' && subitem.ledger_alias && subitem.ledger_alias !== 'NILL') {
                arr4.splice(0, 0, '      ' + subitem.ledger_alias);
              } else if (this.translate.currentLang === 'Arabic') {
                arr4.splice(0, 0, '      ' + subitem.ledger_name_ar);
              } else {
                arr4.splice(0, 0, '      ' + subitem.ledger_name);
              }

                data.push(arr4);
              }
            });
          }
      });
      }
    });

    if (this.diffInOpengBal !== 0) {
      const col2 = this.diffInOpengBal < 0 ? (this.math.abs(this.diffInOpengBal)).toFixed(this.country_dec) :
      (this.diffInOpengBal).toFixed(this.country_dec);
      const arr5 = [this.translate.instant('ACCOUNTS.Difference_In_Opening_Balances'), col2];
    }

    if (this.ledgerList.length > 0 && !this.pageLoadingImg ) {
      const arr6 = [this.translate.instant('HOME.total'), (this.total_debit).toFixed(this.country_dec),
      (this.total_credit).toFixed(this.country_dec)];
      data.push(arr6);
    }

    const lblXPos = 18;

    let doc = this.excelService.getPdfObj();

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('Trial Balance Report', 85, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(10);
    doc.setTextColor(195, 196, 195);
    doc.text(`${this.repDate.date1} to ${this.repDate.date2}`,  80, headerHeight + 2);
    doc.setTextColor(0, 0, 0);

    doc = this.excelService.addTableToPdf(doc, headerHeight + 5, lblXPos, heads, data, this.headerImage, this.branch_display_name);

    doc.setDrawColor(0, 0, 0);
    // tslint:disable-next-line:no-string-literal
    const tblFooterY = doc['lastAutoTable'].finalY + 5;
    doc.line(lblXPos, tblFooterY - 11, lblXPos + 178, tblFooterY - 11);

    doc.save(`trial_balance.pdf`);

  }


  // exportAsXLSX(formdata: { value: any; }): void {

  //   this.apiService.groupSummaryToatalReport(formdata.value).subscribe((res: any) => {
  //     this.reportData = res.data;
  //     console.log(this.reportData);
  //   });

  //   this.excelService.exportAsExcelFile(this.reportData, 'Account Group Summary Report');
  // }


    exportExcelOld(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }



  exportexcel(): void {





    const title = 'Trial Balnce';
    const header = ['Particulars', 'Debit Amount', 'Credit Amount'];
    const newArray = [] as  any;


    // Create workbook and worksheet

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.fileName);
    // let titleRow = worksheet.addRow([title]);
    // titleRow.font = { size: 16, underline: 'double', bold: true }
    // worksheet.addRow([]);
    const subTitleRow = worksheet.addRow(['Branch : ' + this.branch_name]);
    worksheet.addRow([' ' + this.branch_display_name + this.branch_display_code]);
    worksheet.addRow(['Report : Trial Balance' ]);
    worksheet.addRow(['Duration : ' + this.repDate.date1 + ' To ' + this.repDate.date2 ]);

    worksheet.mergeCells('A1:C1');
    worksheet.mergeCells('A2:C2');
    worksheet.mergeCells('A3:C3');
    worksheet.mergeCells('A4:C4');

    // Blank Row
    worksheet.addRow([]);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.font = { bold: true };




    this.ExpledgerList.forEach(function(part, index, theArray) {

      if (part.display) {
          const ltz = part.ltz > 0 ? (part.ltz).toFixed(this.country_dec) : '';
          const gtz = part.gtz > 0 ? (part.gtz).toFixed(this.country_dec) : '';

          const values = [part.accgrp_name, gtz, ltz];
          newArray.push(values);
          const row = worksheet.addRow(values);
          row.eachCell( function(cell, colNumber) {
            if (cell.value) {
                row.getCell(colNumber).font = {color: {argb: '3024ba'}, bold: true};
            }
          });
          // row.font = { bold: true}
      }
      if (this.disp_type === 3) {
        part.ledgers.forEach(function(part1, index1, theArray1) {
          if (part1.gtz !== 0 || part1.ltz !== 0) {
            const ltz = part1.ltz > 0 ? (part1.ltz).toFixed(this.country_dec) : '';
            const gtz = part1.gtz > 0 ? (part1.gtz).toFixed(this.country_dec) : '';

            const values = [part1.ledger_name, gtz, ltz];
            newArray.push(values);
            const row1 = worksheet.addRow(values);
            row1.eachCell( function(cell, colNumber) {
              if (cell.value) {
              row1.getCell(colNumber).font = {color: {argb: '0e7c0a'}, bold: true};
              }
            });
          }
        }.bind(this));
      }

      if (part.sub_groups.length !== 0 && this.disp_type !== 1) {
        part.sub_groups.forEach(function(part2, index2, theArray2) {
          if (part2.display) {
            const ltz = part2.ltz > 0 ? (part2.ltz).toFixed(this.country_dec) : '';
            const gtz = part2.gtz > 0 ? (part2.gtz).toFixed(this.country_dec) : '';

            const values = [part2.accgrp_name, gtz, ltz];
            newArray.push(values);
            const row2 = worksheet.addRow(values);

            row2.eachCell( function(cell, colNumber) {
              if (cell.value) {
              row2.getCell(colNumber).font = {color: {argb: 'ee7814'}, bold: true};
              }
            });
          }

          if (this.disp_type === 3) {
            part2.ledgers.forEach(function(part3, index3, theArray3) {
              if (part3.gtz !== 0 || part3.ltz !== 0) {
                const ltz = part3.ltz > 0 ? (part3.ltz).toFixed(this.country_dec) : '';
                const gtz = part3.gtz > 0 ? (part3.gtz).toFixed(this.country_dec) : '';

                const values = [part3.ledger_name, gtz, ltz];
                newArray.push(values);
                const row3 = worksheet.addRow(values);

                row3.eachCell( function(cell, colNumber) {
                  if (cell.value) {
                  row3.getCell(colNumber).font = {color: {argb: '0e7c0a'}, bold: true};
                  }
                });
              }
            }.bind(this));
          }

        }.bind(this));
      }


    }.bind(this));
    if (this.diffInOpengBal !== 0) {
      const diffl = this.diffInOpengBal < 0 ? (this.diffInOpengBal).toFixed(this.country_dec) : '';
      const diffg = this.diffInOpengBal > 0 ? (this.diffInOpengBal).toFixed(this.country_dec) : '';
      const values = ['Difference In Opening Balances', diffl, diffg];
      newArray.push(values);
      const row4 = worksheet.addRow(values);
      row4.eachCell( function(cell, colNumber) {
        if (cell.value) {
        row4.getCell(colNumber).font = {color: {argb: '3024ba'}, bold: true};
        }
      });
    }
    if (this.ledgerList.length > 0 && !this.pageLoadingImg) {
      const values = ['Total', (this.total_debit).toFixed(this.country_dec), (this.total_credit).toFixed(this.country_dec)];
      newArray.push(values);
      const row = worksheet.addRow(values);
    }


    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.addRow([]);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.fileName);
    });







    // /* table id is passed over here */
    // let element = document.getElementById('export-group');
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // /* generate workbook and add the worksheet */
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export(formdata: { value: any; }) {

    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.exportLoader = true;

    this.apiService.trialBalanceReport(formdata.value, 0).subscribe((res: any) => {

      this.ExpledgerList = res.data.data;
      this.ExpdiffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
      this.Exptotal_debit = res.info.total_debit;
      this.Exptotal_credit = res.info.total_credit;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


      this.repDate = res.info.Date;
      // this.dispnote = res.data.show_notes;
      // this.debitAmt = res.data.debit_amnt;
      // this.crdAmt = res.data.credit_amnt;
      setTimeout(() => {
        // this.exportexcel();
        this.exportExcelOld();
    }, 3000);


    });



  }




}
