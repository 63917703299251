import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-invoice-due-payment-summary',
  templateUrl: './invoice-due-payment-summary.component.html',
  styleUrls: ['./invoice-due-payment-summary.component.css']
})
export class InvoiceDuePaymentSummaryComponent implements OnInit {


  fileName = 'inv_due_payment_summary.xlsx';


  pageLoadingImg: boolean;
  summary: any;
  repDate: any;
  total_payment: any;
  total_pay_amnt: any;
  branch_address:any;
  country_dec: number=2;

  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_sort = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  pageFrom: any;
  curpage: any;
  lastpage: any;
  totalData: any;
  pgend: boolean;
  pgstart: boolean;
  pay_amnt_filter: any;
  pay_val1: any;
  pay_val2: any;
  pay_no_filter: any;
  pay_no_val1: any;
  pay_no_val2: any;
  branch_all: any;
  company: any;
  customer: any;
  supplier: any;
  users: any;
  godowns: any;
  vans: any;
  vanline_all: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_name: any;
  Expsummary: any;
  ExprepDate: any;
  Exptotal_payment: any;
  Exptotal_pay_amnt: any;
  supplierAll: any;
  cur_lang: string;
  filter_branch_id: number;
  userType: any;
  suppName: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    // this.filter_branch_id = 1;
    this.getAllBranch();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
  

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.userType = this.coreService.getUserData('user_type');

    let searchval = new FormData();
    this.summary = [];

    this.apiService.InvoiceduePaymentSummary(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;

      // this.show = res['data'];

      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_payment = res['data']['total_payment'];
      this.total_pay_amnt = res['data']['total_pay_amnt'];

      // console.log(this.total_payment);

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    

  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }

    if (formdata.value.inv_period_type === 'c') {
      formdata.value.inv_date1 = (formdata.value.inv_date1) ? this.apiService.formatDate(formdata.value.inv_date1) : formdata.value.inv_date1;
      formdata.value.inv_date2 = (formdata.value.inv_date2) ? this.apiService.formatDate(formdata.value.inv_date2) : formdata.value.inv_date2;
     
    }

    this.summary = [];

    this.apiService.InvoiceduePaymentSummary(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){

      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_payment = res['data']['total_payment'];
      this.total_pay_amnt = res['data']['total_pay_amnt'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.totalData);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
    });
  }
 

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  // Search Customers
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  searchVanlines(search: string) {
    let searchval = new FormData();
    searchval.append("vanline_name", search);
    this.apiService.getVanlinesName(searchval).subscribe((res) => {
      this.vanline_all = res['data'];

    });
  }

  // form search supplier
    searchSupplier(search: string) {
      const searchval = new FormData();
      searchval.append('supp_name', search);
       if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append("with-alias", "1");
      }
      this.apiService.searchSupplier(searchval).subscribe((res) => {
        this.supplierAll = res.data;
      });
    }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'All' });
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });

    });

  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }

    this.Expsummary = [];

    this.apiService.InvoiceduePaymentSummary(Expformdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){

      this.Expsummary = res['data']['data'];
      this.ExprepDate = res['data'].Date;
      this.Exptotal_payment = res['data']['total_payment'];
      this.Exptotal_pay_amnt = res['data']['total_pay_amnt'];
      


    setTimeout(() => {
      this.exportexcel();
    }, 3000);


    Expformdata.value.export = '';
  });



}
getAllBranch() {
  this.apiService.readAllBranch().subscribe((res:any) => {
    this.branch_all = res.data;
    this.filter_branch_id = res.selected;

    // console.log(  this.branch_all );
  //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}



// pdf download
generatePdf(Expformdata: { value: any; }) {

  this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }

    this.Expsummary = [];

    this.apiService.InvoiceduePaymentSummary(Expformdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      // if(res['data']['data']){

      this.Expsummary = res['data']['data'];
      this.ExprepDate = res['data'].Date;
      this.Exptotal_payment = res['data']['total_payment'];
      this.Exptotal_pay_amnt = res['data']['total_pay_amnt'];
      


      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('transaction.payment_no'),
        this.translate.instant('transaction.branch_payment_no'),
        this.translate.instant('ACCOUNTS.Vch_No'),
        this.translate.instant('HOME.Suppliers'),
        this.translate.instant('Common.Inv_Date'),
        this.translate.instant('HOME.Invoice_no'),
        this.translate.instant('transaction.Branch_Inv_No'),
        this.translate.instant('transaction.Pay_Date'),
        this.translate.instant('transaction.Pay_Amount')
      ];

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
    
        this.Expsummary.forEach((item,i)=>{
          if (this.translate.currentLang === "Arabic") {
            this.suppName = item.ledger.ledger_alias_AR || '';
        } else {
            this.suppName = item.ledger.ledger_name || '';
        }
        
          data.push([
            i+1,
            item.payment_no,
            item.brnch_pay_num,
            item.vch_no,
            this.suppName,
            item.inv_date,
            item.purch_due_purch_inv_no,
            item.branch_inv_no,
            item.purch_due_date,
            (item.purch_due_in).toFixed(this.country_dec)
          ])
        })
    
        let doc = this.excelService.getPdfObj();
    
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
    
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Common.inv_due_Payment_summary')}`,  valueXPos, headerHeight + 15);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.total_payments')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_payment, '', '', '1.1-1'),  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.total_payment_amnt')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + (this.total_pay_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
       
        doc.save(`Invoice Due Payment Summary.pdf`);

        this.exportLoader = false;

    Expformdata.value.export = '';
  });
  
}

}
