import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// component
import { LedgerDashComponent } from './ledger-dash/ledger-dash.component';
import { SupplierComponent } from './ledger-dash/supplier/supplier.component';
import { StaffComponent } from './ledger-dash/staff/staff.component';
import { CustomerComponent } from './ledger-dash/customer/customer.component';
import { SalesAgentComponent } from './ledger-dash/sales-agent/sales-agent.component';
import { ImportCustomerComponent } from './ledger-dash/import-customer/import-customer.component';
import { ImportSuppierComponent } from './ledger-dash/import-supplier/import-supplier.component';
import { CusomerCategoryComponent } from './ledger-dash/cusomer-category/cusomer-category.component';
import { LedgerGroupingComponent } from './ledger-dash/ledger-grouping/ledger-grouping.component';

const routes: Routes = [
{path: 'ledger', component: LedgerDashComponent},
{path: 'supplier', component: SupplierComponent},
{path: 'staff', component: StaffComponent},
{path: 'customer', component: CustomerComponent},
{path: 'sales-agent', component: SalesAgentComponent},
{path: 'customer/import', component: ImportCustomerComponent},
{path: 'supplier/import', component: ImportSuppierComponent},
{path: 'customer-category', component: CusomerCategoryComponent},
{path: 'ledger-grouping', component: LedgerGroupingComponent},

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LedgerRoutingModule {}