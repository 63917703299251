import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  favorite = {
    favp_id: '',
    favp_name:'',
    favp_description:'',  
  }
  isSubmitInProg = false;
  isSubmitInProg1 = false;
  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;
  langText = {
    create: '',
    update: ''
  };
  up: any;
  selecTed: any[];
  cur_lang: string;
  favProducts=[];
  search = [];
  prodsel: any;
  favp_id: any;
  
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }
  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
      this.up = this.coreService.getUserPrivilage();
    });
    this.getInfo(this.curpage);
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.selectProd(''); 
  }
  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllFavorites(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data;
      this.pageLoadingImg = false;
      this.curpage = res.current_page;
      this.lastpage = res.last_page;
      this.totalItem = res.total;
    });
  }
  createServiceType(){
    this.curForm = this.langText.create;
    this.clearForm();
  }
  clearForm() {
    this.favorite = {
      
      favp_id: '',
      favp_name:'',
      favp_description:'',
    };
  }
  editFavorite(favp_id){
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getFavorite({favp_id: favp_id}).subscribe((res) => {
      this.favorite = {
        favp_id: res.favp_id,
        favp_name:res.favp_name,
        favp_description:res.favp_description,
      };
    });
  }
  deleteFavorite(favp_id,curpage){
    if(confirm("Do you wish to void this entry?")){
      this.apiService.deleteFavorite({favp_id: favp_id}).subscribe((res) => {
        this.coreService.showMessage(res.message);
      });
      this.getInfo(curpage); 
    }
  }
  viewFavProd(favp_id){
    this.selectProd('');
    this.search =[];
    this.favp_id =favp_id;
    this.favProducts =[];
      this.apiService.viewFavoriteProd({favp_id: favp_id}).subscribe((res) => {
        this.favProducts =res;
      });
  }
  removeFromFav(favps_id ){
    if(confirm("Do you wish to void this entry?")){
          this.apiService.deleteFavoriteProd({favps_id: favps_id }).subscribe((res) => {
          this.favProducts =[];
          this.favProducts =res;
          this.coreService.showMessage("Successfully Removed");
      });
    }
  }
validateAndSubmit() {
    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createFavorite';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateFavorite';
      }
      this.apiService[callFunction](this.favorite).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }
      });
    }
}

selectProd(name: string) {
  let searchval = new FormData();
  searchval.append("prod_name", name);
  searchval.append("flag[]", "0");
  searchval.append("flag[]", "1");
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.search = res['data'];
  });
}

addFavProducts(){
if (confirm('Do you wish to continue?')) {
  let duplicate = this.favProducts.filter((favProd) => favProd.product.prd_id === this.prodsel.prd_id);
  if(duplicate.length >0){
    this.coreService.showMessage("Already exists");
  }
  else{
        let searchval = new FormData();
        searchval.append("prod_id", this.prodsel.prd_id);
        searchval.append("favp_id", this.favp_id);
        this.isSubmitInProg1 = true;
        this.apiService.addFavProducts(searchval).subscribe((res) => {
          // this.favProducts =[];
            if(res.error){
              this.coreService.showMessage(res.error);
            }
            if(res.message){
              this.favProducts = res.data;
            }  
          this.isSubmitInProg1 = false;

        });

      }
      
  }
}

searchInp(input){
  let searchval = new FormData();
  searchval.append("prod_name", input);
  searchval.append("favp_id", this.favp_id);
    this.apiService.searchFavProducts(searchval).subscribe((res) => {
      this.favProducts =[];
     
     if(res.message){
       this.favProducts = res.data;
     }   
     this.isSubmitInProg1 = false;

    });

}

}
