import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';
// routing
import { VanRoutingModule} from './van-routing.module';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { NumonlyDirective } from '../../../app/directive/numonly.directive';
// validations
// modal
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//date picker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { VanLineComponent } from './van-line/van-line.component';
import { VanDashComponent } from './van-dash/van-dash.component';
import { VanComponent } from './van-dash/van/van.component';
import { VanTransferComponent } from './van-dash/van-transfer/van-transfer.component';
import { ReturnTransferComponent } from './van-dash/return-transfer/return-transfer.component';
import { TransferVoidComponent } from './van-dash/transfer-void/transfer-void.component';
import { TransferReturnVoidComponent } from './van-dash/transfer-return-void/transfer-return-void.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {NgxPrintModule} from 'ngx-print';
import { VanMissingStockComponent } from './van-dash/van-missing-stock/van-missing-stock.component';
import { VanExcessStockComponent } from './van-dash/van-excess-stock/van-excess-stock.component';
import { VanStockVerifyComponent } from './van-dash/van-stock-verify/van-stock-verify.component';
import { VanStockAsignComponent } from './van-dash/van-stock-asign/van-stock-asign.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VanDamageComponent } from './van-dash/van-damage/van-damage.component';

@NgModule({
  declarations: [VanLineComponent, VanDashComponent, VanComponent, VanTransferComponent, ReturnTransferComponent, TransferVoidComponent, TransferReturnVoidComponent, VanMissingStockComponent, VanExcessStockComponent, VanStockVerifyComponent, VanStockAsignComponent, VanDamageComponent],
  imports: [
    CommonModule, 
    VanRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgbModule,
    SharedModule,
    ComfunctModule,
    DateInputsModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' }, NumonlyDirective,DatePipe], 
  bootstrap: [AppComponent]
})
export class VanModule { }
