import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchase-order-rep',
  templateUrl: './purchase-order-rep.component.html',
  styleUrls: ['./purchase-order-rep.component.css']
})
export class PurchaseOrderRepComponent implements OnInit {
  fileName= 'purchase_order_report.xlsx';

  filter_types_orderdate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_types_delv_date = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'cu', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  Status = [
    { id: '0', name: 'Purchased' },
    { id: '1', name: 'Pending' },

  ];

  stats_name = {
    '0': 'Purchased',
    '1': 'Pending', 
  };
  products: any[];
  pageLoadingImg: boolean;
  purchOrdRpt: any;
  repDate: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_product: any;
  pgend: boolean;
  pgstart: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_address:any;
  branch_display_code: any;
  exportLoader: boolean;
  ExppurchOrdRpt: any;
  Exptotal_product: any;
  count: any;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  cur_lang: string;
  prdName: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
  
    let searchval = new FormData();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.apiService.purchaseOrderRpt(searchval, 1).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.purchOrdRpt = res['data']['data'];
      // console.log(this.salesOrdRpt);
      
      
      this.repDate = res.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_product = res.data.total_numbr_of_prodcts;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.PURCHASED','transaction.Pending']).subscribe((res: string) => {    
     
      
      this.filter_types_orderdate = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.filter_types_delv_date = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'cu', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];

      this.Status = [
            { id: '0', name: res['HOME.PURCHASED'] },
            { id: '1', name: res['transaction.Pending'] },
        
          ];
    });

  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN'  || this.userType == 'AUDITOR') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    
   
   
    if (formdata.value.period_type === 'c') {
  
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      
    }
    if (formdata.value.period_type_d === 'cu') {
  
      formdata.value.date_d1 = (formdata.value.date_d1) ? this.apiService.formatDate(formdata.value.date_d1) : formdata.value.date_d1;
      formdata.value.date_d2 = (formdata.value.date_d2) ? this.apiService.formatDate(formdata.value.date_d2) : formdata.value.date_d2;
      
    }
    this.apiService.purchaseOrderRpt(formdata.value, pageno).subscribe((res:any) => {
      this.pageLoadingImg = false;
      
      
      this.purchOrdRpt = res['data']['data'];
      // console.log(this.salesOrdRpt);
      
      
      this.repDate = res.data.Date;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_product = res.data.total_numbr_of_prodcts;
      this.count = res.data.count;
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });

  }
  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }
  
     // search product
     selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }


    exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });
  
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
      
    }
  
    exportEXCL(formdata: { value: any; }){
      this.exportLoader = true;
       
      
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
    
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        
      }
      if (formdata.value.period_type_d === 'cu') {
    
        formdata.value.date_d1 = (formdata.value.date_d1) ? this.apiService.formatDate(formdata.value.date_d1) : formdata.value.date_d1;
        formdata.value.date_d2 = (formdata.value.date_d2) ? this.apiService.formatDate(formdata.value.date_d2) : formdata.value.date_d2;
        
      }
      this.apiService.purchaseOrderRpt(formdata.value, 1).subscribe((res:any) => {
        this.pageLoadingImg = false;
        
        
        this.ExppurchOrdRpt = res['data']['data'];
        // console.log(this.salesOrdRpt);
        
        
        this.repDate = res.data.Date;
        this.Exptotal_product = res.data.total_numbr_of_prodcts;
  
  
  
          setTimeout(() => {
          this.exportexcel();
      }, 3000);
  
  
  
      });
  
    }

    // pdf download
    generatePdf(formdata: { value: any; }) {

      this.exportLoader = true;
       
      
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
    
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        
      }
      if (formdata.value.period_type_d === 'cu') {
    
        formdata.value.date_d1 = (formdata.value.date_d1) ? this.apiService.formatDate(formdata.value.date_d1) : formdata.value.date_d1;
        formdata.value.date_d2 = (formdata.value.date_d2) ? this.apiService.formatDate(formdata.value.date_d2) : formdata.value.date_d2;
        
      }
      this.apiService.purchaseOrderRpt(formdata.value, 1).subscribe((res:any) => {
        this.pageLoadingImg = false;
        
        
        this.ExppurchOrdRpt = res['data']['data'];
        // console.log(this.salesOrdRpt);
        
        
        this.repDate = res.data.Date;
        this.Exptotal_product = res.data.total_numbr_of_prodcts;
  
  
  
        const heads = [
          this.translate.instant('Table.itm_name'),
          this.translate.instant('Purchase.ref_no'),
          this.translate.instant('HOME.order_no'),
          this.translate.instant('HOME.order_date'),
          this.translate.instant('HOME.order_qty'),
          this.translate.instant('Common.Delivery_date'),
          this.translate.instant('Table.status')
        ];

          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
  
          this.ExppurchOrdRpt.forEach(item=>{
            item.items.forEach(item1=>{
              if (this.translate.currentLang === "Arabic") {
                this.prdName = item.prd_alias || '';
            } else {
                this.prdName = item.prd_name || '';
            }
              const arr=[
                this.prdName,
                item1.pqsub_pq_id,
                item1.pq_branch_qt_no,
                this.datePipe.transform(item1.pqsub_date, 'dd/MM/yyyy'),
                this.currencyPipe.transform(item1.pqsub_qty, '', '', '1.1-1'),
                this.datePipe.transform(item1.delvry_date, 'dd/MM/yyyy'),
                this.stats_name[item1.status]
              ]
              
              data.push(arr)
            })
          })
      
          let doc = this.excelService.getPdfObj();
  
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
  
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);

          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
  
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('HOME.purchase_ordr_rep')}`,  valueXPos, headerHeight + 15);
  
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
      
         
          doc.save(`Purchase Order Report.pdf`);
  
          this.exportLoader = false;
  
      });
      
    }

}
