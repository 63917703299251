import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-van-missing-stock',
  templateUrl: './van-missing-stock.component.html',
  styleUrls: ['./van-missing-stock.component.css']
})
export class VanMissingStockComponent implements OnInit {
  fileName= 'van_missing_stock.xlsx';
  vans: any;
  products: any[];
  catagories: any;
  users: any;
  branch_all: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  pageLoadingImg: boolean;
  repDate: any;
  van_misng_rpt: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  tot_items: any;
  total_qty: any;
  tot_purch_amt: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  exp_van_misng_rpt: any;
  country_dec: number=2;
  cur_lang: any;
  PrdNAme: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();

    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.vanMissingStkRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.van_misng_rpt = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage =  res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    
    });

    this.vans=this.searchVans('');
    
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanMissingStkRep(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.van_misng_rpt = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage =  res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    console.log(this.van_misng_rpt);
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

  
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(formdata: { value: any; }) {

    this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanMissingStkRep(formdata.value, 1).subscribe((res: any) => {
      this.exportLoader = true;
      this.repDate = res.data.Date;
      this.exp_van_misng_rpt = res['data'];
      // this.pageFrom = res['data']['from'];
      // this.curpage =  res['data']['current_page'];
      // this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
  
      
    
    });

  }
  // getAllVanList() {
  //   let searchval = new FormData();

  //   this.apiService.getAllVanList(searchval).subscribe((res) => {
  //     this.vans = res['data'];
  //     this.vans.push({ "gd_id": '', 'gd_name': 'All' });

  //   });
  // }

    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }

      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    searchCat(search: string) {
      const searchval = new FormData();
      searchval.append('cat_name', search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.catagories = res['data'];
      });
  
    }
    searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res) => {
    this.branch_all = res['data'];
    this.branch_all.push({'branch_id':'','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanMissingStkRep(formdata.value, 1).subscribe((res: any) => {
      this.exportLoader = true;
      this.repDate = res.data.Date;
      this.exp_van_misng_rpt = res['data'];
      // this.pageFrom = res['data']['from'];
      // this.curpage =  res['data']['current_page'];
      // this.lastpage = res['data']['last_page'];
      
      this.tot_items =  res['total']['tot_items'];
      this.total_qty = res['total']['total_qty'];
      this.tot_purch_amt =  res['total']['tot_purch_amt'];
     
      const heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('HOME.missing_date'),
        this.translate.instant('Common.notes'),
        this.translate.instant('HOME.missing_qty'),
        this.translate.instant('Table.purch_rate'),
        this.translate.instant('Common.Purch_Amt')
      ];

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
    
        this.exp_van_misng_rpt.forEach(item=>{
          if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}

          data.push([
            this.PrdNAme,
            '',
            '',
            this.currencyPipe.transform(item.qty, '', '', '1.1-1'),
            (item.purch_rate).toFixed(this.country_dec),
            (item.purch_sum_amt).toFixed(this.country_dec)
          ])
  
          item.items.forEach(item1=>{
            data.push([
              item.prd_barcode,
              item1.van_mstk_date,
              item1.van_mstk_notes,
              this.currencyPipe.transform(item1.stk_qty, '', '', '1.1-1'),
              (item1.van_mstk_purch_rate).toFixed(this.country_dec),
              (item1.purch_amt).toFixed(this.country_dec)
            ])
          })
  
        })
    
        let doc = this.excelService.getPdfObj();
    
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
    
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
    
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Van.van_msng_stock_rep')}`,  valueXPos, headerHeight + 15);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.tot_items,  valueXPos, headerHeight + 20);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + (this.tot_purch_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 30);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
    
       
        doc.save(`Van Missing Stock Report.pdf`);
  
        this.exportLoader = false;
    
    });
  
}

}
