import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-van-damage',
  templateUrl: './van-damage.component.html',
  styleUrls: ['./van-damage.component.css']
})
export class VanDamageComponent implements OnInit {

  modalRef: NgbModalRef; 
  closeResult: string;
  damage_date: Date;
  printError=[]; errObjArr = {
    unitqtyErr: null, vnErr: null,
    prodErr: null, dtErr: null, unitErr: null, dmgdErr: null, dmqtyErr: null
  };
  selecTed: any = {};
  prod_alias: any;
  stkprd_all: any;
  unit_qty: number;
  unit: any;
  van_id: any;
  vans: any;
  units_all: any;
  trans_stocks = [];
  slno: any;
  unit_base_qty: any;
  sel_unit: any;
  unit_name: any;
  qty: any;
  damage_notes: any;
  customer: any;
  pageLoadingImg: boolean;
  loading: boolean;
  alias: any;
  van_damage_list: any;
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  voidloading: boolean;
  cur_lang: string;
  country_dec: number=2;
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal, private translate: TranslateService) { }

  ngOnInit() {
    this.getVanDamageList('')
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.country_dec=this.coreService.setDecimalLength();
  }

  openModal(content) {

    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.damage_date = new Date();


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

    //form search product
    searchStkPrd(search: any, eventChar) {
      let searchval = new FormData();
      searchval.append("keyword", search);
      if(this.prod_alias){
        searchval.append('alias_search', '1');
      }
      this.apiService.getStockbyItem(searchval).subscribe((res) => {
        this.stkprd_all = res['data'];
        $('#addpurchase').hide();
  
      });
    }
    aliasChecked(){
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        this.prod_alias = 'alias_true';
        this.alias='alias_checked';
      }else{
        this.prod_alias =null;
        this.alias=null;
      }
    }

    selectStockPrdSel(stkprdsel) {
      this.unit_qty = 0;
      if (stkprdsel) {
        this.unit = null;
        this.selecTed = stkprdsel;
        this.van_id = null;
        this.searchVans('');
        this.getUnits();
      } else {
        this.selecTed = [];
  
      }
    }

    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
  
    
      });
  
    }

    getUnits() {
      const searchval = new FormData();
      searchval.append("prd_id",this.selecTed.prd_id);
      this.apiService.unitPrdtWise(searchval).subscribe((res) => {
        this.units_all = res['data'];
  
      });
  
    }

    selectVanStk(van_id) {
      if (van_id) {
        
  
      let searchval = new FormData();
      searchval.append("prd_id", this.selecTed.prd_id);
      searchval.append("van_id", van_id);
      this.unit_qty = 0;
  
      this.apiService.getVanStock(searchval).subscribe((res) => {
        this.selecTed.vanstock_qty = res['data'][0]['vanstock_qty'];
        this.selectUnits(this.unit);
      });
      }
  
    }
    selectUnits(unitid: any) {
      if (unitid) {
        this.sel_unit = this.units_all.find(x => x.unit_id === unitid).unit_id;
        this.unit_base_qty = this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
        this.unit_name = this.units_all.find(x => x.unit_id === unitid).unit_name;
        this.unit_qty = this.selecTed.vanstock_qty / this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
        // console.log(this.unit_qty);
      }
    }


    addToList(form) {

    
      this.printError = [];
      if (form.value.qty == "" || form.value.qty == undefined||form.value.qty < 1) {
        this.errObjArr.unitqtyErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      }
      else if (Number(form.value.qty) > Number(this.unit_qty)) {

        this.errObjArr.unitqtyErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      }else {
        this.errObjArr.unitqtyErr = "f";
      }
  
      if (form.value.van_id == undefined) {
         this.errObjArr.vnErr = "t";
         this.printError.push({ 'fieldErr': 'Required' });
      }
      else {
        this.errObjArr.vnErr = "f";
      }
  
  
      if (form.value.unit == undefined) {
        this.errObjArr.unitErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      }
      else {
        this.errObjArr.unitErr = "f";
      }
  
      if (form.value.damage_date == undefined) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      }
      else {
        if (form.value.damage_date > new Date()) {
          this.errObjArr.dtErr = "t";
          this.printError.push({ 'fieldErr': 'Required' });
        } else {
          this.errObjArr.dtErr = "f";
        }
      }
  
      if (form.value.stkprdsel == undefined) {
        this.errObjArr.prodErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      }
      else {
        this.errObjArr.prodErr = "f";
      }
  
  
      if (this.printError.length <= 0) {
        this.slno = this.trans_stocks.length + 1;
        //check duplicate
        const { length } = this.trans_stocks;
        const id = length + 1;
        const prd = this.trans_stocks.some(el => el.prd_id == form.value.prd_id);
        const van_id = this.trans_stocks.some(el => el.van_id === form.value.van_id);
  
        if (!prd)
          this.trans_stocks.push({
            "sl_no": this.slno, "prd_id": form.value.prd_id, "itemname": form.value.itemname,"prd_alias": form.value.prd_alias, "qty": form.value.qty * this.unit_base_qty, "unit_id": this.sel_unit, "unit_name": this.unit_name, "added_qty": form.value.qty, "van_id": form.value.van_id,
            "damage_date": form.value.damage_date, "damage_notes": form.value.damage_notes
          });
          // console.log(this.trans_stocks);
          
        this.unit = '';
        this.van_id = null;
        this.qty = '';
        // this.stk_unit_qty = '';
        this.unit_qty = 0;
        this.damage_notes = '';
        // form.reset();
        const formData = form.value;
        form.reset({
          cust_id: formData.cust_id,  // This field will not be reset
          // Other fields will be reset to empty or default values
        });
        this.damage_date = new Date();
        this.selecTed = {};
        // this.stkprdsel = this.stkprdsel[0];
        // this.prd_id = '';
       
      }
  
    }

    deleteProd(data) {
      var remopstk = confirm("Removing Selected Product from the table");
      if (remopstk) {
        var type = data.sl_no,
          i;
        for (i = this.trans_stocks.length - 1; i >= 0; --i) {
          if (this.trans_stocks[i].sl_no == type) {
            this.trans_stocks.splice(i, 1);
          }
        }
  
      }
    }

    searchCustomer(search: string) {
      const searchval = new FormData();
      searchval.append("cust_name", search);
     
      this.apiService.searchCustomer(searchval).subscribe((res) => {
        this.customer = res["data"];
      });
    }

    submitVanDamage(form: NgForm){

      if (this.trans_stocks.length > 0) {

        if (confirm('Do you wish to continue?')) {

          form.value.prdlist = this.trans_stocks;
          form.value.damage_date = (form.value.damage_date) ?
            this.apiService.formatDate(form.value.damage_date) : form.value.damage_date;
          this.pageLoadingImg = true;
          this.apiService.addtoVanDamage(form.value).subscribe((res) => {
            this.coreService.showMessage('Added Successfully');
            this.trans_stocks = [];
            form.reset();
            this.selecTed = [];
            this.modalRef.close();
            this.unit = null;
            this.pageLoadingImg = false;
  
  
            this.getVanDamageList('');
          });
        }

      }

    }

    getVanDamageList(prd_name) {
      this.loading=true;
      let searchval = new FormData();
      searchval.append("keyword", prd_name);
      if(this.alias){
        searchval.append('alias_search', '1');
      }
      this.apiService.getVanDamageList(searchval,1).subscribe((res) => {
      this.loading=false;
        this.van_damage_list = res.data.data;
          this.curpage = res.data['current_page'];
        this.lastpage = res.data['last_page'];
        this.from = res.data['from'];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
  
      });
  
    }

    pgEnd(curr, end) {
      if (curr == end)
        this.pgend = true;
      else
        this.pgend = false;
  
    }
  
    pgStart(curr) {
      if (curr == 1)
        this.pgstart = true;
      else
        this.pgstart = false;
    }

    removeVanDamage(id) {

      if (confirm("Do you wish to void this entry?")) {
        let searchval = new FormData();
        this.voidloading= true;
        searchval.append("vd_id", id);
        this.apiService.voidVanDamage(searchval).subscribe((res) => {
          if (res.message)
            this.coreService.showMessage('Voided Successfully');
            
          this.getVanDamageList('');
          this.voidloading = false;
        });
  
      }
  
    }
}
